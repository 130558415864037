<!-- Top Navbar Area -->
<nav class="navbar top-navbar navbar-expand is-sticky mb_theme" id="navbar">
    <div class="collapse navbar-collapse" id="navbarSupportContent">
        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar wh"></span>
            <span class="middle-bar wh"></span>
            <span class="bottom-bar wh"></span>
        </div>
        <span class="head  hb_title"  >&nbsp;&nbsp; </span>
        <ul class="navbar-nav left-nav align-items-center" style="    margin-top: -10px;
        margin-left: 10px;">
           

          
<li style="FONT-FAMILY: system-ui;
FONT-WEIGHT: 700;

padding-top: 4px;" > {{this.appservice.Header_Disp}}</li>

           
        </ul>

        <form class="nav-search-form d-none ml-auto d-md-block">
           <div style="line-height: 45px;">&nbsp;</div>
        </form>

        <ul class="navbar-nav right-nav align-items-center"  
        style="right: 0px;
        position: absolute;"
        >
        <li class="nav-item dropdown profile-nav-item">
            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="menu-profile">

                    <i style="    padding-right: 5px;    margin-top: 6px;" class='bx bx-search-alt' (click)="get_Search()"></i>
                </div>
            </a>
           
           </li>
           <li class="nav-item dropdown profile-nav-item">
            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="menu-profile">

                    <i style="    padding-right: 5px;    margin-top: 6px;" class='bx bx-home cwh' (click)="get_Home()"></i>
                </div>
            </a>
           
           </li>

           
          

            <li class="nav-item notification-box dropdown " >
             
                <a  routerLink="notification" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="notification-btn">
                        <i class='bx bx-bell cwh'></i>

                        <span class="badge badge-secondary">{{appservice.lenth}}</span>
                    </div>
                </a>

               
            </li>

            <li class="nav-item dropdown profile-nav-item">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="menu-profile">

                        <span style="font-size: 17px;" class="name">Hi! {{data.UM_Full_Name}}</span>
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                    </div>
                </a>
                <div class="dropdown-menu">
                    <div class="dropdown-header d-flex flex-column align-items-center">
                        <div class="figure mb-3">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        </div>

                        <div class="info text-center">
                            <span class="name">{{data.UM_Full_Name}}</span>
                            <p style="margin-bottom: 0;" >{{data.UM_User_Name}}</p>
                            <p style="margin-bottom: 0;" >{{appservice.Emp_Dept}}</p>
                            <p style="margin-bottom: 0;" >{{appservice.Emp_Approver_Name}}</p>
                          
                           
                        </div>
                    </div>

                    <div class="dropdown-body">
                        <ul class="profile-nav p-0 pt-3">
                           

                         

                         

                            <li class="nav-item">
                                <a  class="nav-link" routerLink="/change-password">
                                    <i class='bx bx-cog'></i> <span  style="color: #000;">Change Password</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a (click)="logout()" class="nav-link">
                                    <i class='bx bx-log-out'></i> <span  style="color: #000;">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                   
                </div>
            </li>
        </ul>
    </div>

  
  
</nav>



<div  class="bottommenu-area border-top ng-star-inserted mshow" *ngIf="this.appservice.disp_mmenu==true" style="">
    <div  class="sidemenu-body">
        <ul  class="sidemenu-nav"
            style="width: 100%; text-align: center!important; padding: 0px!important;">
            <li  (click)="get_Home()" routerlinkactive="active" class="nav-item active"
                style="float: left; width: 25%; align-items: center;"><a 
                    class="nav-link" style="padding: 2px 2px;"
                    ><span  class="icon"><i 
                            class="bx bx-grid-alt"></i></span><br ><span 
                        class="menu-title">Home</span></a></li>
            <li  (click)="Bill()"   routerlinkactive="active" class="nav-item"
                style="float: left; width: 25%; align-items: center;"><a 
                     class="nav-link" style="padding: 2px 2px;"
                   ><span  class="icon"><i 
                            class="bx bx-hotel"></i></span><br ><span 
                        class="menu-title">Bill Enty </span></a></li>
            <li (click)="Result()" routerlinkactive="active" class="nav-item"
                style="float: left; width: 25%; align-items: center;"><a 
                     class="nav-link" style="padding: 2px 2px;"
                    ><span  class="icon"><i
                             class="bx bx-notepad"></i></span><br ><span
                         class="menu-title">Result</span></a></li>

                         <li (click)="search()" routerlinkactive="active" class="nav-item"
                         style="float: left; width: 25%; align-items: center;"><a 
                              class="nav-link" style="padding: 2px 2px;"
                             ><span  class="icon"><i
                                      class="bx bx-search"></i></span><br ><span
                                  class="menu-title">Search</span></a></li>
           
        </ul>
    </div>
</div>


<!-- End Top Navbar Area -->




