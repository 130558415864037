import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { interval } from 'rxjs';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";

  public Server_URL = "http://localhost:5837/";

  public Quotation_Format="Format1";
  public System_mac_address="Not found"
  public Area_Map: boolean = false;
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public NT_Sales_Disp_Text1 = "Open Invoice";
  public Sample_No_Type = "Bill_No";

  public Link = "";
  public CM_Name = "";
  public CM_Email_ID = "";
  public Purchase_entry_page = "/Purchase/purchase-entry";
  public Sales_Disp_Text1 = "Open Invoice";
  public Sales_Disp_Text2 = "Open Invoice(with code)";
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;
  public Lab_Home_Page = "/master/account-dashboard";
  public Lab_Report_With_Header="General";
  public Lab_Report_Without_Header="Gendral1";
  public Lab_Bill_Format="Sales_Bill";
  public Login_Header="Laboratory Management";

   public mani_class="";

  public TCS_Enable: boolean = true;
  public Balance_SMS = "false";

  public Sales_Entry_Page="/transaction/patient-entry";
  public Test_Entry_Page="/transaction/test_request-details";

  public key_value="";

  public header_hide:boolean=false;



  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:5837/";

  public DB_Vadi_display: boolean = false;
  public Print_Button: boolean = false;
  public Normal_Value_Update:boolean=false;

  public Ledger_Update:boolean=true;
  public Cust_Amt: boolean = false;
  public Lfrom = "34";
  public Top = 50;


  public  Ins_Data:any={};

  public Logo_location = "Top_qubha";
  public Logo_Name = "Qubha";
  public Logo_location_Loin = "Chef";
  public Logo_Sub_Name = "Soft";
  public Phone_No = "9597436220";
  public Button_Name = "Save";

  public Cam_Type="Proof";
  public Proof_Image:any;
  public Visa_Image:any;
  public Empty_Image:any;
  public Email_Subject="National Lab";
  public Report_Body="National Lab";

  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = true;
  public Check_Stock: boolean = false;
  public Spec_Type: boolean = false;
  public SMS_Send: boolean = false;
  public Visible_DB_Print_Barcode: boolean = true;
  public Visible_DB_Test_Penging: boolean = true;
  public Sales_Edit_Visible: boolean = true;
  public Sales_Delete_Permanant: boolean = false;

  public Whatup_Lab_Name="Blood Line Diagnostics";

 


  public Feed_Back_Link="Thank you for your visit to Apollo Tyres Ltd, Chennai Plant. Click the link to share your visit feedback."
  
  public Image_disp: boolean = false;
  public Ledger_Type = "1";
  public Special_Notes:any;
  public Group_Enable: boolean = false;
  public Print_Bill: boolean = false;
  
  public Save_Print: boolean = false;
  public Save_Print_Mobile: boolean = false;
  public Save_New: boolean = false;

  public  Stockbase_Sales: boolean = false;


  //public Logo_Name = "GKDMK";
  //public Logo_location = "GKDMK";
  //public Logo_location_Loin = "GKDMK";


  //public Logo_Name="Qubha";
  //public Logo_location="Top_qubha";
  //public Logo_location_Loin="Login_buhari";
  Lable_Order_entry="Order Entry";
  Lable_Takeaway="Takeaway";
  Lable_Dining="Dining";
  Lable_Purchase = "Purchase";
  Lable_Sales = "Sales";
  Lable_Receipt = "Receipt";
  Lable_Payment = "Payment";
  Lable_Expence = "Expence";
  Lable_Daybook = "Day Book";
  Lable_Receivable = "Receivable";
  Lable_Payable = "Payable";
  Lable_Item_Master = "Item Master";
  Lable_Ledger = "LedgerMaster";
  Lable_Reports = "Reports";
  Lable_Minimum_Stock = "Minimum Stock";

  public PRate_disp="user"
  public U_Rights=""
  public Item_Group_ = "1";
  public from_customer_page:boolean=false;


  public isadd_Page:boolean=false;
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));

      try {
        this.Customer_Area = localStorage.getItem('Area');
      } catch { }
      this.Company = "_" + this.data.UM_Company;

    }


    this.W_height = window.innerHeight;

    this.W_width = window.innerWidth;

    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");

    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");
   

    this.Pay_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");



    this.Y_Date = this.datePipe.transform(new Date().getDate() - 1, "dd-MMM-yyyy");
    this.Today_Date = Today_Date;


  
    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.AS_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.Y_From= this.datePipe.transform(new Date(), "yyyy-MM")+"-01";

    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.AS_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };

    this.Due_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
   
    this.load_themes('');


    interval(30000).subscribe(x => {
      this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");
     
   });

  }


  public openModal: boolean = false;
  Open_Modal(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }

  Close_Modal() {
    this.openModal = false;
    $('#small_Module').modal('hide');
  }

  get_time()
  {
    this.T_Date_TimeD = this.datePipe.transform(new Date(), "dd-MM-yyyy hh:mm ");
  }
  get_time1()
  {
    this.T_Date_TimeD = this.datePipe.transform(new Date(), "dd-MM-yyyy hh:mm:ss");
  }

  public SM_Row = [];
  public Header_Disp = "";

  public add_item_name = "/add-items1";
  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "";
  public CREATED_BY = "";

  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Share_Data: any = {};
  public Selected_Row: any = {};
  public Default_ref_ID: string = "Role";
  public URL_Location = "";
  public Hotel_Reprint = "false";

  public Condi_Sales = "";

  public Checkin_Room_No = "";
  public Room_CIN_ID = "";

  public disp_category:boolean=false;
  public disp_mmenu:boolean=false;

  public Sales_Entry_Row_width =
    {
      ID: "5%",
      Code: "10%",
      Name: "23%",
      Desc: "17%",
      Rate: "15%",
      Qty: "15%",
      Amount: "15%"
    };


  public SER_Disp =
    {
      ID: true,
      Code: false,
      Name: true,
      Desc: true,
      Rate: true,
      Qty: true,
      Amount: true
    };


    public Instrument_Type = "All";
    public Current_Status = "All";
    public Result_Status ="All";
    public Bill_Status ="All";
    public Out_Status ="All";

    public L_Test_Status ="Pending"
    public Enquiry_Status ="Open"

  public Sales_Temp_Row: any = {};
  public Sales_Temp_Row1 = [];
  public Proforma_Details_GF = ['Customer_Name', 'Bill_No'];
  public BOM_Master_GF = [];
  public Typewise_Daybook_GF = ['Nar_Type', 'DB_AMT', 'AC_Date', 'CR_AMT'];
  public Referer_Master_GF = [];

  public Quatation_Details_GF = [];
  public Ledger_Master_GF = ['Patient_ID','Phone_Number','Ledger_Name'];
  public Visitor_Master_GF = ['Contact_No'];

  public Item_Master_GF = [];
  public Referrer_Master_GF = [];
  public Testmaster_GF = [];
  public Test_Master_GF = [];
  public Sample_Collection_GF = ['Contact_No','Patient_Name','Test_Name','Sample_Name','Sample_ID'];
  public Testwise_Pending_GF = ['Bill_No','Bill_Date','Item_Name'];
  public Drug_Master_GF = ['Drug_Name','Unit'];

  public Equipment_Master_GF = [];

  public Product_Master_GF = [];
  public Purchase_Master_GF = [];
  public Stock_Adjustment_GF = [];
  public Blocked_Visitor_GF = [];
  public Area_Master_GF = [];
  public Purchase_Order_Details_GF = [];
  public Member_Details_GF = [];
  public Area_Street_Details_GF = [];
  public Production_Details_GF = [];
  public Employee_Details_GF = [];
  public Instrument_Details_GF = [];
  public Instrument_Request_GF = ['Department','INS_Name','INS_Type','Requested_by','Created_by','Approval_Status','Range'];
  public Department_Email_GF = [];
  public Ins_Standard_Procedure_GF = [];

  public Vadi_Details_GF = [];
 public Holiday_Details_GF = [];
  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];

  public Expense_Details_GF = ['Category_', 'Narration1', 'Amount'];
  public Contra_Details_GF = [ 'Naration', 'Amount'];
  public Enquiry_Details_GF = ['Name', 'Phone_No','Command','Enquiry_Status'];

  public Sales_Details_GF = ['Customer_Name', 'Bill_No','Bill_Date','Disc_Amt','Sub_Total','Net_Amt','Contact_No'];
  public Delivery_Details_GF = ['Customer_Name', 'DC_No'];
  public Test_Details_GF = [];

  public Tharikar_Detail_GF = ['Customer_Name', 'Bill_No'];
  public Dyeing_Detail_GF = ['Customer_Name', 'Bill_No'];


  public Damage_Details_GF = ['Customer_Name', 'Damage_No'];
  public Minimum_Stock_GF = ['Item_Name', 'Stock', 'Ledger_name', 'Reorder_Level','Min_stock'];

  public Sales_Return_Details_GF = ['Customer_Name', 'Bill_No'];
  public Billwise_Purchase_GF = ['Supplier_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];
  public Customerwise_GF = ['Customer_Name', 'Bill_No', 'Bill_Date','Area', 'Phone_No', 'Amount'];
  public Billwise_Track_GF = ['Bill_No', 'Bill_Date', 'Profit', 'P_Value','S_Value','Supplier'];
  public Itemwise_Track_GF = ['Item_Name','P_Qty' ,'S_Qty', 'Stock_Qty','Profit', 'P_Value','S_Value','Stock_Value'];
  public Supplierwise_Track_GF = ['Supplier', 'S_Value', 'Profit', 'Stock_Value','P_Value'];
  public Categorywise_Track_GF = ['Category','P_Qty' ,'S_Qty', 'Stock_Qty','Profit', 'P_Value','S_Value','Stock_Value'];
  public Price_List_GF = ['Category', 'Item_Name', 'MRP', 'Rate','Wholesale_Rate'];
  public Cash_Settlement_GF = ['Cash_Amt', 'Card_Amt','Bill_No', 'Bill_Date', 'Cheque_Amt','Credit_Amt,', 'Less_Amt'];

  public Track_GF = ['Item_Name', 'Supplier','Bill_No', 'Bill_Date', 'Profit','S_Value', 'P_Value'];
  public Visitor_GF = ['Visitor_Name', 'V_From_Time', 'Visitior_Status', 'Company_Name', 'Purpose','V_Person'];
  public Approved_GF = ['Visitor_Name', 'V_From_Time', 'Visitior_Status', 'Company_Name', 'Purpose','V_Person'];
  public Visitorwise_GF = ['Visitor_Name','Card_No', 'Contact_No', 'Vistor_Type', 'Company_Name', 'V_Department','V_Person'];
  public Instrument_GF = ['INS_Name','Range', 'INS_Type', 'Apprval_Status', 'Created_Date_','Requested_by','Department'];
  public Calibration_GF = ['Next Due','Certificate_No', 'Calibrated_by', 'Verified_by', 'Result'];

  public Search_GF = ['Visitor_Name', 'Contact_No', 'Vistor_Type', 'Company_Name', 'V_Department','V_Person'];
  public Departmentwise_GF = ['Visitor_Name', 'Vistor_Type', 'Company_Name', 'V_Department','V_Person'];
  public Personwise_GF = ['Visitor_Name', 'Vistor_Type', 'Company_Name', 'V_Department','V_Person'];
  public Purposewise_GF = ['Visitor_Name', 'Vistor_Type', 'Company_Name', 'V_Department','V_Person'];
  public Daywise_GF = ['V_From_Time', 'Vistor_Type', 'Company_Name', 'V_Department','V_Person'];
  public SMS_Send_GF = [ 'SMS_Status', 'Date_','Time', 'Phone_No'];
  public Reprint_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];
  public Outstation_GF = ['Item_Name', 'Bill_No', 'Bill_Date', 'Out_Status', 'Net_Amt','Outstation_Amount','out_Source_Lab_ID_'];
  public Outstation_Share_GF = ['Item_Name', 'Bill_No', 'Send_Date', 'Out_Status', 'Net_Amt','out_Source_Lab_ID_'];
  public Labwise_Share_GF = ['Item_Name', 'Bill_No', 'Bill_Date', 'Out_Status', 'Net_Amt','out_Source_Lab_ID_'];
  public Home_Visit_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];

  public Billwise_Sales_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];
  public Delivery_Detail_GF = ['Customer_Name','Item_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];

  public Billwise_Profit_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount', 'Profit'];
  public Itemwise_Purchase_GF = ['Item_Name', 'Amount', 'Qty'];
  public Productwise_Sales_GF = ['Item_Name', 'Amount', 'Qty'];
  public Referwise_Share_GF = ['Name', 'Amount', 'Disc_Per','Share'];
  public Refer_Doctorwise_Sales_GF = ['Refer_Doctor', 'Amount', 'Qty'];
  public Share_Ratio_GF = ['Bill_No','Bill_Date','Name', 'Amount', 'Disc_Per','Share','Item_Name'];
  public Servicewise_share_GF = ['Outstation_Amount', 'Amount', 'Disc_Per','Share','Item_Name'];

  public Refererwise_Sales_GF = ['Refer_Type', 'Amount', 'Qty'];
  public Refererwise_Share_GF = ['Refer_Type_', 'Amount', 'Disc_Per'];

  public Itemwise_Sales_GF = ['Item_Name', 'Amount', 'Qty'];
  public Itemwise_Profit_GF = ['Item_Name', 'Amount', 'Qty', 'Profit'];
  public Userwise_Purchase_GF = ['User', 'Amount', 'count'];
  public Paymodewise_Sales_GF = ['Pay_Mode', 'Amount'];

  public Userwise_Sales_GF = ['User', 'Amount', 'count'];
  public Entrywise_col_GF = ['Ledger_Name', 'Bill_No', 'Amount', 'Receipt_No'];
  public Day_book_GF = ['Nar_Type', 'Created_by', 'Pay_Mode_', 'CR_AMT'];
  public Print_Bar_GF = ['Item_Name', 'Description', 'Uni_Code'];

  public Daybook_Amt_GF = ['Bank', 'Amount'];

  public Customer_ledger_GF = ['Customer_Name', 'Phone_No', 'Bill_No', 'Net_Amt'];
  public Billwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];
  public Billwisepayable_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];

  public Customerwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];
  public Supplierwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];

  public Areawise_out_GF = ['Area', 'Amount', 'count'];
  public Streetwise_out_GF = ['Street', 'Amount', 'count'];

  public Entrywise_exp_GF = ['Category_', 'Narration', 'Created_by'];
  public Itemwise_exp_GF = ['Narration', 'Amount'];
  public Typewise_exp_GF = ['Category', 'Amount'];
  public Userwise_exp_GF = ['User', 'Amount'];
  public Userwise_col_GF = ['Created_By', 'Amount'];
  public Areawise_col_GF = ['Area', 'Amount'];
  public Paymodewise_col_GF = ['Pay_Mode_', 'Amount'];
  public Custemerwise_col_GF = ['Customer', 'Amount'];
  public Entrywise_order_GF = ['Order_No', 'Date', 'Customer_Name', 'Contact_No', 'Amount'];
  public Arearwise_sales_GF = ['Area', 'Amount'];
  public Sales_Personwise_sales_GF = ['Sales_person', 'Amount'];

  public Arearwise_Purchase_GF = ['Area', 'Amount'];

  public Paymodewise_sales_GF = ['Pay_Mode', 'Amount'];
  public Paymodewise_Purchase_GF = ['Pay_Mode', 'Amount'];
  public Supplierwise_purchase_GF = ['Supplier_Name', 'count', 'Amount'];

  public Customerwise_sales_GF = ['Customer_Name', 'count', 'Amount'];
  public Customerwise_Profit_GF = ['Customer_Name', 'count', 'Amount', 'Profit'];
  public Category_stock_GF = [ 'Category',  'Stock', 'Value'];

  public Current_stock_GF = ['Item_Name', 'Description', 'Category', 'Rate', 'Stock', 'Value','MRP'];
  public Itemwise_stock_GF = ['Vour_Type', 'Voucher_No', 'Voucher_Date_', 'Rate'];
  public Stock_report_GF = ['Item_Name', 'Category', 'Opening', 'Rate', 'Inward', 'Outward', 'Closing'];
  public Out_Of_Calibration_GF = ['Calibration_Date','INS_ID', 'INS_Name', 'Make', 'Certificate_No','Approved_by'];
  public Plan_Vs_Actual_GF = ['Calibration_Date','Next_Calibration_Date','INS_ID', 'INS_Name','Range','INS_Type', 'Make', 'Certificate_No','Calibrate_at'];
  public Test_Summary_GF = ['Request_Date','Test_Target_Date','Request_No', 'Purpose_of_Test','	Test_Status'];

  public Calibration_Cost_GF = ['Calibration_Date','INS_ID', 'INS_Name', 'Make', 'Vendor_Name','Calibration_Cost'];
  public Vendorwise_GF = ['Vendor_Name', 'count','Cost'];
  public Monthwise_GF = ['Month', 'count','Cost'];

 public Track_Report = [
    { Menu: 'Billwise', Link: '/report/billwise-track', class: '' },
    { Menu: 'Itemwise', Link: '/report/itemwise-track', class: '' },
    { Menu: 'Supplierwise', Link: '/report/supplier-track', class: '' },
    { Menu: 'Categorywise', Link: '/report/category-track', class: '' },
    { Menu: 'Track Details', Link: '/report/product-track', class: '' },

  ];

  public Salse_Report = [
    { Menu: 'Billwise', Link: '/report/sales-report', class: '' },
    { Menu: 'Servicewise', Link: '/report/service-wise-report', class: '' },
   
    { Menu: 'Refer Typewise', Link: '/report/refererwise-report', class: '' },
     { Menu: 'Refererwise', Link: '/report/refer-doctorwise-report', class: '' },
     { Menu: 'Paymodewise', Link: '/report/paymode-wise-report', class: '' },


  ];
  public Home_Visit_Report = [
    { Menu: 'Home Visit', Link: '/report/home-visit', class: '' },
 
  ];
  public Share_Ratio = [
    { Menu: 'Billwise', Link: '/report/share-ratio', class: '' },
    { Menu: 'Servicewise', Link: '/report/servicewise-share', class: '' },

    { Menu: 'Refer Typewise', Link: '/report/refer-typewise-share', class: '' },
    { Menu: 'Refererwise', Link: '/report/refererwise-share', class: '' },
   

  ];
  public Outstation_Share = [
    { Menu: 'Billwise', Link: '/report/outstation-share', class: '' },
    { Menu: 'Labwise', Link: '/report/labwise-share', class: '' },
   

  ];
  public Reprint_Report = [
    { Menu: 'Reprint Report', Link: '/report/reprint-report', class: '' },
 
   

  ];
  public Outstation_Report = [
    { Menu: 'Outstation Report', Link: '/report/outstation-report', class: '' },
 
   

  ];
  public SMS_Report = [
    { Menu: 'SMS-Report', Link: '/report/sms-report', class: '' },
   

  ];







   public Salse_Gst_Report = [
    { Menu: 'Sales GST', Link: '/report/gst-report', class: '' },
    { Menu: 'Sales GST(%)', Link: '/report/sales-gst-one', class: '' },
   
  ];
  public Purchase_Report = [
    { Menu: 'Billwise', Link: '/report/billwise-purchase', class: '' },
    { Menu: 'Itemwise', Link: '/report/itemwise-purchase', class: '' },
    { Menu: 'Userwise', Link: '/report/userwise-purchase', class: '' },
    { Menu: 'Daywise', Link: '/report/daywise-purchase', class: '' },
    { Menu: 'Areawise', Link: '/report/areawise-purchase', class: '' },
    { Menu: 'supplierwise', Link: '/report/supplierwise-purchase', class: '' },
    { Menu: 'Paymodewise', Link: '/report/paymodewise-purchase', class: '' },

  ];
  public Profit_Report = [
    { Menu: 'Billwise-Profit', Link: '/Billwise-Profit', class: '' },
    { Menu: 'Itemwise-Profit', Link: '/Itemwise-Profit', class: '' },
    { Menu: 'Customerwise-Profit', Link: '/Customerwise-Profit', class: '' },
    { Menu: 'Over-all-Profit', Link: 'Over-all', class: '' },

  ];
  public Outstanding_Report = [
    { Menu: 'Billwise', Link: '/report/billwise-out' },
    { Menu: 'Customerwise', Link: '/report/customerwise-out' },
    { Menu: 'Due daywise', Link: '/report/duedaywise-out' },
    { Menu: 'Salespersonwise', Link: '/report/salespersonwise-out' },

  ];
  public Payable_Outstanding_Report = [
    { Menu: 'Billwise', Link: '/report/billwisepayable-out' },
    { Menu: 'Supplierwise', Link: '/report/supplierwise-out' },

  ];
 

  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/user-details' },
    { Menu: 'Role Master', Link: '/role-master' },
    { Menu: 'Role Rights', Link: '/role-permissions' }

  ];


  public Order_Report = [
    { Menu: 'Entrywise', Link: '/report/entrywise-order' },
    { Menu: 'Itemwise', Link: '/report/itemwise-order' },
    { Menu: 'Userwise', Link: '/report/userwise-order' },
    { Menu: 'Daywise', Link: '/report/daywise-order' },
    { Menu: 'Customerwise', Link: '/report/customerwise-order' },
    { Menu: 'Areawise', Link: '/report/areawise-order' },

  ];

''
  public Ledger_Report = [
    { Menu: 'Day Book', Link: '/report/day-book', class: '' },
    { Menu: 'Type wise', Link: '/report/typewise-daybook', class: '' },

  

  ];

  public Test_Entry_Details = [
    { Menu: 'Pending', Link: '/transaction/test-entry-details', class: '' },
    { Menu: 'Result Update', Link: '/transaction/test-entry-details', class: '' },

  

  ];


  public Stock_Report = [

    { Menu: 'Current Stock', Link: '/report/current-stock', class: '' },
    { Menu: 'Category Stock', Link: '/report/Categorywise-stock', class: '' },
    { Menu: 'Itemwise Stock', Link: '/report/itemwise-stock', class: '' },
    { Menu: 'Stock Report', Link: '/report/stock-report', class: '' }
  ];




  public Expense_Report = [
    { Menu: 'Entrywise', Link: '/report/entrywise-exp' },
    { Menu: 'Itemwise', Link: '/report/itemwise-exp' },
    { Menu: 'Typewise', Link: '/report/typewise-exp' },
    { Menu: 'Userwise', Link: '/report/userwise-exp' },
    { Menu: 'Paymodewise', Link: '/report/paymodewise-exp' },
    { Menu: 'Daywise', Link: '/report/daywise-exp' }
  ];


  public Collection_Report = [

    { Menu: 'Entrywise', Link: '/report/entrywise-col' },
    { Menu: 'Customerwise', Link: '/report/customerwise-col' },
    { Menu: 'Userwise', Link: '/report/userwise-col' },
    { Menu: 'Areawise', Link: '/report/areawise-col' },
    { Menu: 'Paymodewise', Link: '/report/paymodewise-col' }

  ];
  public Customer_Profile = [

    { Menu: 'Overview', Link: '/transaction/customer-overview' },
    { Menu: 'Ledger', Link: '/transaction/customer-ledger' },
    { Menu: 'Sales', Link: '/transaction/customer-sales' },
    { Menu: 'Payment', Link: '/transaction/customer-payment' }

  ];

  public Dmk_Report = [

    { Menu: 'Entrywise', Link: '/dmk/entrywise' },
    { Menu: 'Statewise', Link: '/dmk/statewise' },
    { Menu: 'Thoguthiwise', Link: '/dmk/thoguthiwise' },
    { Menu: 'Districtwise', Link: '/dmk/districtwise' },

  ];
  public Customerwise_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Area', Name: 'Area', Align: '' },

    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Payment', Name: 'Payment', Align: 'right' }

  ];
  public Billwise_sales_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date  ', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Total Amt', Align: 'right' },
    { Field: 'Disc_Amt', Name: 'Disc Amt', Align: 'right' },
    { Field: 'Rec_Amt', Name: 'Rec Amt', Align: 'right' }

  ];
  public Home_Visit_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date  ', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Contact_No', Name: 'Phone No', Align: '' },
    { Field: 'Home_Visit_Person', Name: 'Visit Person', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Reprint_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date  ', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'WH_print_Count', Name: 'WH print Count', Align: 'right' },
    { Field: 'WOH_print_Count', Name: 'WOH print Count', Align: 'right' }

  ];
  public Outstation_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Send_Date', Name: 'Send Date  ', Align: '' },
    { Field: 'Item_Name', Name: '    Test Name     ', Align: '' },
    { Field: 'out_Source_Lab_ID_', Name: 'Outstation Lab', Align: '' },

    { Field: 'Out_Status', Name: 'Status', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Outstation_Amount', Name: 'Outstation Amt', Align: 'right' }

  ];
  public Outstation_Share_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Send_Date', Name: 'Send Date  ', Align: '' },
    { Field: 'Item_Name', Name: ' Test Name ', Align: '' },
    { Field: 'out_Source_Lab_ID_', Name: 'Outstation Lab', Align: '' },
    { Field: 'Amount', Name: '  Amt  ', Align: 'right' },
    { Field: 'Disc_Per', Name: 'Share%', Align: 'right' },
    { Field: 'Share', Name: 'Share Amt', Align: 'right' }


  ];
  public Labwise_Share_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Send_Date', Name: 'Send Date  ', Align: '' },
    { Field: 'out_Source_Lab_ID_', Name: 'Outstation Lab', Align: '' },

    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Outstation_Amount', Name: 'Outstation Amt', Align: 'right' },

    { Field: 'Disc_Per', Name: 'Share(%)', Align: 'right' },

    { Field: 'Share', Name: 'Share', Align: 'right' }


  ];
  public Typewise_Daybook_Export = [

    { Field: 'AC_Date', Name: 'Date', Align: ' ' },
    { Field: 'Nar_Type', Name: 'Type', Align: ' ' },
    { Field: 'CR_AMT', Name: 'Credit', Align: 'right' },
    { Field: 'DB_AMT', Name: 'Debit', Align: 'right' }

  ];
  public Refer_Doctorwise_Export = [

    { Field: 'Refer_Doctor', Name: 'Refer Doctor', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Refererwise_Export = [

    { Field: 'Refer_Type', Name: 'Refer Type', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Share_Ratio_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date_', Name: ' Bill Date ', Align: '' },
    { Field: 'Name', Name: '  Referer ', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Outstation_Amount', Name: 'Outstation Amt', Align: 'right' },
    { Field: 'Disc_Per', Name: 'Share(%)', Align: 'right' },
    { Field: 'Share', Name: 'Share Amt', Align: 'right' }

  ];
  public Refererwise_Share_Export = [

    { Field: 'Refer_Type_', Name: 'Referer Type', Align: '' },
    { Field: 'Name', Name: 'Referer', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Outstation_Amount', Name: 'Outstation Amt', Align: 'right' },
    { Field: 'Disc_Per', Name: 'Share(%)', Align: 'right' },
    { Field: 'Share', Name: 'Share Amt', Align: 'right' }

  ];
  public Refertypewise_Share_Export = [

    { Field: 'Refer_Type_', Name: 'Referer', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Outstation_Amount', Name: 'Outstation Amt', Align: 'right' },

    { Field: 'Disc_Per', Name: 'Share(%)', Align: 'right' },
    { Field: 'Share', Name: 'Share Amt', Align: 'right' }


  ];
  public SMS_Send_Export = [

    { Field: 'Date_', Name: 'Date', Align: '' },
    { Field: 'Time', Name: 'Time', Align: '' },

    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'SMS_Status', Name: 'SMS Status', Align: '' }

  ];
  public Visitorwise_Export = [

    { Field: 'Vistor_Type', Name: 'Vistor Type ', Align: '' },
    { Field: 'Visitor_Name', Name: 'Visitor Name', Align: '' },
    { Field: 'Company_Name', Name: 'Company Name', Align: '' },
    { Field: 'Contact_No', Name: 'Phone No', Align: '' },
    { Field: 'V_Department', Name: 'Department', Align: '' },
    { Field: 'V_Person', Name: 'Person', Align: '' },
    { Field: 'Purpose', Name: 'Purpose', Align: '' }


  ];
  public Calibration_Cost_Export = [

    { Field: 'INS_ID', Name: 'INS ID ', Align: '' },
    { Field: 'INS_Name', Name: 'INS Name', Align: '' },
    { Field: 'Calibration_Date', Name: 'Calibration Date', Align: '' },
    { Field: 'Make', Name: 'Make', Align: '' },
    { Field: 'Vendor_Name', Name: 'Vendor Name', Align: '' },
    { Field: 'Calibration_Cost', Name: 'Calibration Cost', Align: '' },


  ];
  public Out_Of_Calibration_Export = [

    { Field: 'INS_ID', Name: 'INS ID ', Align: '' },
    { Field: 'INS_Name', Name: 'INS Name', Align: '' },
    { Field: 'Calibration_Date', Name: 'Calibration Date', Align: '' },
    { Field: 'Certificate_No', Name: 'Certificate No', Align: '' },
    { Field: 'Make', Name: 'Make', Align: '' },
    { Field: 'Approved_by', Name: 'Verified By', Align: '' },


  ];
  public Plan_Vs_Actual_Export = [
    { Field: 'INS_Type', Name: 'INS Type ', Align: '' },
    { Field: 'INS_ID', Name: 'INS ID ', Align: '' },
    { Field: 'Calibration_Date', Name: 'Calibration Date', Align: '' },
    { Field: 'Next_Calibration_Date', Name: 'Next Calibration Date', Align: '' },
    { Field: 'INS_Name', Name: 'INS Name', Align: '' },
    { Field: 'Make', Name: 'Make', Align: '' },
    { Field: 'Range', Name: 'Range', Align: '' },
    { Field: 'Calibrate_at', Name: 'Calibrate At', Align: '' },
    { Field: 'Certificate_No', Name: 'Certificate No', Align: '' },


  ];
  public Vendorwise_Export = [

   
    { Field: 'Vendor_Name', Name: 'Vendor Name', Align: '' },
    { Field: 'count', Name: 'No of Istrument', Align: '' },
    { Field: 'Cost', Name: 'Cost', Align: '' },


  ];
  public Monthwise_Export = [

   
    { Field: 'Month', Name: 'Month', Align: '' },
    { Field: 'count', Name: 'No of Istrument', Align: '' },
    { Field: 'Cost', Name: 'Cost', Align: '' },


  ];
  public Instrument_Request_Export = [
    { Field: 'Department', Name: 'Department ', Align: '' },
    { Field: 'Requested_by', Name: 'Requested by ', Align: '' },
    { Field: 'INS_Type', Name: 'INS Type ', Align: '' },
    { Field: 'INS_Name', Name: 'INS Name', Align: '' },
    { Field: 'Range', Name: ' Range ', Align: '' },
    { Field: 'Created_Date_', Name: '   Date    ', Align: '' },
    { Field: 'Apprval_Status', Name: ' Status ', Align: '' },
   
    
  ];
  
  public Calibration_Report_Export = [
    { Field: 'INS_ID', Name: 'INS ID', Align: '' },
    { Field: 'INS_Type', Name: 'INS Type', Align: '' },
    { Field: 'INS_Name', Name: 'INS Name', Align: '' },
    { Field: 'Certificate_No', Name: 'Certificate No', Align: '' },

    { Field: 'Calibration_Date', Name: 'Certificate Date', Align: '' },
    { Field: 'Next_Due', Name: 'Next Due', Align: '' },
    { Field: 'Calibrated_by', Name: 'Calibrated by', Align: '' },
    { Field: 'Result', Name: 'Result', Align: '' },
    { Field: 'Approved_by', Name: 'Verified by', Align: '' },


  ];
  public Userwise_Export = [

    { Field: 'Vistor_Type', Name: 'Vistor Type ', Align: '' },
    { Field: 'Visitor_Name', Name: 'Visitor Name', Align: '' },
    { Field: 'Company_Name', Name: 'Company Name', Align: '' },
    { Field: 'Contact_No', Name: 'Phone No', Align: '' },
    { Field: 'V_Department', Name: 'Department', Align: '' },
    { Field: 'V_Person', Name: 'Person', Align: '' },
    { Field: 'Purpose', Name: 'Purpose', Align: '' }


  ];
  public Departmentwise_Export = [
    { Field: 'V_Department', Name: 'Department', Align: '' },

   
    { Field: 'count', Name: 'No of Visitor', Align: '' },


  ];
  public Daywise_Export = [
    { Field: 'V_From_Time', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'No of Visitor', Align: '' },

  ];
  public Personwise_Export = [

    { Field: 'V_Person', Name: 'Person ', Align: '' },
    { Field: 'count', Name: 'No of Visitor', Align: '' },


  ];
  public Purposewise_Export = [

    { Field: 'Purpose', Name: 'Purpose', Align: '' },
    { Field: 'count', Name: 'No of Visitor', Align: '' },


  ];
  public Billwise_Track_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Supplier', Name: 'Supplier Name', Align: '' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
   public Track_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Supplier', Name: 'Supplier Name', Align: '' },
     { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'P_Qty', Name: 'P Qty', Align: 'right' },
    { Field: 'S_Qty', Name: 'S Qty', Align: 'right' },
    { Field: 'Stock_Qty', Name: 'Stock Qty', Align: 'right' },

    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
   public Supplierwise_Track_Export = [

    { Field: 'Supplier', Name: 'Supplier Name', Align: '' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Minimum_Stock_Export = [

    { Field: 'Ledger_name', Name: 'Supplier Name', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Reorder_Level', Name: 'Reorder Level', Align: 'right' },
    { Field: 'Min_stock', Name: 'Min stock', Align: 'right' },
    { Field: 'Stock', Name: 'Stock', Align: 'right' }


  ];
   public Itemwise_Track_Export = [

    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'P_Qty', Name: 'P Qty', Align: 'right' },
    { Field: 'S_Qty', Name: 'S Qty', Align: 'right' },
    { Field: 'Stock_Qty', Name: 'Stock Qty', Align: 'right' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Categorywise_Track_Export = [

    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'P_Qty', Name: 'P Qty', Align: 'right' },
    { Field: 'S_Qty', Name: 'S Qty', Align: 'right' },
    { Field: 'Stock_Qty', Name: 'Stock Qty', Align: 'right' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Delivery_Details_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  
  public Billwise_Purchase_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Supplier_Name', Name: 'Suppier Name', Align: '' },
    { Field: 'Contact_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Billwise_Profit_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }

  ];


  public Daybook_Export = [

    { Field: 'AC_Date', Name: 'Date', Align: ' ' },
    { Field: 'Nar_Type', Name: 'Type', Align: ' ' },
    { Field: 'Narration1', Name: 'Narration', Align: ' ' },
    { Field: 'Pay_Mode_', Name: 'Pay Mode', Align: '' },
    { Field: 'Created_by', Name: 'User', Align: 'right' },
    { Field: 'CR_AMT', Name: 'Credit', Align: 'right' },
    { Field: 'DB_AMT', Name: 'Debit', Align: 'right' }

  ];

  public Areawise_sales_Export = [
    { Field: 'Area', Name: 'Area ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Sales_Personwise_sales_Export = [
    { Field: 'Sales_person', Name: 'Sales Person ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Areawise_Purchase_Export = [
    { Field: 'Area', Name: 'Area ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Paymodewise_sales_Export = [
    { Field: 'Pay_Mode', Name: 'Pay Mode ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Paymodewise_Purchase_Export = [
    { Field: 'Pay_Mode', Name: 'Pay Mode ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];

  public Productwise_sales_Export  = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' },
    { Field: 'Qty', Name: 'Qty', Align: 'right' },
    { Field: 'Free', Name: 'Free', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Extra_Qty', Name: 'Extra.Qty', Align: 'right' }

  ];
  public Servisewise_share_Export = [
    { Field: 'Item_Name', Name: 'Service Name', Align: '' },
    { Field: 'Qty', Name: 'Qty', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Outstation_Amount', Name: 'Outstation Amt', Align: 'right' },
    { Field: 'Disc_Per', Name: 'Share(%)', Align: 'right' },
    { Field: 'Share', Name: 'Share Amt', Align: 'right' }



  ];
  public Itemwise_sales_Export = [
    { Field: 'Item_Name', Name: 'Service Name', Align: '' },
    { Field: 'Qty', Name: 'Qty', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Paymodewise_collect_Export = [
    { Field: 'Pay_Mode', Name: 'Pay Mode', Align: '' },
    { Field: 'Amount', Name: 'Bill Amt', Align: 'right' },

    //{ Field: 'Collected_Amt', Name: 'Collected Amt', Align: 'right' }

  ];
  public Itemwise_Purchase_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Quality', Align: '' },
    { Field: 'Amount', Name: 'P Amount', Align: 'right' },
    { Field: 'S_Rate', Name: 'S Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }

  ];
  public Itemwise_Profit_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Quality', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }

  ];
  public Userwise_sales_Export = [
    { Field: 'User', Name: 'User', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Userwise_Purchase_Export = [
    { Field: 'User', Name: 'User', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];

  public Daywise_sales_Export = [
    { Field: 'Date', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Daywise_Purchase_Export = [
    { Field: 'Purchase_Date_', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];

  public Customerwise_sales_Export = [
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Supplierwise_purchase_Export = [
    { Field: 'Supplier_Name', Name: 'Supplier Name', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Customerwise_Profit_Export = [
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];

  public Sales_personwise_sales_Export = [
    { Field: 'Date', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];

  public Billwise_out_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone  No', Align: '' },
    { Field: 'Bill_Amount', Name: 'Bill Amt', Align: 'right' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }

  ];
  public Billwisepayable_out_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone  No', Align: '' },
    { Field: 'Bill_Amount', Name: 'Bill Amt', Align: 'right' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }

  ];

  public Customerwise_out_Export = [

    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Supplierwise_out_Export = [

    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];

  public Areawise_out_Export = [
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Streetwise_out_Export = [
    { Field: 'Street', Name: 'Street', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }



  ];


  public Duedaywise_out_Export = [

    { Field: 'Dues', Name: 'Dues', Align: '' },
    { Field: 'count', Name: 'Bills Count', Align: '' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }

  ];

  public Salespersonwise_out_Export = [

    { Field: 'Created_by', Name: 'User', Align: '' },
    { Field: 'count', Name: 'Bills', Align: '' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }
  ];

  public Itemwise_order_Export = [

    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Qty', Align: '' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }
  ];

  public Customerwise_order_Export = [
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];

  public Areawise_order_Export = [

    { Field: 'Area', Name: 'Area Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Daywise_order_Export = [

    { Field: 'Order_Date_', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Userwise_order_Export = [

    { Field: 'User', Name: 'User', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Entrywise_order_Export = [

    { Field: 'Order_No', Name: 'Order No', Align: '' },
    { Field: 'Date', Name: 'Date    ', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name         ', Align: '' },
    { Field: 'Contact_No', Name: 'Contact No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];

  public Pending_order_Export = [

    { Field: 'Order_No', Name: 'Order No', Align: '' },
    { Field: 'Date', Name: 'Date    ', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name         ', Align: '' },
    { Field: 'Contact_No', Name: 'Contact No', Align: '' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }
  ];

  public Itemwisepending_order_Export = [

    { Field: 'Item_Name', Name: 'Item Name      ', Align: '' },
    { Field: 'Bag', Name: ' Bag    ', Align: 'right' },
    { Field: 'Pcs', Name: ' Kg     ', Align: 'right' },
    { Field: 'qty', Name: ' Total Kg ', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Price_List_Export = [
    { Field: 'Category', Name: 'Brand', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Wholesale_Rate', Name: 'Wholesale Rate', Align: 'right' },
    { Field: 'Rate', Name: 'Rate', Align: 'right' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' }

  ];
  public Cash_Settlement_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Cash_Amt', Name: 'Cash_Amt', Align: 'right' },
    { Field: 'Cheque_Amt', Name: 'Cheque Amt', Align: 'right' },
    { Field: 'Card_Amt', Name: 'Card Amt', Align: 'right' },
    { Field: 'Gpay_Amt', Name: 'Gpay Amt', Align: 'right' },
    { Field: 'Credit_Amt', Name: 'Credit Amt', Align: 'right' },
    { Field: 'Less_Amt', Name: 'Less Amt', Align: 'right' }

  ];
  public Item_Master_Export = [
    { Field: 'Display_order', Name: 'order', Align: '' },
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Description', Name: 'Tamil Name', Align: '' },
    { Field: 'Bag_Qty', Name: 'Bag Qty', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' }
  ];
  public TestMaster_Export = [
    { Field: 'Test_Name', Name: 'Test Name', Align: '' },
    { Field: 'Short_Name', Name: 'Short Name', Align: '' },
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Unit', Name: 'Unit', Align: '' },
    { Field: 'Input_type', Name: 'Input type', Align: '' },
    { Field: 'Default_Result', Name: 'Default Result', Align: '' }
  ];
  public Referrer_Master_Export = [
    { Field: 'Name', Name: 'Name', Align: '' },
    { Field: 'Degree', Name: 'Degree', Align: '' },
    { Field: 'Mobile_No', Name: 'Mobile No', Align: '' },
    { Field: 'Contact_Email', Name: 'Contact Email', Align: '' },
    { Field: 'Address', Name: 'Address', Align: '' },
  ];
  public Head_Assignment_Export = [
    { Field: 'Department', Name: 'Department', Align: '' },
    { Field: 'HOD1_Name', Name: 'HOD1 Name', Align: '' },
    { Field: 'HOD2_Name', Name: 'HOD2 Name', Align: '' }
   
  ];
  public Product_Master_Export = [
    { Field: 'Display_order', Name: 'order', Align: '' },
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Description', Name: 'Tamil Name', Align: '' },
    { Field: 'Bag_Qty', Name: 'Bag Qty', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' }
  ];
  public BOM_Master_Export = [
    { Field: 'BOM_Name', Name: 'BOM Name', Align: '' },
    { Field: 'BOM_Qty', Name: 'BOM Qty', Align: '' },
    { Field: 'RM_Name', Name: 'RM Name', Align: '' },
    { Field: 'RM_Qty', Name: 'RM Qty', Align: '' },

  ];



  public Stock_report_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' },
    { Field: 'Opening', Name: 'Opening', Align: '' },
    { Field: 'Inward', Name: 'Inward', Align: '' },
    { Field: 'Outward', Name: 'Outward', Align: 'right' },
    { Field: 'Closing', Name: 'Closing', Align: 'right' }

  ];

  public User_Master_Export = [
    { Field: 'Code', Name: 'Code', Align: '' },
    { Field: 'Name', Name: 'Name', Align: '' },
    { Field: 'User_ID', Name: 'User_ID', Align: '' },
    { Field: 'Password', Name: 'Password', Align: '' },
    { Field: 'Rights', Name: 'Rights', Align: '' },
  

  ];


  public Curent_Stock_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },

    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' },
    { Field: 'Rate', Name: 'Rate', Align: 'right' },
    { Field: 'Stock', Name: 'Stock', Align: 'right' },
    { Field: 'Value', Name: 'Value', Align: 'right' },


  ];
  public Category_Stock_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },

    { Field: 'Stock', Name: 'Stock', Align: 'right' },
    { Field: 'Value', Name: 'Value', Align: 'right' },


  ];
  public Itemwise_Stock_Export = [
    { Field: 'Vour_Type', Name: 'Vour Type', Align: '' },
    { Field: 'Voucher_No', Name: 'Voucher No', Align: '' },
    { Field: 'Voucher_Date_', Name: 'Voucher Date', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' },
    { Field: 'Inward_Qty', Name: 'Inward Qty', Align: '' },
    { Field: 'Outward_Qty', Name: 'Outward Qty', Align: 'right' },
    { Field: 'Balance', Name: 'Balance', Align: 'right' },


  ];
  public Sales_Gst_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'GST_No', Name: 'GST No', Align: 'right' },
    { Field: 'Taxable_Amount', Name: 'Taxable', Align: 'right' },
    { Field: 'IGST_Amt', Name: 'IGST', Align: 'right' },
    { Field: 'SGST_Amt', Name: 'SGST', Align: 'right' },
    { Field: 'CGST_Amt', Name: 'CGST', Align: 'right' },
    { Field: 'Tax_Amt', Name: 'Tax', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }


  ];
  public B2C_Gst_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Supplier_Name', Name: 'Supplier Name', Align: '' },
    { Field: 'GST_No', Name: 'GST No', Align: 'right' },
    { Field: 'Taxable_Amount', Name: 'Taxable', Align: 'right' },
    { Field: 'IGST_Amt', Name: 'IGST', Align: 'right' },
    { Field: 'SGST_Amt', Name: 'SGST', Align: 'right' },
    { Field: 'CGST_Amt', Name: 'CGST', Align: 'right' },
    { Field: 'Tax_Amt', Name: 'Tax', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }


  ];
  public Entrywise_Collection_Export = [
    { Field: 'Receipt_No', Name: 'Receipt No', Align: '' },
    { Field: 'RCDate_', Name: 'RC Date', Align: '' },
    { Field: 'Bill_No', Name: 'Bill_No', Align: '' },
    { Field: 'Ledger_Name', Name: 'Ledger Name', Align: '' },
    { Field: 'Created_By', Name: 'Created By', Align: '' },
    { Field: 'Pay_Mode_', Name: 'Pay Mode', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Areawise_Collection_Export = [
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Paymodewise_Collection_Export = [
    { Field: 'Pay_Mode_', Name: 'Pay Mode', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Customerwise_Collection_Export = [
    { Field: 'Ledger_Name', Name: 'Customer', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Userwise_Collection_Export = [
    { Field: 'Created_By', Name: 'User', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Ledger_Master_Exel_Export = [
    { Field: 'Ledger_Name', Name: 'Ledger Name', Align: '' },
    { Field: 'Short_Name', Name: 'Short Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Street', Name: 'Street', Align: '' },
    { Field: 'Address', Name: 'Address', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'VC_Number', Name: 'VC Number', Align: '' },
    { Field: 'Join_Date', Name: 'Join Date', Align: '' },
    { Field: 'Status', Name: 'Status', Align: '' }

  ];


  public V_Detail_Exel_Export = [
    { Field: 'Visitor_Name', Name: 'Visitor Name', Align: '' },
    { Field: 'V_Person', Name: 'Host', Align: '' },
    { Field: 'Purpose', Name: 'Purpose', Align: '' },
    { Field: 'Contact_No', Name: 'Contact No', Align: '' },
    { Field: 'V_From_Time', Name: 'From', Align: '' },
    { Field: 'V_To_Time', Name: 'To', Align: '' },
    { Field: 'Approval_Status', Name: 'Status', Align: '' },
    { Field: 'Laptop_Approval', Name: 'Laptop', Align: '' },
    { Field: 'Mobile_Approval', Name: 'SmartPhone', Align: '' },
    { Field: 'Remarks', Name: 'Remarks', Align: '' }

  ];


  public Customer_Ledger_Export = [
    { Field: 'Date', Name: 'Date', Align: 'center' },
    { Field: 'Narration1', Name: 'Narration', Align: 'center' },
    { Field: 'CR_Amt', Name: 'Credit', Align: 'right' },
    { Field: 'DB_Amt', Name: 'Debit', Align: 'right' }

    
  ];
  public Instrument_Approval_Export = [
    { Field: 'Department', Name: 'Department', Align: 'center' },
    { Field: 'Requested_by', Name: 'Requested by', Align: 'center' },
    { Field: 'INS_Name', Name: 'Instrument Name', Align: 'center' },
    { Field: 'INS_Type', Name: 'INS Type', Align: '' },
    { Field: 'Range', Name: 'Range', Align: '' },
    { Field: 'Created_Date', Name: 'Requested Date', Align: '' },
    { Field: 'Approval_Status', Name: 'Approval Status', Align: '' }

  ];




  public Test_Master_Export = [];
  public Sample_Collection_Export = [
  
    { Field: 'Collected_Date', Name: 'Collected Date  ', Align: '' },
    { Field: 'Contact_No', Name: 'Contact No', Align: '' },
    { Field: 'Patient_Name', Name: 'Patient Name', Align: 'center' },
    { Field: 'Sample_ID', Name: 'Sample ID', Align: '' },
    { Field: 'Test_Name', Name: 'Test Name', Align: '' },
   

  ];
  public Sales_Export = [
  
    { Field: 'Bill_No', Name: 'Bill No  ', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date  ', Align: '' },
    { Field: 'Customer_Name', Name: 'Patient Name', Align: 'center' },
    { Field: 'Contact_No', Name: 'Contact No', Align: '' },
    { Field: 'Sub_Total', Name: 'Sub Total', Align: 'right' },
    { Field: 'Disc_Amt', Name: 'Disc Amt', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Net Amt', Align: 'right' },

    { Field: 'Received_AMT', Name: 'Paid', Align: 'right' },
    
    { Field: 'Balance', Name: 'Balance', Align: 'right' },


  ];
  public Enquiry_Export = [
  
    { Field: 'Name', Name: 'Name ', Align: '' },
    { Field: 'Date_', Name: ' Date  ', Align: '' },
    { Field: 'Phone_No', Name: 'Contact No', Align: '' },
    { Field: 'Email', Name: 'Email ID', Align: '' },
    { Field: 'Enquiry_Status', Name: 'Enquiry Status', Align: '' },
    { Field: 'Command', Name: 'Command', Align: '' },

    
   

  ];
  public Equipment_Master_Export = [];

  
  public Quotation_Export = [];
  public Purchase_Export = [];
  public Adjustment_Export = [];
  public Test_Request_Export = [];

  public Delivery_Export = [];

  public Tharikar_Export = [];
  public Dyeing_Export = [];
  public Damage_Export = [];

  public Sales_Return_Export = [];

  public Purchase_Order_Export = [];
  public Member_Registration_Export = [];
  public Proforma_Export = [];
  public Ledger_Master_Export = [];
  public Visitor_Master_Export = [];
  public Visitor_Master_Export1 = [];
  public Blocked_Visitor_Export = [];
  public Area_Master_Export = [];

  public Production_Export = [];
  public Employee_Details_Export = [];
 public Holiday_Details_Export = [];
  public img_visible: boolean = false;
  public Instrument_Details_Export = [];
  public Department_Email_Export = [];
  public Ins_Standard_Export = [];
  public M_From="";

  public RC_Bill_Amt_Check: boolean = true;

  public Order_enty_type = "Type1";
  public page_Name = "Order_Entry";

  public from_customer: boolean = false;
  public from_customer_Code = "";

  public DF_Paymode = "244";
  public DF_Checkin_by = "40";
  public DF_Checkin_Type = "35";
  public DF_Stay_purpose = "31";

  public C_Sub_Total = 0;
  public C_Delivery_Charges = 0;
  public C_Taxable = 0;
  public C_IGST = 0;
  public C_SGST = 0;
  public C_CGST = 0;
  public C_Tax = 0;
  public C_Total_Payable = 0;
  public C_Savings = 0;

  public Item_Category = [];
  public Selected_item_Category = "";

  public Date;
  public Date1;
  public From_Date;
  public To_Date;

  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print="false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public Created_Date = "";
  public AS_From = "";

  public S_From = "";
  public Y_From = "";
  public S_To = "";
  public AS_To = "";

  public S_Bill_Type = "All";
  public S_Thari_Status = "All";

  public Category = "All";
  public Equipment_Name="All"
  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public Home_Visit = "All"

  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"


  public Y_Date = "";
  public data: any = {};
  public Order_Status = "Pending";
  public Search_User = "All";
  public Approval_Status = "Pending";
  public Apprval_Status = "Pending";
  public Test_Status = "Pending";
  public Test_Feasibility_Status = "Pending";

  public Laptop_Approval = "Pending"; 
  public Mobile_Approval = "Pending";
  public M_Calibrate_At = "All";
  public M_Out_of_Calibration = "All";

  public Customer_Area = "All";
  public item_name_disp = "English";

  public T_Date = "";
  public T_Date_Time = "";
  public T_Date_TimeD="";

  public Pay_Date="";


  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public datetimefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-ddTHH:mm");
  }
  

  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_ID = "";
  public Emp_ID = "";
  public Emp_Name = "";
  public Emp_Dept= "";
  public Emp_Approver= "";
  public Emp_Approver_Name= "";
 

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

  public Receipt_AMT=0;
  public Ledger_Receipt_Rows=[]
  get_Ledger_Recipts(data) {
    this.isload = true;

    this.get("Api/Transaction/get_Receipt_Details?From=" + this.S_From + "&To=" + this.S_To+"&Ledger="+data).subscribe((res: any) => {
      this.Ledger_Receipt_Rows = JSON.parse(res).record;
      this.isload = false;
      this.Receipt_AMT = (this.Ledger_Receipt_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

    });

  }
  Cash_ID = "";
  Cheque_ID = "";
  Card_ID = "";
  Prow = [];
  Pay_Mode_Rows = [];

  get_pay_mode_ID() {

    try {
      this.Prow = this.Reference_Rows.filter(e => e.Ref_ID == "Pay_Mode");
      this.Pay_Mode_Rows = this.Reference_Rows.filter(e => e.Ref_ID == "Pay_Mode");
      this.Cash_ID = this.Prow.filter(e => e.RGV_vDesciption.toLowerCase() == "cash")[0].RGV_iID;
      this.Cheque_ID = this.Prow.filter(e => e.RGV_vDesciption.toLowerCase() == "cheque")[0]["RGV_iID"];
      this.Card_ID = this.Prow.filter(e => e.RGV_vDesciption.toLowerCase() == "card")[0]["RGV_iID"];
      this.Pay_Mode = this.Cash_ID;
    } catch { }
  }


  public pay_Mode_reset() {
    this.Pay_Mode = this.Cash_ID;
    this.Receiving_Bank = "0";
    this.Cheque_No = "";
    this.Cheque_Date = this.Today_Date;
    this.Card_Charge = "";
    this.Remarks = "";

  }
  public Test_ID: string = "0";
  public Test_Row_: any = {};
  public isEdit: boolean = false;
  public isUpdate: boolean = false;
  public Pay_Mode = "";
  public Bill_Mode = "Cash";
  public Receiving_Bank = "0";
  public Cheque_No = "";
  public Cheque_Date = this.Today_Date;
  public Card_Charge = "";
  public Remarks = "";



  public Bill_No = "";
  public Bill_Date = "";
  public Due_Date = "";
  public Place_of_Supply = "";
  public Sales_Person = "";


  public Pay_Mode_ID = "";
  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }

  public W_height = 0;
  public W_width = 0;

  lenth: number = 0;
  public order_Rows = [];
  public Orders_sort_by = "order_Date"

  public Invoice_Row_total = 0;
  public Invoice_Row_length = 0;
  public Invoice_Row = [];


  public Exp_Rows = [];
  public Exp_Total = 0;
  get_expense() {
    this.isload = true;
    this.Exp_Rows = [];
    this.get("Api/Transaction/get_Adjustments?From=" + this.S_From + "&To=" + this.S_To + "&Type=Expense&Category=" + this.S_Exp_Category + "&Pay_Mode=" + this.S_Pay_Mode).subscribe((res: any) => {
      this.Exp_Rows = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Exp_Total = 0;
        this.Exp_Total = (this.Exp_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      } catch
      { }
    });

  }

  public Income_Rows = [];
  public Income_Total = 0;
  get_Income() {
    this.isload = true;
    this.Income_Rows = [];
    this.get("Api/Transaction/get_Adjustments?From=" + this.S_From + "&To=" + this.S_To + "&Type=Income&Category=" + this.S_Exp_Category + "&Pay_Mode=" + this.S_Pay_Mode).subscribe((res: any) => {
      this.Income_Rows = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Income_Total = 0;
        this.Income_Total = (this.Income_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      } catch
      { }
    });

  }

  public Contra_Rows = [];
  public Contra_Total = 0;
  get_Contra() {
    this.isload = true;
    this.Contra_Rows = [];
    this.get("Api/Transaction/get_Contra?From=" + this.S_From + "&To=" + this.S_To +  "&Pay_Mode=" + this.S_Pay_Mode).subscribe((res: any) => {
      this.Contra_Rows = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Contra_Total = 0;
        this.Contra_Total = (this.Contra_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      } catch
      { }
    });

  }

  public Collection_Row = [];
  public Customerwise_Coll_Row = [];
  public Userwise_Coll_Row = [];
  public Areawise_Coll_Row = [];
  public Paymodewise_Coll_Row = [];

  public Collection_Total_Amt = 0;



  get_Collections_Details() {

    this.isload = true;
    this.get("Api/Invoice/get_Amount_Collection?From=" + this.S_From + "&To=" + this.S_To + "&Customer=" + this.S_customer + "&Area=" + this.S_Area + "&Pay_Mode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&order_by=Receipt_Date").subscribe((res: any) => {
      this.Collection_Row = JSON.parse(res).record;
      this.isload = false;



      var Ledger_Name = [...new Set(this.Collection_Row.map(item => item.Ledger_Name))];
      this.Customerwise_Coll_Row = [];
      for (let data of Ledger_Name) {
        var Amount_ = (this.Collection_Row.filter(e => e.Ledger_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Customerwise_Coll_Row.push({ Ledger_Name: data, Amount: Amount_ })
      }
      var Created_By = [...new Set(this.Collection_Row.map(item => item.Created_By))];
      this.Userwise_Coll_Row = [];
      for (let data of Created_By) {
        var Amount_ = (this.Collection_Row.filter(e => e.Created_By == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Userwise_Coll_Row.push({ Created_By: data, Amount: Amount_ })
      }
      var Area = [...new Set(this.Collection_Row.map(item => item.Area))];
      this.Areawise_Coll_Row = [];
      for (let data of Area) {
        var Amount_ = (this.Collection_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Areawise_Coll_Row.push({ Area: data, Amount: Amount_ })
      }
      var Pay_Mode_ = [...new Set(this.Collection_Row.map(item => item.Pay_Mode_))];
      this.Paymodewise_Coll_Row = [];
      for (let data of Pay_Mode_) {
        var Amount_ = (this.Collection_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Paymodewise_Coll_Row.push({ Pay_Mode_: data, Amount: Amount_ })
      }




      try {

        this.Collection_Total_Amt = (this.Collection_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }


    });
  }




  S_State = "All";
  S_District = "All";
  S_Thoguthi = "All";
  S_Desgnation = "All";

  Member_Data_Row = [];
  Thoguthiwise_Member_Row = [];
  Statewise_Member_Row = [];
  Districtwise_Member_Row = [];

  Total_Members = [];
  get_Total_Members() {
    this.isload = true;
    this.getc("Api/Master/get_Total_Members").subscribe((res: any) => {
      this.isload = false;
      this.Total_Members = JSON.parse(res).record;
    });
  }

  get_Member_Data() {

    this.isload = true;
    this.get("Api/Master/get_Member_Data?From=" + this.S_From + "&To=" + this.S_To + "&State=" + this.S_State + "&District=" + this.S_District + "&Thoguthi=" + this.S_Thoguthi + "&Desgnation=" + this.S_Desgnation + "&User=" + this.Search_User + "&Order=Name").subscribe((res: any) => {
      this.Member_Data_Row = JSON.parse(res).record;
      this.isload = false;

      var Thoguthi = [...new Set(this.Member_Data_Row.map(item => item.Thoguthi))];
      this.Thoguthiwise_Member_Row = [];
      for (let data of Thoguthi) {
        var count = [...new Set((this.Member_Data_Row.filter(e => e.Thoguthi == data)).map(item => item.ID))].length;
        this.Thoguthiwise_Member_Row.push({ 'Name': data, 'Count': count })
      }


      var State = [...new Set(this.Member_Data_Row.map(item => item.State))];
      this.Statewise_Member_Row = [];
      for (let data of State) {
        var count = [...new Set((this.Member_Data_Row.filter(e => e.State == data)).map(item => item.ID))].length;
        this.Statewise_Member_Row.push({ 'Name': data, 'Count': count })
      }


      var District = [...new Set(this.Member_Data_Row.map(item => item.District))];
      this.Districtwise_Member_Row = [];
      for (let data of District) {
        var count = [...new Set((this.Member_Data_Row.filter(e => e.District == data)).map(item => item.ID))].length;
        this.Districtwise_Member_Row.push({ 'Name': data, 'Count': count })
      }

    });
  }



  public Approval_Waiting_Count =0;
  public Approval_Waiting_Row = [];
  public Approval_Waiting_Row_ = [];

  get_Approval_Waiting_Details() {
    this.isload = true;
    this.Approval_Waiting_Row = [];
    this.Approval_Waiting_Row_ = [];

    this.Approval_Waiting_Count=0;
    this.get("Api/Invoice/get_Approval_Waiting_Detail?From=" + this.AS_From + "&To=" + this.AS_To+"&Status="+this.Result_Status+ "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
    this.Approval_Waiting_Row=JSON.parse(res).record;
    this.Approval_Waiting_Row_=JSON.parse(res).record;

    this.isload = false;

      try {

        this.Approval_Waiting_Count = this.Approval_Waiting_Row.length;
      } catch { }

     

    });

  }
  get_Genetic_Test_Report(data) 
  {
   this.Edit_Row=data;
   window.open(this.Server_URL+"report/Test_Report_Format1?Type=Open&Contact="+this.Edit_Row.Contact_No+"&Bill_No="+this.Edit_Row.Bill_No+"&Format="+this.Edit_Row.Report_Format+"&File_Name="+this.Edit_Row.Bill_No+"&Msg="+this.Report_Msg+"&Company="+this.Company, "_blank");
  
  
  }
  get_Genetic_Test_Report_Download(data) 
  {
   this.Edit_Row=data;
   window.open(this.Server_URL+"report/Test_Report_Format1_Download?Type=Open&Contact="+this.Edit_Row.Contact_No+"&Bill_No="+this.Edit_Row.Bill_No+"&Format="+this.Edit_Row.Report_Format+"&File_Name="+this.Edit_Row.Bill_No+"&Msg="+this.Report_Msg+"&Company="+this.Company, "_blank");
  
  
  }
  public Exp_Category = [];
  public S_Exp_Category = "0";
  public Expenses_Row = [];
  public Exp_Category_Row = [];

  public Exp_Userwise_Row = [];
  public Exp_Paymodewise_Row = [];

  public Exp_Daywise_Row = [];
  public Exp_itemwise_Row = [];

  public Total_Expense_Amount = 0;


  get_Expense_Details() {

    this.isload = true;
    this.get("Api/Invoice/get_expenses?From=" + this.S_From + "&To=" + this.S_To + "&Type=Expense&Category=" + this.S_Exp_Category + "&Paymode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&order_by=Date").subscribe((res: any) => {
      this.Expenses_Row = JSON.parse(res).record;
      this.isload = false;



      try {

        this.Total_Expense_Amount = (this.Expenses_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }


      var Item = [...new Set(this.Expenses_Row.map(item => item.Category_))];
      this.Exp_Category_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Category_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Category_Row.push({ Category: data, Qty: 0, Amount: Amount_ })
      }


      var Created_by = [...new Set(this.Expenses_Row.map(item => item.Created_by))];
      this.Exp_Userwise_Row = [];
      for (let data of Created_by) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Created_by == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Userwise_Row.push({ User: data, Qty: 0, Amount: Amount_ })
      }


      var Pay_Mode_ = [...new Set(this.Expenses_Row.map(item => item.Pay_Mode_))];
      this.Exp_Paymodewise_Row = [];
      for (let data of Pay_Mode_) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Pay_Mode_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Paymodewise_Row.push({ Pay_Mode_: data, Qty: 0, Amount: Amount_ })
      }


      var Date = [...new Set(this.Expenses_Row.map(item => item.Date))];
      this.Exp_Daywise_Row = [];
      for (let data of Date) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Date == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Daywise_Row.push({ Date: data, Qty: 0, Amount: Amount_ })
      }




      var Narration = [...new Set(this.Expenses_Row.map(item => item.Narration))];
      this.Exp_itemwise_Row = [];
      for (let data of Narration) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Narration == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_itemwise_Row.push({ Narration: data, Qty: 0, Amount: Amount_ })
      }



    });

  }

  public Sales_Item_Rowdata = "";

  public Sales_Date = "";
  public Sales_Customer: any = {};

  public Sales_Item_Row = [];
  public Saleswise_Details() {
    this.isload = true;
    this.Sales_Item_Row = [];
    this.get("Api/Invoice/get_Saleswise_Item?ID=" + this.Sales_Item_Rowdata + "&From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Sales_Item_Row = JSON.parse(res).record;
      this.Sales_Customer = this.Sales_Item_Row[0];
      this.isload = false;

    });

  }
  
  ago(value: any, args?: any): any {

    
    if (value) {
        const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
        if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
            return 'Just now';
        const intervals = {
            'year': 31536000,
            'month': 2592000,
            'week': 604800,
            'day': 86400,
            'hour': 3600,
            'minute': 60,
            'second': 1
        };
        let counter;
        for (const i in intervals) {
            counter = Math.floor(seconds / intervals[i]);
            if (counter > 0)
                if (counter === 1) {
                    return counter + ' ' + i + ' ago'; // singular (1 day ago)
                } else {
                    return counter + ' ' + i + 's ago'; // plural (2 days ago)
                }
        }
    }
    return value;
}


  public Delivery_Details_Row = [];
  get_Delivery_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Delivery?From=" + this.S_From + "&To=" + this.S_To +"&Area=" + this.S_Area+"&Category=" + this.I_Category +"&Category2=" + this.I_Category2 + "&order_by=y.Bill_Date desc").subscribe((res: any) => {
      this.Delivery_Details_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Delivery_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Delivery_Details_Row.length;

      } catch
      {

      }
    });
  }
  public Brandwise_Sales_Row = [];
  get_Brandwise_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Delivery?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Brandwise_Sales_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Delivery_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Delivery_Details_Row.length;

      } catch
      {

      }
    });
  }

  public Deleted_Order_GF = ['Table_', 'Item_Name', 'Created_by', 'Created_Date'];
  public Variable_Settings_GF = ['ID', 'S_Variable', 'S_Value', 'S_Default'];


  public Delete_Order_Details_Row = [];
  public Delete_Order_Details() {
    this.isload = true;
    this.Delete_Order_Details_Row = [];
    this.get("Api/Invoice/get_Delete_Order_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Created_Date").subscribe((res: any) => {
      this.isload = false;
      this.Delete_Order_Details_Row = JSON.parse(res).record;
    });

  }
  public Cusomer_Details_Rowdata = "";

  public Cusomer_Details_Row = [];
  public Customer_Details() {
    this.isload = true;
    this.Cusomer_Details_Row = [];
    this.get("Api/Invoice/get_Customer_Sales?Uni_Code=" + this.Cusomer_Details_Rowdata ).subscribe((res: any) => {
      this.Cusomer_Details_Row = JSON.parse(res).record;
      this.isload = false;

    });

  }
 public Track_Sum_Row =[];
 public Itemwise_Track_Row =[];
 public Billwise_Track_Sum_Row =[];
 public Product_Track_Row = [];
 public Supplierwise_Track_Row =[];
 public Categorywise_Track_Row =[];

  get_Track_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Product_Track?From=" + this.S_From +  "&To=" + this.S_To +"&S_Stock=" + this.S_Stock+"&I_Category=" + this.I_Category+  "&order_by=y.Bill_Date,y.Bill_no,x.ID desc").subscribe((res: any) => {
      this.Product_Track_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Product_Track_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Product_Track_Row.length;

      } catch
      {

      }



      var Item = [...new Set(this.Product_Track_Row.map(item => item.Item_Name))];
      this.Itemwise_Track_Row = [];
      for (let data of Item) {
        var s_sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var P_Qty = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.P_Qty), 0)).toFixed(2);
        var S_Qty = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Qty), 0)).toFixed(2);
        var Stock_Qty = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Stock_Qty), 0)).toFixed(2);

        var rs = this.Product_Track_Row.filter(e => e.Item_Name == data);

        this.Itemwise_Track_Row.push({ 'Item_Name': data,'P_Qty': P_Qty,'S_Qty': S_Qty,'Stock_Qty': Stock_Qty, 'S_Value': s_sum,'P_Value': sum, 'Stock_Value': Stock_sum,'Profit': Profit_sum })
      }
      var Item = [...new Set(this.Product_Track_Row.map(item => item.Category))];
      this.Categorywise_Track_Row = [];
      for (let data of Item) {
        var s_sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var P_Qty = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.P_Qty), 0)).toFixed(2);
        var S_Qty = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.S_Qty), 0)).toFixed(2);
        var Stock_Qty = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Stock_Qty), 0)).toFixed(2);

        var rs = this.Product_Track_Row.filter(e => e.Category == data);

        this.Categorywise_Track_Row.push({ 'Category': data,'P_Qty': rs[0].P_Qty,'S_Qty': rs[0].S_Qty,'Stock_Qty': rs[0].Stock_Qty, 'S_Value': s_sum,'P_Value': sum, 'Stock_Value': Stock_sum,'Profit': Profit_sum })
      }



     
      this.Supplierwise_Track_Row = [];
      var Supplier = [...new Set(this.Product_Track_Row.map(item => item.Supplier))];
      for (let data of Supplier) {
        var s_sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var rs = this.Product_Track_Row.filter(e => e.Supplier == data);
        this.Supplierwise_Track_Row.push({ 'Supplier': data,    'P_Value': sum,'S_Value': s_sum,'Stock_Value':Stock_sum,'Profit': Profit_sum});

      }

      
      this.Billwise_Track_Sum_Row = [];
      var Bill_No = [...new Set(this.Product_Track_Row.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var s_sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);

        var rs = this.Product_Track_Row.filter(e => e.Bill_No == data);

        this.Billwise_Track_Sum_Row.push({ 'Bill_No': data, 'Bill_Date': rs[0].Date,  'Supplier': rs[0].Supplier,  'P_Value': sum,'S_Value': s_sum,'Stock_Value':Stock_sum,'Profit': Profit_sum});


      }
       




    });

  }
  
 
  public Minimum_Stock_Row =[];
  public Minimum_Stock_count=0;
   get_Minimum_Stock_Details() {
     this.isload = true;
     this.get("Api/Invoice/get_Minimum_Stock?I_Ledger_Name=" + this.I_Ledger_Name+  "&order_by=Ledger_Name").subscribe((res: any) => {
       this.Minimum_Stock_Row = JSON.parse(res).record;
       this.isload = false;
       try {
        this.Minimum_Stock_count = this.Minimum_Stock_Row.length;
      } catch { }
 
 
     });
 
   }
   
  
 
   public SMS_Send_Row =[];
    get_SMS_Send_Details() {
      this.isload = true;
      this.get("Api/Invoice/get_SMS_Report?From=" + this.S_From +"&To="+this.S_To).subscribe((res: any) => {
      this.SMS_Send_Row = JSON.parse(res).record;
      this.isload = false;
      
  
      });
  
    }



    public Bill_Print_Setting_Rows1 = [];
    get_Bill_Print_Settingc() {
  
      this.loading = true;
      this.isload = true;
      this.Bill_Print_Setting_Rows1 = [];
      this.getc("Api/Setting/get_Print_Bill_Settingc").subscribe((res: any) => {
      this.Bill_Print_Setting_Rows1 = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
        // console.log(this.Item_Category);
      });
    }


    get_Print_type(data) {


      try {
        return this.Bill_Print_Setting_Rows1.filter(e => e.Print_Type.toLowerCase() == data.toLowerCase());
      } catch {
  
        return [];
      }
    }

    public Ser_Type="";
    public Ser_Name="";
    public Service_Name_rows = [];
    get_Service_Name() {
      this.Service_Name_rows=[];
      
      this.get("Api/Master/get_Service_Names?Category=" + this.Ser_Type).subscribe((res: any) => {
        this.Service_Name_rows = JSON.parse(res).record;
        this.Ser_Name='0';
      });
    }
    public Billwise_Home_Visit_rows = [];

    public Home_Visit_rows = [];
    get_Home_Visit() {
      this.Home_Visit_rows=[];
      this.get("Api/Invoice/get_Home_Visit?From=" + this.S_From +"&Refer_Type=" + this.Refer_Type_ + "&To=" + this.S_To + "&Referer_Name=" + this.Referer_Name +"&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
        this.Home_Visit_rows = JSON.parse(res).record;

        this.Billwise_Home_Visit_rows = [];
        var Bill_No = [...new Set(this.Home_Visit_rows.map(item => item.Bill_No))];
        for (let data of Bill_No) {
          var sum = (this.Home_Visit_rows.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
  
          var rs = this.Home_Visit_rows.filter(e => e.Bill_No == data);
  
          this.Billwise_Home_Visit_rows.push({ 'Bill_No': data, 'Bill_Date_': rs[0].Bill_Date, 'Bill_Date': rs[0].Bill_Date_,'Home_Visit_Person': rs[0].Home_Visit_Person, 'Customer_Name': rs[0].Customer_Name, 'Contact_No': rs[0].Contact_No, 'Amount': sum });
  
  
        }
      });
    }
  public Refererwise_Sum_Row=[];  
  public Refer_Doctorwise_Sum_Row=[];
  public Itemwise_Sales_Row = [];
  get_Sales_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.Itemwise_Sales_Row = [];
    this.Billwise_Sales_Sum_Row = [];
    this.Refererwise_Sum_Row = [];
    this.Item_wise_sales_Row = [];
    this.Refer_Doctorwise_Sum_Row=[];
    this.get("Api/Invoice/get_Itemwise?From=" + this.S_From +"&Refer_Type=" + this.Refer_Type_ + "&To=" + this.S_To +"&Home_Visit="+this.Home_Visit+"&Referer_Name=" + this.Referer_Name +"&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Itemwise_Sales_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Sales_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Sales_Row.length;
      } catch
      {

      }


      this.Item_wise_sales_Row = [];

      var Item = [...new Set(this.Itemwise_Sales_Row.map(item => item.Item_Name))];
      this.Item_wise_sales_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Sales_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Sales_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);

        this.Item_wise_sales_Row.push({ Item_Name: data, Qty: Qty_, Amount: Amount_ })
      }


     
      this.Refererwise_Sum_Row = [];
    
      var Refer_Type_ = [...new Set(this.Itemwise_Sales_Row.map(item => item.Refer_Type_))];
      for (let data of Refer_Type_) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Refer_Type_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Refererwise_Sum_Row.push({ 'Refer_Type_': data, 'Amount': sum });
      }
      this.Refer_Doctorwise_Sum_Row = [];
    
      var Name = [...new Set(this.Itemwise_Sales_Row.map(item => item.Name))];
      for (let data of Name) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var rs = this.Itemwise_Sales_Row.filter(e => e.Name == data);

        this.Refer_Doctorwise_Sum_Row.push({ 'Name': data,'Refer_Type_': rs[0].Refer_Type_, 'Amount': sum });
      }
      


      this.Paymodewise_Sales_Sum_Row = [];
      var Pay_Mode_ = [...new Set(this.Itemwise_Sales_Row.map(item => item.Pay_Mode_))];
      for (let data of Pay_Mode_) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Pay_Mode_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var rs = this.Itemwise_Sales_Row.filter(e => e.Pay_Mode_ == data);
        //var Balance=sum-rs[0].Rec_Amt;

        this.Paymodewise_Sales_Sum_Row.push({ 'Pay_Mode': data, 'Amount': sum ,});
      }



      this.Date_wise_sales_Row = [];
      var Bill_Date_ = [...new Set(this.Itemwise_Sales_Row.map(item => item.Bill_Date_))];
      for (let data of Bill_Date_) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Bill_Date_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_Sales_Row.filter(e => e.Bill_Date_ == data)).map(item => item.Bill_No))].length;

        this.Date_wise_sales_Row.push({ 'Date': data, 'count': count, 'Amount': sum });
      }
      this.Customerwise_sales_Row = [];
      var Customer_Name = [...new Set(this.Itemwise_Sales_Row.map(item => item.Customer_Name))];
      for (let data of Customer_Name) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_Sales_Row.filter(e => e.Customer_Name == data)).map(item => item.Bill_No))].length;

        this.Customerwise_sales_Row.push({ 'Customer_Name': data, 'count': count, 'Amount': sum });
      }

      this.Billwise_Sales_Sum_Row = [];
      var Bill_No = [...new Set(this.Itemwise_Sales_Row.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Itemwise_Sales_Row.filter(e => e.Bill_No == data);

        this.Billwise_Sales_Sum_Row.push({ 'Bill_No': data, 'Bill_Date_': rs[0].Bill_Date, 'Disc_Amt': rs[0].Disc_Amt,'Rec_Amt': rs[0].Rec_Amt,'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Amount': sum });


      }




    });

  }
  public Share_Total=0;
  public Share_Per=0;
  public Share_Amt=0;
  public Refererwise_Amt=0;  
  public Servicewise_Share_Row=[];  
  public Billwise_Share_Row=[];  

  public Refererwise_Share_Row=[];  
  public Refer_Typewise_Share_Row=[];
  public Share_Row = [];
  get_Share_Ratio_Details() {
    this.isload = true;
    this.Share_Total = 0;
    this.Share_Per = 0;
    this.Share_Amt=0;
    this.Refererwise_Share_Row = [];
    this.Share_Row = [];
    this.Refer_Typewise_Share_Row=[];
    this.get("Api/Invoice/get_Share_Ratio?From=" + this.S_From +"&Refer_Type=" + this.Refer_Type_ + "&To=" + this.S_To + "&Referer_Name=" + this.Referer_Name +"&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Share_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Share_Total = (this.Share_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Share_Per = (this.Share_Row.reduce((sum, current) => sum + parseFloat(current.Disc_Amt), 0)).toFixed(2);
        this.Invoice_Row_length = this.Share_Row.length;
      } catch
      {

      }

      var Item = [...new Set(this.Share_Row.map(item => item.Bill_No))];
      this.Billwise_Share_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Share_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Share_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Out_sum = (this.Share_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Outstation_Amount), 0)).toFixed(2);
        var Share = (this.Share_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Share), 0)).toFixed(2);
        var rs = this.Share_Row.filter(e => e.Bill_No == data);

        this.Billwise_Share_Row.push({ Bill_No: data, Qty: Qty_, Amount: Amount_,'Outstation_Amount': Out_sum,'Disc_Per': rs[0].Disc_Per,'Name': rs[0].Name,'Bill_Date_': rs[0].Bill_Date_,'Share': Share })
      }

 

      this.Servicewise_Share_Row = [];

      var Item = [...new Set(this.Share_Row.map(item => item.Item_Name))];
      this.Servicewise_Share_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Share_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Share_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Out_sum = (this.Share_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Outstation_Amount), 0)).toFixed(2);
        var Share = (this.Share_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Share), 0)).toFixed(2);
        var rs = this.Share_Row.filter(e => e.Item_Name == data);

        this.Servicewise_Share_Row.push({ Item_Name: data, Qty: Qty_, Amount: Amount_,'Outstation_Amount': Out_sum,'Disc_Per': rs[0].Disc_Per,'Share': Share })
      }

      
  


     
      this.Refer_Typewise_Share_Row = [];
      var Refer_Type_ = [...new Set(this.Share_Row.map(item => item.Refer_Type_))];
      for (let data of Refer_Type_) {
        
        var sum = (this.Share_Row.filter(e => e.Refer_Type_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Out_sum = (this.Share_Row.filter(e => e.Refer_Type_ == data).reduce((sum, current) => sum + parseFloat(current.Outstation_Amount), 0)).toFixed(2);

        var Share = (this.Share_Row.filter(e => e.Refer_Type_ == data).reduce((sum, current) => sum + parseFloat(current.Share), 0)).toFixed(2);

        var rs = this.Share_Row.filter(e => e.Refer_Type_ == data);
        this.Refer_Typewise_Share_Row.push({ 'Refer_Type_': data, 'Amount': sum,'Outstation_Amount': Out_sum,'Disc_Per': rs[0].Disc_Per,'Share': Share });
      }
      this.Refererwise_Share_Row = [];
    
      var Name = [...new Set(this.Share_Row.map(item => item.Name))];
      for (let data of Name) {
        var sum = (this.Share_Row.filter(e => e.Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Out_sum = (this.Share_Row.filter(e => e.Name == data).reduce((sum, current) => sum + parseFloat(current.Outstation_Amount), 0)).toFixed(2);

        var sum2 = (this.Share_Row.filter(e => e.Name == data).reduce((sum, current) => sum + parseFloat(current.Share), 0)).toFixed(2);


        var rs = this.Share_Row.filter(e => e.Name == data);

        this.Refererwise_Share_Row.push({ 'Name': data,'Refer_Type_': rs[0].Refer_Type_,'Outstation_Amount': Out_sum, 'Amount': sum,'Disc_Per': rs[0].Disc_Per,'Share': sum2});
      }
      


    });

  }
  public Outstation_Share_Row=[];  
  public Labwise_Share_Row=[];
  get_Outstation_Share_Details() {
    this.isload = true;
 
    this.Outstation_Share_Row = [];
    this.get("Api/Invoice/get_Outstation_Share?From=" + this.S_From + "&Refer_Type=" + this.Refer_Type_ +"&Status="+this.Out_Status+ "&To=" + this.S_To + "&Referer_Name=" + this.Referer_Name +"&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Outstation_Share_Row = JSON.parse(res).record;
      this.isload = false;

     

      this.Labwise_Share_Row = [];
      var out_Source_Lab_ID_ = [...new Set(this.Outstation_Share_Row.map(item => item.out_Source_Lab_ID_))];
      for (let data of out_Source_Lab_ID_) {
        
        var sum = (this.Outstation_Share_Row.filter(e => e.out_Source_Lab_ID_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var sum2 = (this.Outstation_Share_Row.filter(e => e.out_Source_Lab_ID_ == data).reduce((sum, current) => sum + parseFloat(current.Share), 0)).toFixed(2);
        var sum_out = (this.Outstation_Share_Row.filter(e => e.out_Source_Lab_ID_ == data).reduce((sum, current) => sum + parseFloat(current.Outstation_Amount), 0)).toFixed(2);


        var rs = this.Outstation_Share_Row.filter(e => e.out_Source_Lab_ID_ == data);
        this.Labwise_Share_Row.push({ 'out_Source_Lab_ID_': data, 'Amount': sum,'Disc_Per': rs[0].Disc_Per,'Outstation_Amount': sum_out,'Share':sum2,'Bill_No': rs[0].Bill_No, 'Send_Date': rs[0].Send_Date,'Out_Status': rs[0].Out_Status });
      }
   
   

    });

  }

  public Reprint_Row=[];
  get_Reprint_Report()
  {
    this.get("Api/Reports/get_Reprint_Report?From=" + this.S_From + "&Refer_Type=" + this.Refer_Type_ + "&To=" + this.S_To + "&Referer_Name=" + this.Referer_Name +"&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Reprint_Row = JSON.parse(res).record;
      console.log( this.Reprint_Row )
    });
  }
  direct_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url , { headers: reqHeader })
  }

  public Outstation_Report_Row=[];
  get_Outstation_Report()
  {
    this.get("Api/Reports/get_Outstation_Report?From=" + this.S_From + "&Refer_Type=" + this.Refer_Type_ +"&Status="+this.Out_Status+ "&To=" + this.S_To + "&Referer_Name=" + this.Referer_Name +"&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
    this.Outstation_Report_Row = JSON.parse(res).record;
    });
  }
  public Vendorwise_Row=[];
  public Monthwise_Row=[];
  public Calibration_Cost_Row = [];
  get_Calibration_Cost_Report() {
    this.isload = true;
    this.get("Api/Master/get_Calibration_Cost?From=" + this.S_From + "&To=" + this.S_To+"&Calibrate_At="+this.M_Calibrate_At).subscribe((res: any) => {
      this.Calibration_Cost_Row = JSON.parse(res).record;
    
      this.isload = false;

      this.Vendorwise_Row = [];
      var Vendor_Name = [...new Set(this.Calibration_Cost_Row.map(item => item.Vendor_Name))];
      for (let data of Vendor_Name) {
        var rs = this.Calibration_Cost_Row.filter(e => e.Vendor_Name == data);
        var Cost = (this.Calibration_Cost_Row.filter(e => e.Vendor_Name == data).reduce((sum, current) => sum + parseFloat(current.Calibration_Cost), 0)).toFixed(2);
        var count = [...new Set((this.Calibration_Cost_Row.filter(e => e.Vendor_Name == data)).map(item => item.ID))].length;

        this.Vendorwise_Row.push({ 'Cost': Cost,'Vendor_Name': data,'count': count});

      }
      this.Monthwise_Row = [];
      var Month = [...new Set(this.Calibration_Cost_Row.map(item => item.Month))];
      for (let data of Month) {
        var rs = this.Calibration_Cost_Row.filter(e => e.Month == data);
        var Cost = (this.Calibration_Cost_Row.filter(e => e.Month == data).reduce((sum, current) => sum + parseFloat(current.Calibration_Cost), 0)).toFixed(2);
        var count = [...new Set((this.Calibration_Cost_Row.filter(e => e.Month == data)).map(item => item.ID))].length;

        this.Monthwise_Row.push({ 'Cost': Cost,'Month': data,'count': count});

      }
    });

  }
 
  public Out_Of_Calibration_Row = [];
  get_Out_Of_Calibration() {
    this.isload = true;
    this.get("Api/Master/get_Out_Of_Calibration?From=" + this.S_From + "&To=" + this.S_To+"&Out_of_Calibration="+this.M_Out_of_Calibration).subscribe((res: any) => {
    this.Out_Of_Calibration_Row = JSON.parse(res).record;
    this.isload = false;

    });

  }
  public Test_Time_Row = [];

  public Test_Summary_Row = [];
  get_Test_Summary() {
    this.isload = true;
    this.get("Api/Master/get_Test_Summary?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
    this.Test_Summary_Row = JSON.parse(res).record;
    this.isload = false;


    this.Test_Time_Row = [];
    var Request_No = [...new Set(this.Test_Summary_Row.map(item => item.Request_No))];
    for (let data of Request_No) {
      var rs = this.Test_Summary_Row.filter(e => e.Request_No == data);
      var count = [...new Set((this.Test_Summary_Row.filter(e => e.Request_No == data)).map(item => item.Request_No))].length;
      this.Test_Time_Row.push({ 'Request_No': data,'count':count ,'Test_Status': rs[0].Test_Status,'Purpose_of_Test': rs[0].Purpose_of_Test,'Program_Name': rs[0].Program_Name,'Test_Start_Date': rs[0].Test_Start_Date,'Test_End_Date': rs[0].Test_End_Date});
    }
    });

  }


  
  TAD_Sheet_Pending_C=0;
  public TAD_Sheet_Pending = [];
  get_TAD_Sheet_Pending() {
    this.TAD_Sheet_Pending_C=0;
    this.isload = true;
    this.getc("Api/Transaction/get_TAD_Pending").subscribe((res: any) => {
    this.TAD_Sheet_Pending = JSON.parse(res).record;
    this.isload = false;
    try {

      this.TAD_Sheet_Pending_C = this.TAD_Sheet_Pending.length;

    } catch
    {

    }

    });

  }

  public Plan_Vs_Actual_Row = [];
  get_Plan_Vs_Actual() {
    this.isload = true;
    this.get("Api/Master/Plan_Vs_Actual?Month=" + this.S_From ).subscribe((res: any) => {
    this.Plan_Vs_Actual_Row = JSON.parse(res).record;
    this.isload = false;

    });

  }
  public Deportmentwise_Row=[];
  public Personwise_Row=[];
  public Daywise_Row=[];
  public Userwise_Row=[];

  public filter_Name="";

  public Purposewise_Row=[];
  public Visitorwise_Row = [];
  get_Visitor_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Master/get_Visitorwise?From=" + this.S_From + "&To=" + this.S_To + "&Visitor="+this.S_Visitor_Type+"&Department=" + this.S_Department+"&User="+this.Created_by ).subscribe((res: any) => {
      this.Visitorwise_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Visitorwise_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Visitorwise_Row.length;

      } catch
      {

      }


     

      this.Daywise_Row = [];
      var Date = [...new Set(this.Visitorwise_Row.map(item => item.Date))];
      for (let data of Date) {
        var rs = this.Visitorwise_Row.filter(e => e.Date == data);

        var count = [...new Set((this.Visitorwise_Row.filter(e => e.Date == data)).map(item => item.ID))].length;
        this.Daywise_Row.push({ 'Date': data,'count':count });
      }

      this.Purposewise_Row = [];
      var Purpose = [...new Set(this.Visitorwise_Row.map(item => item.Purpose))];
      for (let data of Purpose) {
        var rs = this.Visitorwise_Row.filter(e => e.Purpose == data);
        var count = [...new Set((this.Visitorwise_Row.filter(e => e.Purpose == data)).map(item => item.Visitor_Name))].length;
        this.Purposewise_Row.push({ 'Purpose': data,'count':count });
      }
      this.Personwise_Row = [];
      var V_Person = [...new Set(this.Visitorwise_Row.map(item => item.V_Person))];
      for (let data of V_Person) {
        var rs = this.Visitorwise_Row.filter(e => e.V_Person == data);
        var count = [...new Set((this.Visitorwise_Row.filter(e => e.V_Person == data)).map(item => item.Visitor_Name))].length;

        this.Personwise_Row.push({ 'count': count,'V_Person': data, 'Visitor_Name': rs[0].Visitor_Name, 'Company_Name': rs[0].Company_Name });

      }

      


      this.Deportmentwise_Row = [];
      var V_Department = [...new Set(this.Visitorwise_Row.map(item => item.V_Department))];
      for (let data of V_Department) {
        var rs = this.Visitorwise_Row.filter(e => e.V_Department == data);
        var count = [...new Set((this.Visitorwise_Row.filter(e => e.V_Department == data)).map(item => item.Visitor_Name))].length;

        this.Deportmentwise_Row.push({ 'V_Department': data, 'count':count, 'Company_Name': rs[0].Company_Name, 'V_Person': rs[0].V_Person });

      }



      this.Userwise_Row = [];
      var Created_by = [...new Set(this.Visitorwise_Row.map(item => item.Created_by))];
      for (let data of Created_by) {
        var rs = this.Visitorwise_Row.filter(e => e.Created_by == data);
        var count = [...new Set((this.Visitorwise_Row.filter(e => e.Created_by == data)).map(item => item.Visitor_Name))].length;

        this.Userwise_Row.push({ 'Created_by': data,'Purpose':rs[0].Purpose ,'Vistor_Type':rs[0].Vistor_Type ,'Unit':rs[0].Unit,'V_Department':rs[0].V_Department,'Visitor_Name':rs[0].Visitor_Name,'Contact_No':rs[0].Contact_No,'Date': rs[0].Date, 'Out_Time': rs[0].Out_Time,'In_Time': rs[0].In_Time,'Company_Name': rs[0].Company_Name, 'V_Person': rs[0].V_Person });

      }


     


     



    });

  }
  public Productwise_Row=[];

  public Customerwise_Row=[];
  public Itemwise_Sales_Row1 = [];
  get_Deliverywise_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Customerwise?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Itemwise_Sales_Row1 = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Sales_Row1.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Sales_Row1.length;

      } catch
      {

      }



      var Item = [...new Set(this.Itemwise_Sales_Row1.map(item => item.Item_Name))];
      this.Productwise_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Sales_Row1.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Sales_Row1.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Free_ = (this.Itemwise_Sales_Row1.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Free), 0)).toFixed(2);

        var rs = this.Itemwise_Sales_Row1.filter(e => e.Item_Name == data);

        this.Productwise_Row.push({ Item_Name: data, Qty: Qty_, Amount: Amount_,MRP: rs[0].MRP,Free: Free_,Extra_Qty: rs[0].Extra_Qty  });
      }


      this.Customerwise_Row = [];
      var Bill_No = [...new Set(this.Itemwise_Sales_Row1.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Itemwise_Sales_Row1.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Itemwise_Sales_Row1.filter(e => e.Bill_No == data);

        this.Customerwise_Row.push({ 'Bill_No': data, 'Bill_Date_': rs[0].Bill_Date, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Area': rs[0].Area, 'Phone_No': rs[0].Contact_No, 'Amount': sum,'Payment': rs[0].Payment, });


      }


      

     



     
     


     




    });

  }
 
  public Billwise_Purchase_Sum_Row = [];
  public Paymodewise_Purchase_Sum_Row = [];
  public Date_wise_Purchase_Row = [];
  public Supplierwise_Purchase = [];
  public userwise_Purchase_Sum_Row = [];
  public Areawise_Purchase_Sum_Row = [];
  public Item_wise_purchase_Row = [];
  public Itemwise_purchase_Row = [];
  get_Purchase_Report() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Purchase_Report?From=" + this.S_From + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Purchase_Date desc").subscribe((res: any) => {
      this.Itemwise_purchase_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_purchase_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_purchase_Row.length;

      } catch
      {

      }



      var Item = [...new Set(this.Itemwise_purchase_Row.map(item => item.Item_Name))];
      this.Item_wise_purchase_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Profit_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var S_Rate_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Rate), 0)).toFixed(2);

        this.Item_wise_purchase_Row.push({ Item_Name: data, Qty: Qty_, Amount: Amount_,Profit:Profit_ ,S_Rate:S_Rate_})
      }


      this.Areawise_Purchase_Sum_Row = [];
      var Area = [...new Set(this.Itemwise_purchase_Row.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Areawise_Purchase_Sum_Row.push({ 'Area': data, 'Amount': sum });
      }


      this.userwise_Purchase_Sum_Row = [];
      var User = [...new Set(this.Itemwise_purchase_Row.map(item => item.User))];


      for (let data of User) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.User == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.User == data)).map(item => item.Bill_No))].length;

        this.userwise_Purchase_Sum_Row.push({ 'User': data, 'count': count, 'Amount': sum });
      }


      this.Paymodewise_Purchase_Sum_Row = [];
      var Pay_Mode_ = [...new Set(this.Itemwise_purchase_Row.map(item => item.Bill_Mode))];
      for (let data of Pay_Mode_) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Bill_Mode == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Paymodewise_Purchase_Sum_Row.push({ 'Pay_Mode': data, 'Amount': sum });
      }



      this.Date_wise_Purchase_Row = [];
      var Purchase_Date_ = [...new Set(this.Itemwise_purchase_Row.map(item => item.Purchase_Date_))];
      for (let data of Purchase_Date_) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Purchase_Date_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.Purchase_Date_ == data)).map(item => item.Purchase_No))].length;

        this.Date_wise_Purchase_Row.push({ 'Purchase_Date_': data, 'count': count, 'Amount': sum });
      }
      this.Supplierwise_Purchase = [];
      var Supplier_Name = [...new Set(this.Itemwise_purchase_Row.map(item => item.Supplier_Name))];
      for (let data of Supplier_Name) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Supplier_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.Supplier_Name == data)).map(item => item.Bill_No))].length;

        this.Supplierwise_Purchase.push({ 'Supplier_Name': data, 'count': count, 'Amount': sum });
      }


      this.Billwise_Purchase_Sum_Row = [];
      var Purchase_No = [...new Set(this.Itemwise_purchase_Row.map(item => item.Purchase_No))];
      for (let data of Purchase_No) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Purchase_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Itemwise_purchase_Row.filter(e => e.Purchase_No == data);

        this.Billwise_Purchase_Sum_Row.push({ 'Purchase_No': data, 'Purchase_Date_': rs[0].Purchase_Date, 'Purchase_Date': rs[0].Purchase_Date_, 'Supplier_Name': rs[0].Supplier_Name, 'Contact_No': rs[0].Contact_No, 'Amount': sum });


      }




    });

  }
  public Over_all_Row = [];
  public Customerwise_Profit_Row = [];
  public Billwise_Profit_Sum_Row = [];
  public Item_wise_profit_Row = [];
  public Itemwise_Profit_Row = [];
  get_Profit_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Itemwise_Profit?From=" + this.S_From + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Itemwise_Profit_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Profit_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Profit_Row.length;

      } catch
      {

      }



      var Item = [...new Set(this.Itemwise_Profit_Row.map(item => item.Item_Name))];
      this.Item_wise_profit_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Profit_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Profit_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var rs = this.Itemwise_Profit_Row.filter(e => e.Item_Name == data);

        this.Item_wise_profit_Row.push({ Item_Name: data, Profit: rs[0].Profit, Qty: Qty_, Amount: Amount_ })
      }




      this.Customerwise_Profit_Row = [];
      var Customer_Name = [...new Set(this.Itemwise_Profit_Row.map(item => item.Customer_Name))];
      for (let data of Customer_Name) {
        var sum = (this.Itemwise_Profit_Row.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_Profit_Row.filter(e => e.Customer_Name == data)).map(item => item.Bill_No))].length;
        var rs = this.Itemwise_Profit_Row.filter(e => e.Customer_Name == data);

        this.Customerwise_Profit_Row.push({ 'Customer_Name': data, 'count': count, 'Profit': rs[0].Profit, 'Amount': sum });
      }


      this.Billwise_Profit_Sum_Row = [];
      var Bill_No = [...new Set(this.Itemwise_Profit_Row.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Itemwise_Profit_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Itemwise_Profit_Row.filter(e => e.Bill_No == data);

        this.Billwise_Profit_Sum_Row.push({ 'Bill_No': data, 'Bill_Date_': rs[0].Bill_Date, 'Profit': rs[0].Profit, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Amount': sum });


      }




    });

  }get_Damage_Detail

  public DB_Damage_Total = 0;
  public Damage_Row = [];
  get_Damage_Details() {
    this.isload = true;
    this.DB_Damage_Total = 0;
    this.get("Api/Invoice/get_Damage_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&order_by=Damage_Date,Damage_No").subscribe((res: any) => {
      this.Damage_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Damage_Total = this.Damage_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }


S_Data="";
  public Sales_Row1 = [];
  get_Sale_Details1() {
    this.isload = true;
    this.DB_Sales_Total = 0;
    this.get("Api/Invoice/get_Sale_Detail1?Data=" + this.S_Data + "").subscribe((res: any) => {
      this.Sales_Row1 = JSON.parse(res).record;
      this.isload = false;
    });
  }
  public DB_Sales_Count = 0;

  public DB_Sales_Total = 0;
  public Sales_Row = [];
  get_Sale_Details() {
    this.isload = true;
    this.DB_Sales_Total = 0;
    this.get("Api/Invoice/get_Sale_Detail?From=" + this.S_From + "&To=" + this.S_To +"&Status="+this.Result_Status+"&Bill_Status="+this.Bill_Status+"&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Sales_Row = JSON.parse(res).record;
      this.isload = false;
 
     
      try {
        this.DB_Sales_Count = this.Sales_Row.length;

        this.DB_Sales_Total = this.Sales_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }

  public DB_Service_Count = 0;
  public Ref_Row_ = [];
  
  public DB_Service_Total = 0;
  public DB_Service_Row = [];
  get_DB_Service_Details() {
    this.isload = true;
    this.DB_Service_Total = 0;
    this.get("Api/Invoice/get_DB_Service?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.DB_Service_Row = JSON.parse(res).record;
      this.isload = false;
 
     
      try {
        this.DB_Service_Count = this.DB_Service_Row.length;

        this.DB_Service_Total = this.DB_Service_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
filter_Test(data)
{

  this.Test_Sales_Row=this.Test_Row.filter(e => (e.Bill_No.toLowerCase().includes(data.toLowerCase()) )  || (e.Customer_Name.toLowerCase().includes(data.toLowerCase()) ) || (e.Contact_No.toLowerCase().includes(data.toLowerCase()) )  );

}
public search_Patient;
public Search_Outstation;

  public Test_Row = [];
  public Filter_Test_Row = [];
  public DB_Test_Sales=0;
  public DB_Test_Count=0;
  public Test_Pending_Row = [];
  public Test_Sales_Row = [];
  public DB_Testwise_Count=0;

  get_Test_Details() {
    this.isload = true;
    this.Test_Sales_Row = [];
    this.Test_Pending_Row = [];
    this.DB_Test_Count=0;
    this.get("Api/Invoice/get_Test_Detail?From=" + this.AS_From + "&To=" + this.AS_To+"&Status="+this.Result_Status+ "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
    this.Test_Pending_Row=JSON.parse(res).record;

    this.isload = false;

      try {
        this.DB_Test_Count = this.Test_Pending_Row.length;
      } catch { }

     

    });

  }

  public Typewise_Report_Row= [];
  public Typewise_Row= [];

  public DB_Typewise_Count=0;

  get_Typewise_Report() {
    this.isload = true;
    this.Typewise_Report_Row = [];
  
    this.get("Api/Invoice/get_Typewise_Report?From=" + this.AS_From + "&To=" + this.AS_To+"&Status="+this.Result_Status+ "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
    this.Typewise_Report_Row=JSON.parse(res).record;
    this.isload = false;
    try {
      this.DB_Typewise_Count = this.Typewise_Report_Row.length;
    } catch { }
    });
  

  }
 
  public Testwise_Pending = [];
  get_Testwise_Pending() {
    this.isload = true;
   this.Testwise_Pending = [];
   this.DB_Testwise_Count=0;

    this.get("Api/Invoice/get_Testwise_Pending?From=" + this.AS_From + "&To=" + this.AS_To+"&Status="+this.Result_Status+ "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Testwise_Pending=JSON.parse(res).record;

      try {
        this.DB_Testwise_Count = this.Testwise_Pending.length;
      } catch { }
      this.isload = false;
   
     

    });

  }
  public Outstation_Received_Row = [];
  public Outstation_Received_Row_Count=0;
  get_Outstation_Received() {
    this.isload = true;
    this.Outstation_Received_Row_Count=0;

    this.get("Api/Invoice/get_Outstation_Details?From=" + this.AS_From + "&To=" + this.AS_To+"&Status="+this.Out_Status+ "&User=" + this.Search_User + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Outstation_Received_Row = JSON.parse(res).record;
      try {
        this.Outstation_Received_Row_Count = this.Outstation_Received_Row.length;
      } catch { }
      this.isload = false;
   
     

    });

  }
  get_DB_Test_Pending() {
    this.isload = true;
    this.DB_Test_Sales = 0;
    this.get("Api/Invoice/get_DB_Test_Pending?From=" + this.S_From + "&To=" + this.S_To+"&Status="+this.Result_Status+ "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Test_Sales_Row=JSON.parse(res).record;
    
      this.isload = false;
      try {
        this.DB_Test_Sales = this.Test_Sales_Row.length;
      } catch { }
     

    });

  }
  public Test_Request_Rowdata=[];
  public Test_Request_Row = [];
  get_Test_Request_Details() {
    this.DB_Sales_Total = 0;
    this.get("Api/Invoice/get_Test_Request_Detail?From=" + this.S_From + "&To=" + this.S_To ).subscribe((res: any) => {
      this.Test_Request_Row = JSON.parse(res).record;
      this.isload = false;
 
     
    
    });

  }
  public Referwise_Lenth=0;
  public Referwise_Total=0;

  public Referwise_Row=[];
  get_Refererwise_Details() {
    this.get("Api/Transaction/get_Refererwise_Details?From=" + this.S_From + "&To=" + this.S_To ).subscribe((res: any) => {
      this.Referwise_Row = JSON.parse(res).record;
      this.isload = false;
 
     
      try {
        this.Referwise_Lenth = this.Referwise_Row.length;

        this.Referwise_Total = (this.Referwise_Row.reduce((sum, current) => sum + parseFloat(current.Amount),0)).toFixed(2);
      } catch { }
    });

  }
  public get_Test_Request_Row=[];

  get_Test_data(data)
  {
    this.get("Api/Invoice/get_Test_Request?ID=" +data).subscribe((res: any) => {
      this.get_Test_Request_Row = JSON.parse(res).record;
      this.isload = false;
    });
  }
  public Sales_Gst_Row = [];
  get_Sale_Gst_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Sale_Gst_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Sales_Gst_Row = JSON.parse(res).record;
      this.isload = false;
 
     
      try {
        this.DB_Sales_Total = this.Sales_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Delivery_Total = 0;
  public Delivery_Row = [];
  get_Delivery_Challan_Details() {
    this.isload = true;
    this.DB_Delivery_Total = 0;
    this.get("Api/Invoice/get_Delivery_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&order_by=DC_Date,DC_No").subscribe((res: any) => {
      this.Delivery_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Delivery_Total = this.Delivery_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Dyeing_Total = 0;
  public Dyeing_Row = [];
  get_Dyeing_Details() {
    this.isload = true;
    this.DB_Tharikar_Total = 0;
    this.get("Api/Invoice/get_Dyeing_Detail?From=" + this.S_From + "&To=" + this.S_To +  "&Thari_Status=" + this.S_Thari_Status +"&User=" + this.Search_User + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Dyeing_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Dyeing_Total = this.Dyeing_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public Print_Total = 0;
  public Print_Row = [];
  get_Print_Details() {
    this.isload = true;
    this.Print_Total = 0;
    this.get("Api/Invoice/get_Print_Barcode?From=" + this.S_From + "&To=" + this.S_To +"&Bill_Mode="+this.S_Bill_Mode ).subscribe((res: any) => {
      this.Print_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.Print_Total = this.Print_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Tharikar_Total = 0;
  public Tharikar_Row = [];
  get_Tharikar_Details() {
    this.isload = true;
    this.DB_Tharikar_Total = 0;
    this.get("Api/Invoice/get_Tharikar_Detail?From=" + this.S_From + "&To=" + this.S_To +  "&Thari_Status=" + this.S_Thari_Status +"&User=" + this.Search_User + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Tharikar_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Tharikar_Total = this.Tharikar_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Sales_Return_Total = 0;
  public Sales_Return_Row = [];
  get_Sale_Return_Details() {
    this.isload = true;
    this.DB_Sales_Return_Total = 0;
    this.get("Api/Invoice/get_Sale_Return_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Return_No").subscribe((res: any) => {
      this.Sales_Return_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Sales_Return_Total = this.Sales_Return_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  
  public DB_Vadi_Total = 0;
  public Vadi_No = [];
  public Vadi_Details_Row = [];
  get_Vadi_Details() {
    this.isload = true;
    this.DB_Vadi_Total = 0;
    this.get("Api/Invoice/get_Vadi_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Vadi_Date,Vadi_No").subscribe((res: any) => {
      this.Vadi_Details_Row = JSON.parse(res).record;

      this.Vadi_No = this.Vadi_No;
      this.isload = false;
      try {
        this.DB_Vadi_Total = this.Vadi_Details_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });
  }
  
  public Production_Row = [];
  get_Production_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Production_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Pro_No").subscribe((res: any) => {
      this.Production_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }


  public Production_issue_Row = [];
  get_Production_issue_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Production_issue_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Pro_No").subscribe((res: any) => {
      this.Production_issue_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }

  public Proforma_Row = [];
  get_P_Invoice_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_P_Invoice_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Bill_No").subscribe((res: any) => {
      this.Proforma_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }
  public Quatation_Row = [];
  get_Quatation_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Quotation_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Bill_No").subscribe((res: any) => {
      this.Quatation_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }


  public Ledger_Group = [];
  get_Ledger_Group() {
    this.isload = true;
    this.getc("Api/Master/get_Ledger_Group").subscribe((res: any) => {
      this.Ledger_Group = JSON.parse(res).record;
      this.isload = false;
      console.log()
    });

  }

  public S_Group=0;
  public S_Category=0;
  public S_Brand=0;

  public Item_Group = [];
  get_Item_Group() {
    this.isload = true;
    this.getc("Api/Master/get_Item_Group").subscribe((res: any) => {
      this.Item_Group = JSON.parse(res).record;
      this.isload = false;
    });

  }

  public Category_Stock = [];
  public Current_Stock = [];

  get_Current_Stock_Details() {
    this.isload = true;
    this.get("Api/Reports/get_Currenct_Stock?From=" + this.S_From + "&To=" + this.S_To + "&Group="+this.S_Group+"&Category="+this.I_Category+"&Brand="+this.I_Brand).subscribe((res: any) => {
      this.Current_Stock = JSON.parse(res).record;
 
this.Current_Stock = JSON.parse(res).record;


      this.Category_Stock = [];
      var Category = [...new Set(this.Current_Stock.map(item => item.Category))];
      for (let data of Category) {
        var sum = (this.Current_Stock.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Stock), 0)).toFixed(2);
        var sum2 = (this.Current_Stock.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Value), 0)).toFixed(2);



        this.Category_Stock.push({ 'Category': data, 'Stock': sum,'Value': sum2, });
      }



      this.isload = false;
    });

  }

  public Stock_Report_Row = [];
  get_Stock_Report() {
    this.isload = true;
    this.get("Api/Reports/get_Stock_Report?From=" + this.S_From + "&To=" + this.S_To + "").subscribe((res: any) => {
      this.Stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }





  public S_Item_ID = "";
  public S_Item_Name = "";
  public Item_Stock = [];

  get_Item_Stock_Details() {
    this.isload = true;
    this.get("Api/Reports/get_Item_Stock?Item_ID=" + this.S_Item_ID + "&From=" + this.S_From + "&To=" + this.S_To + "").subscribe((res: any) => {
      this.Item_Stock = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Item_Stock[0].Balance = Number(this.Item_Stock[0].Inward_Qty) - Number(this.Item_Stock[0].Outward_Qty);
        for (var i = 1; i < this.Item_Stock.length; i++) {
          this.Item_Stock[i].Balance = (Number(this.Item_Stock[i - 1].Balance)) + (Number(this.Item_Stock[i].Inward_Qty) - Number(this.Item_Stock[i].Outward_Qty));
        }

      }
      catch { }
    });
  }

  public DB_Stock_Total = 0;
  public Stock_Row = [];
  get_Adjustment_Details() {
    this.isload = true;
    this.DB_Stock_Total = 0;
    this.get("Api/Invoice/get_Stock_Adjustment_Detail?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Adj_No").subscribe((res: any) => {
      this.Stock_Row = JSON.parse(res).record;
      this.isload = false;
      try {
        this.DB_Stock_Total = this.Stock_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Purchase_Total = 0;
  public Purchase_Row = [];
  get_Purchase_Details() {
    this.isload = true;
    this.DB_Purchase_Total = 0;
    this.get("Api/Invoice/get_Purchase_Detail?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Purchase_No").subscribe((res: any) => {
      this.Purchase_Row = JSON.parse(res).record;
      this.isload = false;
      try {
        this.DB_Purchase_Total = this.Purchase_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public Purchase_Order_Row = [];
  get_Purchase_Order_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Purchase_Order_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=PO_NO").subscribe((res: any) => {
      this.Purchase_Order_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }

  public Test_Date_Row = [];
  public Calender_Row = [];
  get_Calender_Row(date) {
  
    this.get("Api/Master/Dates?Date="+date).subscribe((res: any) => {
      this.Calender_Row = JSON.parse(res).record;
    


      this.get("Api/Master/Test_Dates?Date="+date).subscribe((res1: any) => {
        this.Test_Date_Row = JSON.parse(res1).record;

       for(var i=0;i<this.Calender_Row.length;i++ )
        {
          if(this.Test_Date_Row.filter(e=>e.T_Date==this.Calender_Row[i]["T_Date"]).length>0)
          {this.Calender_Row[i]["Active"]=this.Test_Date_Row.filter(e=>e.T_Date==this.Calender_Row[i]["T_Date"]).length;

          }
        }
  
      });


    });

  }

  

  get_Month(data)
  {
    
    return this.Calender_Row.filter(e=>e.month_ord==data);
    
  }

  add_month(date,i)
  {
    
    var d = new Date(date);
    d.setMonth(d.getMonth() +    (parseInt(i)-1));
    
    return this.datePipe.transform(d, "MMM-yyyy");

  }



  L = [];
  get_Array(data) {
    this.L = data.split(",");

    return this.L;
  }

  Date_wise_sales_Row = [];
  Item_wise_sales_Row = [];
  Areawise_Sales_Sum_Row = [];
  userwise_Sales_Sum_Row = [];
  Paymodewise_Sales_Sum_Row = [];
  Billwise_Sales_Sum_Row = [];
  Customerwise_sales_Row = [];



  public S_customer = "0";
  public S_Sales = "All";
  public S_Visitor_Type = "All";
  public S_Department = "All";
  public Email_Department = "All";
  public Department = "";
  public Created_by = "All";

  public Outstand_Amt = 0;
  public Outstanding_Rows = [];
  public selected_Out_customer: any = {}
  public selected_Out_supplier: any = {}
  public DB_Customer_Total = 0;
  public Billwise_Out = [];
  public Customerwise_Out = [];
  public Areawise_Out = [];
  public Streetwise_Out = [];
  public Sales_Personwise_Out = [];
  public Duedays_Out = [];
  public DB_Customer_lenth=0;
  get_OutStanding() {
    this.isload = true;
    this.DB_Customer_Total = 0;
    this.Outstanding_Rows=[];
    this.get("Api/Invoice/get_Outstanding?Date=" + this.S_To + "&Sales_person=" + this.S_Sales + "&Area=" + this.S_Area + "&Customer="+this.S_customer+"&Area_Map=" + this.Area_Map + "&Rights=" + this.Rights_Name + "&User_ID=" + this.Current_User.UM_ID + "&Order_by=Bill_Date").subscribe((res: any) => {
      this.isload = false;
      if(res!="[]")
      {
     
      this.Outstanding_Rows = JSON.parse(res).record;

      this.Billwise_Out = [];
      var Bill_No = [...new Set(this.Outstanding_Rows.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Outstanding_Rows.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Outstanding_Rows.filter(e => e.Bill_No == data);

        this.Billwise_Out.push({ 'Bill_No': data, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Bill_Amount': rs[0].Bill_Amount, 'Amount': sum });

      }




      this.Customerwise_Out = [];
      var Ledger_ID = [...new Set(this.Outstanding_Rows.map(item => item.Ledger_ID))];


   
      for (let data of Ledger_ID) {
        var sum = (this.Outstanding_Rows.filter(e => e.Ledger_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Ledger_ID == data)).map(item => item.Bill_No))].length;


        var crow = this.Ledger_Master_Rows.filter(e => e.ID == data)[0];


        try {
          if (Number(sum) != 0) {
            this.Customerwise_Out.push({ 'Customer_Name': crow.Ledger_Name, 'Customer_ID': crow.ID, 'Area': crow.Area, 'Street': crow.Street, 'Phone_No': crow.Phone_Number, 'count': count, 'Amount': sum });
          }
        } catch { }
        try {
          this.DB_Customer_lenth = this.Outstanding_Rows.length;

          this.DB_Customer_Total = (this.Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount),  0)).toFixed(2);
        } catch { }
      }
      }


      this.Areawise_Out = [];
      var Area = [...new Set(this.Outstanding_Rows.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.Outstanding_Rows.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Area == data)).map(item => item.Ledger_ID))].length;

        this.Areawise_Out.push({ 'Area': data, 'count': count, 'Amount': sum });
      }

      this.Streetwise_Out = [];
      var Street = [...new Set(this.Outstanding_Rows.map(item => item.Street))];
      for (let data of Street) {
        var sum = (this.Outstanding_Rows.filter(e => e.Street == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Street == data)).map(item => item.Ledger_ID))].length;

        this.Streetwise_Out.push({ 'Street': data, 'count': count, 'Amount': sum });
      }



      this.Sales_Personwise_Out = [];
      var Created_by = [...new Set(this.Outstanding_Rows.map(item => item.Created_by))];
      for (let data of Created_by) {
        var sum = (this.Outstanding_Rows.filter(e => e.Created_by == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Created_by == data)).map(item => item.Bill_No))].length;

        this.Sales_Personwise_Out.push({ 'Created_by': data, 'count': count, 'Amount': sum });
      }





      this.Duedays_Out = [];
      var Dues = [...new Set(this.Outstanding_Rows.map(item => item.Dues))];
      for (let data of Dues) {
        var sum = (this.Outstanding_Rows.filter(e => e.Dues == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Dues == data)).map(item => item.Bill_No))].length;

        this.Duedays_Out.push({ 'Dues': data, 'count': count, 'Amount': sum });
      }




      try {

        this.Outstand_Amt = (this.Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }


    });
  }
  public DB_payable = 0;
  public Payable_Outstand_Amt = [];
  public Supplierwise_Out = [];
  public Billwise_Payable_Out = [];
  public Payable_Outstanding_Rows = [];
  get_Payable_OutStanding() {
    this.isload = true;
    this.DB_payable = 0;
    this.get("Api/Invoice/get_Purchase_outstanding?Date=" + this.S_To + "&Sales_person=" + this.S_Sales_Person + "&Area=" + this.S_Area + "&Customer=0&Area_Map=" + this.Area_Map + "&Rights=" + this.Rights_Name + "&User_ID=" + this.Current_User.UM_ID + "&Order_by=Bill_Date").subscribe((res: any) => {
      this.isload = false;

      this.Payable_Outstanding_Rows = JSON.parse(res).record;

      this.Billwise_Payable_Out = [];
      var Purchase_No = [...new Set(this.Payable_Outstanding_Rows.map(item => item.Purchase_No))];
      for (let data of Purchase_No) {
        var sum = (this.Payable_Outstanding_Rows.filter(e => e.Purchase_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Payable_Outstanding_Rows.filter(e => e.Purchase_No == data);

        this.Billwise_Payable_Out.push({ 'Bill_No': rs[0].Bill_No, 'Purchase_No': data, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Bill_Amount': rs[0].Bill_Amount, 'Amount': sum });
      }
      this.Supplierwise_Out = [];
      var Ledger_ID = [...new Set(this.Payable_Outstanding_Rows.map(item => item.Ledger_ID))];


      console.log(Ledger_ID);
      for (let data of Ledger_ID) {
        var sum = (this.Payable_Outstanding_Rows.filter(e => e.Ledger_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var count = [...new Set((this.Payable_Outstanding_Rows.filter(e => e.Ledger_ID == data)).map(item => item.Bill_No))].length;

        var crow = this.Ledger_Master_Rows.filter(e => e.ID == data)[0];


        try {
          if (Number(sum) != 0) {
            this.Supplierwise_Out.push({ 'Customer_Name': crow.Ledger_Name, 'Customer_ID': crow.ID, 'Area': crow.Area, 'Street': crow.Street, 'Phone_No': crow.Phone_Number, 'count': count, 'Amount': sum });
          }
        } catch { }
      }
      try {

        this.Payable_Outstand_Amt = (this.Payable_Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }
      try {

        this.DB_payable = (this.Payable_Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0));


      } catch
      {

      }



    });

  }






  public S_Bank = "0";
  public day_Book_Short_by = "AC_Date";
  public cr = 0;
  public db = 0;
  public Total = 0;
  public Rows = [];
  public Day_Book_Row = [];
  public Typewise_DayBook_Row = [];

  public focus_type = "name";
 
  public get_day_Book() {
    this.isload = true;
    this.Day_Book_Row = [];

    this.get("Api/Invoice/get_Daybook?From=" + this.S_From + "&To=" + this.S_To + "&Bank=" + this.S_Bank + "&Pay_Mode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&Order_by=" + this.day_Book_Short_by).subscribe((res: any) => {

      this.Day_Book_Row = JSON.parse(res).record;
      this.isload = false;
      this.Total = 0;
      this.cr = (this.Day_Book_Row.reduce((sum, current) => sum + parseFloat(current.CR_AMT), 0)).toFixed(2);
      this.db = (this.Day_Book_Row.reduce((sum, current) => sum + parseFloat(current.DB_AMT), 0)).toFixed(2);
      this.Total = this.cr - this.db;

      var Item = [...new Set(this.Day_Book_Row.map(item => item.Nar_Type))];
      this.Typewise_DayBook_Row = [];
      for (let data of Item) {
        var cr = (this.Day_Book_Row.filter(e => e.Nar_Type == data).reduce((sum, current) => sum + parseFloat(current.CR_AMT), 0)).toFixed(2);
        var db = (this.Day_Book_Row.filter(e => e.Nar_Type == data).reduce((sum, current) => sum + parseFloat(current.DB_AMT), 0)).toFixed(2);
        var rs = this.Day_Book_Row.filter(e => e.Nar_Type == data);

        this.Typewise_DayBook_Row.push({ Nar_Type: data, AC_Date: rs[0].AC_Date, CR_AMT: cr, DB_AMT: db })
      }

    });

  }
  public DayBook_amt_Row = [];
  public get_dayBook_Amt() {
    this.isload = true;
    this.DayBook_amt_Row = [];
    this.get("Api/invoice/get_daybook_Amt?From=").subscribe((res: any) => {

      this.DayBook_amt_Row = JSON.parse(res).record;
      this.isload = false;



    });

  }


  public dashboard_data() {
    this.get_Sale_Details();
    this.get_Purchase_Details();
    this.get_expense();
    this.get_Recipts();
   // this.get_Ledger_Master();
    //this.get_Item_Master();
    //this.get_OutStanding();
    this.get_Payable_OutStanding();
    this.get_day_Book();
    this.get_Payment();

  }

  public Receipt_Row = [];
  public Receipt_Row_Total = 0;
  public Receipt_Row_Cout = 0;
  public DB_Receipt = 0;
  get_Recipts() {
    this.isload = true;
    this.Receipt_Row_Total = 0;
    this.DB_Receipt = 0;

    this.get("Api/Transaction/get_Collection_Details?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Receipt_Row = JSON.parse(res).record;
      this.isload = false;
      try {

        this.Receipt_Row_Total = (this.Receipt_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Receipt_Row_Cout = this.Receipt_Row.length;
      } catch { }
      try {
        this.DB_Receipt = this.Receipt_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0);
      } catch { }

    });

  }


  public DB_Payment = 0;
  Payment_Details_Row = [];
  Payment_Row_Total = 0;
  Payment_Row_Cout = 0;
  get_Payment() {
    this.isload = true;
    this.DB_Payment = 0;
    this.get("Api/Transaction/get_Paid_Details?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Payment_Details_Row = JSON.parse(res).record;
      this.isload = false;
      try {

        this.Payment_Row_Total = (this.Payment_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Payment_Row_Cout = this.Payment_Details_Row.length;
      } catch
      {

      }
      try {

        this.DB_Payment = (this.Payment_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0));
      } catch
      {

      }
    });

  }


  public get_Daybook_Open_Row = [];
  public get_Daybook_Open() {
    this.isload = true;
    this.Day_Book_Row = [];
    this.get("Api/Invoice/get_Daybook_Open?From=" + this.S_From + "&To=" + this.S_To + "&Bank=" + this.S_Bank + "&Pay_Mode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&Order_by=" + this.day_Book_Short_by).subscribe((res: any) => {

      this.isload = false;
      this.get_Daybook_Open_Row = JSON.parse(res).record;
    });

  }




  public isorder_Load = false;
  public order_Rows_Total = 0;
  get_orders() {
    this.isorder_Load = true;
    this.order_Rows_Total = 0;
    this.isload = true;
    this.get("Api/Invoice/get_Order_details?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area + "&Order_by=" + this.Orders_sort_by).subscribe((res: any) => {
      this.isorder_Load = false;
      this.isload = false;

      
      this.order_Rows = JSON.parse(res).record;
      try {

        this.order_Rows_Total = (this.order_Rows.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0)).toFixed(2);

        this.lenth = this.order_Rows.length;
      } catch
      {
        this.lenth = 0;
      }
    });


  }



  get_Orders1()
  {
    
      
      Promise.all([this.get("Api/Invoice/get_Order_details?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area + "&Order_by=" + this.Orders_sort_by).toPromise()]).then(([res]) => {
        if (res) {
         
        }
      });
    
  }


  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  open_Quotation(data) {
    
    if(this.Quotation_Format=="Format1")
    {
    var parm="User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name="+data.Quote_No.replace(/[^a-zA-Z0-9 ]/g, "_")+"&File_Type=pdf&Quote_No="+data.Quote_No;
    window.open(this.Server_URL+"PDF/Export_Quotation_1?"+parm, "_blank");
    }
    else  if(this.Quotation_Format=="Format2"){
      window.open(this.Server_URL+"report/Quotation?Bill_No="+data.Quote_No+"&Company="+this.Company, "_blank");
 
     }
     else  if(this.Quotation_Format=="Klisters"){
      window.open(this.Server_URL+"report/Klisters_Quotation_Bill?Bill_No="+data.Quote_No+"&Company="+this.Company, "_blank");
 
     }
  }




  public Itemwise_order = [];
  public All_order_Rows = [];
  public Entry_wise_Order = [];
  public Areawise_Order = [];
  public Userwise_Order = [];
  public Cusomerwise_Order = [];
  public Daywise_Order = [];



  get_orders1() {
    this.isorder_Load = true;
    this.order_Rows_Total = 0;
    this.isload = true;
    this.get("Api/Invoice/get_Orders?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area + "&Order_by=x.order_Date").subscribe((res: any) => {
      this.isorder_Load = false;
      this.isload = false;
      this.All_order_Rows = JSON.parse(res).record;

      console.log(this.All_order_Rows);
      this.Entry_wise_Order = [];
      var Order_No = [...new Set(this.All_order_Rows.map(item => item.Order_No))];
      for (let data of Order_No) {
        var sum = (this.All_order_Rows.filter(e => e.Order_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.All_order_Rows.filter(e => e.Order_No == data);

        this.Entry_wise_Order.push({ 'Order_No': data, 'Date': rs[0].Order_Date_, 'Customer_Name': rs[0].Customer_Name, 'Contact_No': rs[0].Contact_No, 'Amount': sum });

      }



      var Item = [...new Set(this.All_order_Rows.map(item => item.Item_ID))];
      this.Itemwise_order = [];
      for (let data of Item) {
        var Amount_ = (this.All_order_Rows.filter(e => e.Item_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.All_order_Rows.filter(e => e.Item_ID == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);


        var rs = this.Item_Master_Rows.filter(e => e.ID == data);


        this.Itemwise_order.push({ 'Bag_Qty': rs[0].Bag_Qty, 'Item_Name': rs[0].Item_Name, 'Disp_Order': rs[0].Display_Order, 'Qty': Qty_, 'Net_Amt': Amount_ })
      }



      this.Areawise_Order = [];
      var Area = [...new Set(this.All_order_Rows.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.All_order_Rows.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Area == data)).map(item => item.Order_No))].length;

        this.Areawise_Order.push({ 'Area': data, 'count': count, 'Amount': sum });
      }


      this.Userwise_Order = [];
      var Created_by = [...new Set(this.All_order_Rows.map(item => item.Created_by))];
      for (let data of Created_by) {
        var sum = (this.All_order_Rows.filter(e => e.Created_by == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Created_by == data)).map(item => item.Order_No))].length;

        this.Userwise_Order.push({ 'User': data, 'count': count, 'Amount': sum });
      }

      this.Cusomerwise_Order = [];
      var Customer_Name = [...new Set(this.All_order_Rows.map(item => item.Customer_Name))];
      for (let data of Customer_Name) {
        var sum = (this.All_order_Rows.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Customer_Name == data)).map(item => item.Order_No))].length;

        this.Cusomerwise_Order.push({ 'Customer_Name': data, 'count': count, 'Amount': sum });
      }
      this.Daywise_Order = [];
      var Order_Date_ = [...new Set(this.All_order_Rows.map(item => item.Order_Date_))];
      for (let data of Order_Date_) {
        var sum = (this.All_order_Rows.filter(e => e.Order_Date_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Order_Date_ == data)).map(item => item.Order_Date_))].length;

        this.Daywise_Order.push({ 'Order_Date_': data, 'count': count, 'Amount': sum });
      }



    });


  }

  public Area_Row = [];

  public Itemwise_Pending_Rows = [];
  public Item_Pending_Total = 0;
  public Item_Pending_Qty = 0;
  get_items_pending() {
    this.isload = true;
    this.Item_Pending_Total = 0;
    this.get("Api/Invoice/get_itemwise_Pending?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area + "&Order_by=i.Display_Order").subscribe((res: any) => {
      this.isload = false;
      this.Itemwise_Pending_Rows = JSON.parse(res).record;

      this.Item_Pending_Total = (this.Itemwise_Pending_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      this.Item_Pending_Qty = (this.Itemwise_Pending_Rows.reduce((sum, current) => sum + parseFloat(current.qty), 0)).toFixed(2);

    });
  }


  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Selected_Referer: any = {};

  public Current_User: any = {};

  public Order_No = "0";

  public states = [
    "Ariyalur",
    "Chengalpattu",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Mayiladuthurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Salem",
    "Sivaganga",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tirupattur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar"
  ];





  get_Category() {

    this.getc("Api/Master/get_Item_Category").subscribe((res: any) => {
      this.Item_Category = JSON.parse(res).record;

      console.log(this.Item_Category);

    });
  }

  public Document_Upload_Rows = [];
  get_Douments() {
    this.getc("Api/Master/get_Document_Upload").subscribe((res: any) => {
      this.Document_Upload_Rows = JSON.parse(res).record;
    });
  }






  public customerwise_Pending_Rows = [];
  get_customerwise_pending() {
    this.getc("Api/Invoice/get_customerwise_Pending").subscribe((res: any) => {
      this.customerwise_Pending_Rows = JSON.parse(res).record;
    });

  }


  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }
  public Bill_Format_Row = [];
  get_Bill_Format() {
    this.getc("Api/Setting/get_Bill_Format").subscribe((res: any) => {
      this.Bill_Format_Row = JSON.parse(res).record;

    });

  }

  F_Row = [];
  get_fields_of(Table) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }


  public Advance_Booking_Rows = [];
  get_Advance_Book() {
    this.getc("Api/Transaction/get_Advance_Booking").subscribe((res: any) => {
      this.Advance_Booking_Rows = JSON.parse(res).record;
    });
  }


  public get_datas() {

    //this.get_Ledger_Master();


  }

  public Sales_Rows = [];
  get_Sales_Rows() {
    this.getc("Api/Transaction/get_Sales").subscribe((res: any) => {
      this.Sales_Rows = JSON.parse(res).record;
    });
  }


  public Menus_Rows: any = {};


  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {
    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;
      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  get_ref_lable(data) {

  
    
    try{
    return this.Reference_Rows.filter(e => e.value == data)[0]["label"];
    }catch{

      return "";
    }
  }


  get_ref(data) {

    try{
    return this.Reference_Rows.filter(e => e.Ref_ID == data);
    }catch{

      return [];
    }
  }

  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.Menu_Master_Rows = JSON.parse(res).record;

      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");



    });
  }


  public Item_Master_Rows = [];
  public company_master = [];



  loading = false;

  public Perment_Item_Master_Row = [];
  public Reset_Item_Master_Row = [];


  public Item_Orderby_Name = "Category,Item_Name";
  public Item_Orderby_Product = "Item_Name";
  public Hod_ID="";
  get_Company_Master() {



    this.getc("Api/Common/get_companys").subscribe((res: any) => {
      this.company_master = JSON.parse(res).record;



    });
  }



  public Items_Rows = [];
  public I_Category = "All";
  public I_Category2 = "All";
  public I_Ledger_Name = "All";

  public I_Brand = "All";
  public I_Item = "";
  public I_Group = "";

  get_Items() {

    this.loading = true;
    this.isload = true;
    this.Items_Rows = [];
    this.get("Api/Master/get_Items?Category=" + this.I_Category + "&Brand=" + this.I_Brand + "&Item=" + this.I_Item + "&Order_by=" + this.Item_Orderby_Name).subscribe((res: any) => {
      this.Items_Rows = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;


    });
  }

  public Item_Row_Cout = 0;
  public Item_Master_RM_Rows=[];

  public RM_all_item:boolean=true;
  get_Item_Master() {

    this.loading = true;
    this.isload = true;
    this.Item_Master_Rows = [];
    this.get("Api/Master/get_Item_Master?Order_by=" + this.Item_Orderby_Name).subscribe((res: any) => {
      this.Item_Master_Rows = JSON.parse(res).record;

      this.Perment_Item_Master_Row = JSON.parse(res).record;
      this.Reset_Item_Master_Row = JSON.parse(res).record;
      


      if(this.RM_all_item==true)
      {
        this.Item_Master_RM_Rows=this.Perment_Item_Master_Row;
      }else
      {
        this.Item_Master_RM_Rows=this.Perment_Item_Master_Row.filter(e=>e.Item_Group=="2");
      }
      


      this.loading = false;
      this.isload = false;


      try {
        this.Item_Row_Cout = this.Item_Master_Rows.length;

      } catch { }

        this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public Testmaster_Rows=[];

 
  get_TestMaster() {

    this.loading = true;
    this.isload = true;
    this.Testmaster_Rows = [];
    this.get("Api/Master/get_Test_Master_New?").subscribe((res: any) => {
      this.Testmaster_Rows = JSON.parse(res).record;

      this.Perment_Item_Master_Row = JSON.parse(res).record;
     
    

      this.loading = false;
      this.isload = false;


    

    });
  }


Test_String=[];
get_Test_String() {

  
    this.Test_String = [];
    this.getc("Api/Invoice/get_Test_String").subscribe((res: any) => {
      this.Test_String = JSON.parse(res).record;
      console.log(this.Test_String);

    });
  }


  
  get_teststing(data) {
    console.log(data);
    console.log(this.Test_String);
    try{
    return this.Test_String.filter(e => e.Test_ID == data);
    }catch{

      return [];
    }
  }

  public Enquiry_Rows=[];

  public DB_Enquiry_Rows=0;

  get_Enquiry_Details() {

    this.loading = true;
    this.isload = true;
    this.Enquiry_Rows = [];
    this.get("Api/Master/get_Enquiry_Details?Enquiry_Status="+this.Enquiry_Status+"&From="+this.S_From+"&To="+this.S_To+"&Search_User="+this.Search_User+"&Area="+this.S_Area).subscribe((res: any) => {
      this.Enquiry_Rows = JSON.parse(res).record;

     
    

      this.loading = false;
      this.isload = false;
      try {
        this.DB_Enquiry_Rows = this.Enquiry_Rows.length;
      } catch { }

    

    });
  }
  public Test_Parameter_Rows=[];


  get_Normal_Value() {

    this.loading = true;
    this.isload = true;
    this.Test_Parameter_Rows = [];
    this.get("Api/Master/get_Test_Parameter?ID="+this.Test_ID).subscribe((res: any) => {
      this.Test_Parameter_Rows = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;


    

    });
  }
 
  get_TestMaster_Details() {

    this.loading = true;
    this.isload = true;
    this.Test_Parameter_Rows = [];
    this.get("Api/Master/get_Test_Parameter?ID="+this.Edit_Row.ID).subscribe((res: any) => {
      this.Test_Parameter_Rows = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;


    

    });
  }
  public Referrer_Master_Type=[];

  get_Referrer_Master_Type() {

    this.loading = true;
    this.isload = true;
    this.Referrer_Master_Type = [];
    this.getc("Api/Master/get_Referrer_Master_Type").subscribe((res: any) => {
      this.Referrer_Master_Type = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;

    });
  }
  public Referrer_Master_Rows=[];
  public Refer_Type="311";
  public Refer_Type_="All";

  public Referer_Name="All";

  get_Referrer_Master() {

    this.loading = true;
    this.isload = true;
    this.Referrer_Master_Rows = [];
    this.get("Api/Master/get_Referrer_Master?Refer_Type="+this.Refer_Type).subscribe((res: any) => {
      this.Referrer_Master_Rows = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;

    });
  }
  public Test_Row_Cout = 0;
  public Test_Master_Rows=[];
  public Test_Pending="Pending"

  public Sample_Collection_Rows=[];
  public DB_Sample_Collection=0;
  get_Sample_Collection() {

 
    this.Test_Master_Rows = [];
    this.get("Api/Transaction/get_Sample_Collection?From="+this.S_From+"&To="+this.S_To+"&Test_Status="+this.L_Test_Status).subscribe((res: any) => {
      this.Sample_Collection_Rows = JSON.parse(res).record;
      
      try {
        this.DB_Sample_Collection = this.Sample_Collection_Rows.length;
      } catch { }

    });
    




  }


  public Equipment_Details_Rows=[];
  
  public Characteristics_Rows=[];
  get_Test_Characteristics(data) {

    this.get("Api/Master/get_Test_Characteristics?Request_No="+data).subscribe((res: any) => {
      this.Characteristics_Rows = JSON.parse(res).record;
    });
    




  }
  public Perday_Dep_Cost=5;
  public Depreciation_NO=313;
  public Equipment_Master_Rows=[];
  public Test_Report_No:any;
public Test_Cost;
  get_Equipment_Master() {

 
    this.Equipment_Master_Rows = [];
    this.get("Api/Master/get_Equipment_Master?Equipment_Name="+this.Equipment_Name).subscribe((res: any) => {
    this.Equipment_Master_Rows = JSON.parse(res).record;
   
    
    

    });
    




  }
  get_Referer(data) {
    try{
      return this.Referrer_Master_Type.filter(e => e.Refer_Type == data)[0]["label"];

    }catch{
      return [];
    }
  }

  public Head_Assignment=[];
  get_Head_Assignment() {

    this.loading = true;
    this.isload = true;
    this.Head_Assignment = [];
    this.get("Api/Master/get_Head_Assignment?").subscribe((res: any) => {
      this.Head_Assignment = JSON.parse(res).record;


      this.loading = false;
      this.isload = false;


      // console.log(this.Item_Category);
    });
  }
  public Item_Master_Rows1=[];
  get_Price_Item_Master1() {

    this.loading = true;
    this.isload = true;
    this.Item_Master_Rows1 = [];
    this.get("Api/Master/get_Price_List?I_Category=" + this.I_Category).subscribe((res: any) => {
      this.Item_Master_Rows1 = JSON.parse(res).record;

      this.Perment_Item_Master_Row = JSON.parse(res).record;
      this.Reset_Item_Master_Row = JSON.parse(res).record;
      


     
      


      this.loading = false;
      this.isload = false;


      try {
        this.Item_Row_Cout = this.Item_Master_Rows.length;

      } catch { }

        this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public Cash_Settlement=[];

  public Cash_Settlement_Rows=[];
  get_Cash_Settlement() {

    this.loading = true;
    this.isload = true;
    this.Cash_Settlement_Rows = [];
    this.get("Api/Invoice/get_Cash_Settlement?From=" + this.S_From +  "&To=" + this.S_To  + "&Sales_person="+this.S_Sales+"&User=" + this.Search_User + "&Area=" + this.S_Area  + "&order_by=x.Bill_No desc").subscribe((res: any) => {

      this.Cash_Settlement_Rows = JSON.parse(res).record;
    


     
      


      this.loading = false;
      this.isload = false;


     
      // console.log(this.Item_Category);
    });
  }
  public Reset_Product_Master_Rows=[];
  public Perment_Product_Master_Rows=[];
  public Product_Master_Rows=[];
  public Product_Row_Cout = 0;
  public Product_Master_RM_Rows=[];
  get_Product_Master() {

    this.loading = true;
    this.isload = true;
    this.Product_Master_Rows = [];
    this.get("Api/Master/get_Product_Master?Order_by=" + this.Item_Orderby_Product).subscribe((res: any) => {
      this.Product_Master_Rows = JSON.parse(res).record;

      this.Perment_Product_Master_Rows = JSON.parse(res).record;
      this.Reset_Product_Master_Rows = JSON.parse(res).record;
      
      this.Product_Master_RM_Rows=this.Perment_Product_Master_Rows.filter(e=>e.Item_Group=="2");
      console.log( this.Product_Master_RM_Rows);
      this.loading = false;
      this.isload = false;


      try {
        this.Product_Row_Cout = this.Product_Master_Rows.length;

      } catch { }

      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public BOM_Master_Rows = [];
  public FG_ID = "0";
  get_BOM_Master() {
    this.loading = true;
    this.isload = true;
    this.BOM_Master_Rows = [];
    this.get("Api/Master/get_BOM_Master_By_FG?FG_ID=" + this.FG_ID + "&Order_by=RM_Order").subscribe((res: any) => {
      this.BOM_Master_Rows = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
    });
  }

  public Table_Master_Rows = [];
  public Perment_Table_Master_Rows = [];
  public Reset_Table_Master_Row = [];
  public Table_Orderby_Name = "Name";


  Num(data) {
    return Number(data).toFixed(2);
  }


  get_Table_Master() {
    this.loading = true;
    this.isload = true;
    this.Table_Master_Rows = [];
    this.get("Api/Master/get_Table_Master?Order_by=" + this.Table_Orderby_Name).subscribe((res: any) => {
      this.Table_Master_Rows = JSON.parse(res).record;
      this.Perment_Table_Master_Rows = JSON.parse(res).record;
      this.Reset_Table_Master_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }

  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";

  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }


  public load_from_make_invoice:boolean=false;
  public Stcok_Row = [];
  public P_Stcok_Row = [];
  public item_Category_ = "";
  get_Item_Master1(id) {
    this.Stcok_Row = [];
    this.loading = true;
    this.get("Api/Master/get_Item_Stock_bycustomer1?Ledger_ID=" + id + "&Category=" + this.item_Category_ + "&Check_Stock="+this.Check_Stock+"&Order_by= x.Category,Item_Name&Order_No="+this.Order_No).subscribe((res: any) => {
      this.Stcok_Row = JSON.parse(res).record;
      this.P_Stcok_Row = JSON.parse(res).record;
      this.loading = false;
      this.load_Temp_Order_Row()
    });
  }


  load_Temp_Order_Row()
  {

    try {
      var Qty=0;
      var  Rate =0;
      var  Free =0;
      this.Stcok_Row.forEach(x => x.Model = "0");

      for (var i = 0; i < this.Stcok_Row.length; i++) {
        try {

           Qty = this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Order_Qty"];
          Rate = this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Order_Rate"];
          Free = this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Free"];
          
          Qty=parseFloat(Qty.toString());
          Rate=parseFloat(Rate.toString());
          Free=parseFloat(Free.toString());
          
        } catch {  

          Qty=0;
          Rate=0;
          Free=0;
         }

         
         if(Rate>0)
         {
         this.Stcok_Row[i]["Order_Rate"] = Rate;
         }

         if(Qty>0)
         {
          this.Stcok_Row[i]["Order_Qty"] = Qty;
          this.Stcok_Row[i]["Model"] = Qty;
          
         }

         if(Free>0)
         {
          this.Stcok_Row[i]["Free"] = Free;
         }

         
      }
    } catch { }


    
    this.Stcok_Row=this.Stcok_Row.sort((b, a) => Number(a["Model"]) - Number((b["Model"])));
    

  
  }

  public header_Row: any = {}
  public Details_Row = [];

  public Temp_Order_Row = [];

  add_one: number = 1;
  remove_one: number = -1;

  public Tax_Type = "exclusive";
  public GST_Type = "local";
  public cart_item = [

  ];

  /*{
  BAG: "1",
  CGST_PER: 0,
  CGST_TAX_AMT: 0,
  CUS_FREE: "0",
  DESCRIPTION: "bottle of 60 capsules",
  DISC_AMT: "0",
  DISC_PER: "0",
  DISC_TYPE: "",
  GST_PER: "0.00",
  HSNCode: "-",
  IGST_PER: 0,
  IGST_TAX_AMT: 0,
  ITEM_ID: "5",
  ITEM_NAME: "Inlife Green Tea Extract 500mg ",
  MRP: "0.00",
  NET_AMT: 870,
  NET_RATE: "290.00",
  PURCHASE_RATE: "0.000",
  Qty: 3,
  RESALE_RATE: "0.00",
  SALE_RATE: "290.00",
  SGST_PER: 0,
  SGST_TAX_AMT: 0,
  SOC_FREE: "0",
  TAXABLE_AMT: 870,
  TAX_REVYES: "",
  TAX_TYPE: "",
  TAX_YESNO: "",
  TOTAL_TAX: 0,
  TOT_AMT: 870,
  UNIT: "2"
  },
  {
  BAG: "1",
  CGST_PER: 0,
  CGST_TAX_AMT: 0,
  CUS_FREE: "0",
  DESCRIPTION: "bottle of 60 capsules",
  DISC_AMT: "0",
  DISC_PER: "0",
  DISC_TYPE: "",
  GST_PER: "0.00",
  HSNCode: "-",
  IGST_PER: 0,
  IGST_TAX_AMT: 0,
  ITEM_ID: "5",
  ITEM_NAME: "Inlife Green Tea Extract 500mg ",
  MRP: "0.00",
  NET_AMT: 870,
  NET_RATE: "290.00",
  PURCHASE_RATE: "0.000",
  Qty: 3,
  RESALE_RATE: "0.00",
  SALE_RATE: "290.00",
  SGST_PER: 0,
  SGST_TAX_AMT: 0,
  SOC_FREE: "0",
  TAXABLE_AMT: 870,
  TAX_REVYES: "",
  TAX_TYPE: "",
  TAX_YESNO: "",
  TOTAL_TAX: 0,
  TOT_AMT: 870,
  UNIT: "2"
  }
  ];  
  */


  Clear_Cart() {

    this.Selected_Customer = {};
    this.cart_item = [];
    try {
      this.Item_Master_Rows.forEach(x => x.Order_Qty = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
    } catch { }

  }




  Clear_Order_Cart() {

    this.Selected_Customer = {};
    this.cart_item = [];
    try {
      this.Item_Master_Rows.forEach(x => x.Order_Qty = "0");
      this.Perment_Item_Master_Row.forEach(x => x.Order_Qty = "0");

      this.Item_Master_Rows.forEach(x => x.Description = "");
      this.Perment_Item_Master_Row.forEach(x => x.Description = "");

    } catch { }

  }




  public assign = "Assign";

  public Pack = "Pack";

  add_item1(data, qty, pack, rate) {


  var qty_ = 0;
    var index = this.Item_Master_Rows.findIndex(function (item, i) {
      return item.ID === data.ID
    });  


    var bag_Qty = this.Item_Master_Rows[index]["Bag_Qty"];

    if (index > -1) {

      qty_ = (Number(pack) * Number(bag_Qty)) + Number(qty);


      this.Item_Master_Rows[index]["Order_Qty"] = qty_;
      this.Item_Master_Rows[index]["Rate"] = rate;
      this.Item_Master_Rows[index]["Bag"] = pack;
      this.Item_Master_Rows[index]["Qty"] = qty;

      var inx = this.Perment_Item_Master_Row.findIndex(function (item, i) {
        return item.ID === data.ID
      });

      this.Perment_Item_Master_Row[inx]["Order_Qty"] = qty_;


      var ID = "";
      ID = this.Item_Master_Rows[index]["ID"];
      if (this.cart_item.length > 0) {
        var Temp_Index = this.cart_item.findIndex(function (item, i) {
          return item.ITEM_ID == ID;

        });

        if (Temp_Index > -1) {
          this.cart_item.splice(Temp_Index, 1);
        }
      }
    }




    var Disc_Rate = rate;
    var GST_per = data.GST_Per;
    var Final_Rate = 0.00;
    var Final_AMT = 0.00;
    var IGST_AMT = 0.00;
    var SGST_AMT = 0.00;
    var CGST_AMT = 0.00;

    var IGST_Per = 0.00;
    var SGST_Per = 0.00;
    var CGST_Per = 0.00;

    if (this.Tax_Type.toLowerCase() == "inclusive") {
      Final_Rate = (Number(Disc_Rate) / (100 + Number(GST_per))) * 100;
      Final_AMT = ((Number(Disc_Rate) / (100 + Number(GST_per))) * 100) * Number(qty_);
    }
    else {
      Final_Rate = Number(Disc_Rate);
      Final_AMT = Number(Disc_Rate) * Number(qty_);
    }

    if (this.GST_Type.toLowerCase() == "local") {
      IGST_AMT = 0.00;
      SGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);
      CGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);


      IGST_Per = 0.00;
      SGST_Per = (Number(GST_per) / 2);
      CGST_Per = (Number(GST_per) / 2);
    }
    else {
      IGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per));
      SGST_AMT = 0.00;
      CGST_AMT = 0.00;


      IGST_Per = (Number(GST_per));
      SGST_Per = 0.00;
      CGST_Per = 0.00;
    }





    var Temp_data = {

      'ITEM_ID': data.value,

      'ITEM_CODE': data.Part_No,
      'ITEM_NAME': data.label,
      'DESCRIPTION': data.Description,
      'HSNCode': data.HSN_Code,
      'UNIT': data.UOM,
      'BAG': '1',
      'Qty': qty_,
      'PURCHASE_RATE': data.Purchase_Rate,
      'RESALE_RATE': data.Wholesale_Rate,
      'SALE_RATE': rate,
      'MRP': data.compareAtPrice,
      'CUS_FREE': '0',
      'SOC_FREE': '0',
      'NET_RATE': rate,
      'TOT_AMT': Number(rate) * Number(qty_),
      'DISC_TYPE': '',
      'DISC_PER': '0',
      'DISC_AMT': '0',
      'TAXABLE_AMT': Final_AMT,
      'TAX_YESNO': '',
      'TAX_REVYES': '',
      'TAX_TYPE': '',
      'Bag': pack,
      'Pcs': qty,
      'GST_PER': data.GST_Per,
      'CGST_PER': CGST_Per,
      'SGST_PER': SGST_Per,
      'IGST_PER': IGST_Per,
      'CGST_TAX_AMT': CGST_AMT,
      'SGST_TAX_AMT': SGST_AMT,
      'IGST_TAX_AMT': IGST_AMT,
      'TOTAL_TAX': Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT),
      'NET_AMT': Number(Final_AMT) + Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT)
    }

    if (qty_ > 0) {
      this.cart_item.push(Temp_data)
    }

    this.cart_item.sort((a, b) => a["ITEM_NAME"].localeCompare(b["ITEM_NAME"]))

    this.C_Sub_Total = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Taxable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TAXABLE_AMT), 0)).toFixed(2);
    this.C_IGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.IGST_TAX_AMT), 0)).toFixed(2);
    this.C_SGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.SGST_TAX_AMT), 0)).toFixed(2);
    this.C_CGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.CGST_TAX_AMT), 0)).toFixed(2);
    this.C_Tax = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TOTAL_TAX), 0)).toFixed(2);

    this.C_Total_Payable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Savings = (this.cart_item.reduce((sum, current) => sum + ((parseFloat(current.MRP) - parseFloat(current.SALE_RATE)) * parseFloat(current.Qty)), 0)).toFixed(2);

  }

  public Drugmaster_Rows=[];

 
  get_Drug_Master() {

    this.loading = true;
    this.isload = true;
    this.Drugmaster_Rows = [];
    this.get("Api/Master/get_Drug_Master?").subscribe((res: any) => {
      this.Drugmaster_Rows = JSON.parse(res).record;

     
    

      this.loading = false;
      this.isload = false;


    

    });
  }
  add_item(data, qty) {




    var qty_ = 0;
    var index = this.Item_Master_Rows.findIndex(function (item, i) {
      return item.ID === data.ID
    });



    if (index > -1) {

      qty_ = Number(this.Item_Master_Rows[index]["Order_Qty"]) + Number(qty);

      this.Item_Master_Rows[index]["Order_Qty"] = qty_;
      var inx = this.Perment_Item_Master_Row.findIndex(function (item, i) {
        return item.ID === data.ID
      });

      this.Perment_Item_Master_Row[inx]["Order_Qty"] = qty_;


      var ID = "";
      ID = this.Item_Master_Rows[index]["ID"];
      if (this.cart_item.length > 0) {
        var Temp_Index = this.cart_item.findIndex(function (item, i) {
          return item.ITEM_ID == ID;

        });

        if (Temp_Index > -1) {
          this.cart_item.splice(Temp_Index, 1);
        }
      }
    }




    var Disc_Rate = data.Rate;
    var GST_per = data.GST_Per;
    var Final_Rate = 0.00;
    var Final_AMT = 0.00;
    var IGST_AMT = 0.00;
    var SGST_AMT = 0.00;
    var CGST_AMT = 0.00;

    var IGST_Per = 0.00;
    var SGST_Per = 0.00;
    var CGST_Per = 0.00;

    if (this.Tax_Type.toLowerCase() == "inclusive") {
      Final_Rate = (Number(Disc_Rate) / (100 + Number(GST_per))) * 100;
      Final_AMT = ((Number(Disc_Rate) / (100 + Number(GST_per))) * 100) * Number(qty_);
    }
    else {
      Final_Rate = Number(Disc_Rate);
      Final_AMT = Number(Disc_Rate) * Number(qty_);
    }

    if (this.GST_Type.toLowerCase() == "local") {
      IGST_AMT = 0.00;
      SGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);
      CGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);


      IGST_Per = 0.00;
      SGST_Per = (Number(GST_per) / 2);
      CGST_Per = (Number(GST_per) / 2);
    }
    else {
      IGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per));
      SGST_AMT = 0.00;
      CGST_AMT = 0.00;


      IGST_Per = (Number(GST_per));
      SGST_Per = 0.00;
      CGST_Per = 0.00;
    }








    var Temp_data = {

      'ITEM_ID': data.value,
      'ITEM_CODE': data.Item_Code,
      'ITEM_NAME': data.label,
      'DESCRIPTION': data.Description,
      'HSNCode': data.HSN_Code,
      'UNIT': data.UOM,
      'BAG': '1',
      'Qty': qty_,
      'PURCHASE_RATE': data.Purchase_Rate,
      'RESALE_RATE': data.Wholesale_Rate,
      'SALE_RATE': data.Rate,
      'MRP': data.compareAtPrice,
      'CUS_FREE': '0',
      'SOC_FREE': '0',
      'NET_RATE': data.Rate,
      'TOT_AMT': Number(data.Rate) * Number(qty_),
      'DISC_TYPE': '',
      'DISC_PER': '0',
      'DISC_AMT': '0',
      'TAXABLE_AMT': Number(data.Rate) * Number(qty_),
      'TAX_YESNO': '',
      'TAX_REVYES': '',
      'TAX_TYPE': '',
      'Bag': '1',
      'Pcs': '0',
      'GST_PER': data.GST_Per,
      'CGST_PER': CGST_Per,
      'SGST_PER': SGST_Per,
      'IGST_PER': IGST_Per,
      'CGST_TAX_AMT': CGST_AMT,
      'SGST_TAX_AMT': SGST_AMT,
      'IGST_TAX_AMT': IGST_AMT,
      'TOTAL_TAX': Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT),
      'NET_AMT': (Number(data.Rate) * Number(qty_)) + Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT)
    }

    if (qty_ > 0) {
      this.cart_item.push(Temp_data)
    }

    this.cart_item.sort((a, b) => a["ITEM_NAME"].localeCompare(b["ITEM_NAME"]))

    this.C_Sub_Total = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Taxable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TAXABLE_AMT), 0)).toFixed(2);
    this.C_IGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.IGST_TAX_AMT), 0)).toFixed(2);
    this.C_SGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.SGST_TAX_AMT), 0)).toFixed(2);
    this.C_CGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.CGST_TAX_AMT), 0)).toFixed(2);
    this.C_Tax = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TOTAL_TAX), 0)).toFixed(2);

    this.C_Total_Payable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Savings = (this.cart_item.reduce((sum, current) => sum + ((parseFloat(current.MRP) - parseFloat(current.SALE_RATE)) * parseFloat(current.Qty)), 0)).toFixed(2);

  }


  add_item2(data, qty, pack, rate) {


    var qty_ = 0;
    var index = this.Item_Master_Rows.findIndex(function (item, i) {
      return item.ID == data.ID
    });



    var bag_Qty = this.Item_Master_Rows[index]["Bag_Qty"];

    if (index > -1) {

      qty_ = (Number(pack) * Number(bag_Qty)) + Number(qty);


      this.Item_Master_Rows[index]["Order_Qty"] = qty_;
      this.Item_Master_Rows[index]["Rate"] = rate;
      this.Item_Master_Rows[index]["Bag"] = pack;
      this.Item_Master_Rows[index]["Qty"] = qty;

      var inx = this.Perment_Item_Master_Row.findIndex(function (item, i) {
        return item.ID === data.ID
      });

      this.Perment_Item_Master_Row[inx]["Order_Qty"] = qty_;


      var ID = "";
      ID = this.Item_Master_Rows[index]["ID"];
      if (this.cart_item.length > 0) {
        var Temp_Index = this.cart_item.findIndex(function (item, i) {
          return item.ITEM_ID == ID;

        });

        if (Temp_Index > -1) {
          this.cart_item.splice(Temp_Index, 1);
        }
      }
    }




    var Disc_Rate = data.Rate;
    var GST_per = data.GST_Per;
    var Final_Rate = 0.00;
    var Final_AMT = 0.00;
    var IGST_AMT = 0.00;
    var SGST_AMT = 0.00;
    var CGST_AMT = 0.00;

    var IGST_Per = 0.00;
    var SGST_Per = 0.00;
    var CGST_Per = 0.00;

    if (this.Tax_Type.toLowerCase() == "inclusive") {
      Final_Rate = (Number(Disc_Rate) / (100 + Number(GST_per))) * 100;
      Final_AMT = ((Number(Disc_Rate) / (100 + Number(GST_per))) * 100) * Number(qty_);
    }
    else {
      Final_Rate = Number(Disc_Rate);
      Final_AMT = Number(Disc_Rate) * Number(qty_);
    }

    if (this.GST_Type.toLocaleUpperCase() == "local") {
      IGST_AMT = 0.00;
      SGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);
      CGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);


      IGST_Per = 0.00;
      SGST_Per = (Number(GST_per) / 2);
      CGST_Per = (Number(GST_per) / 2);
    }
    else {
      IGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per));
      SGST_AMT = 0.00;
      CGST_AMT = 0.00;


      IGST_Per = (Number(GST_per));
      SGST_Per = 0.00;
      CGST_Per = 0.00;
    }





    var Temp_data = {

      'ITEM_ID': data.value,
      'ITEM_CODE': data.Part_No,
      'ITEM_NAME': data.label,
      'DESCRIPTION': data.Description,
      'HSNCode': data.HSN_Code,
      'UNIT': data.UOM,
      'BAG': '1',
      'Qty': qty_,
      'PURCHASE_RATE': data.Purchase_Rate,
      'RESALE_RATE': data.Wholesale_Rate,
      'SALE_RATE': rate,
      'MRP': data.compareAtPrice,
      'CUS_FREE': '0',
      'SOC_FREE': '0',
      'NET_RATE': rate,
      'TOT_AMT': Number(rate) * Number(qty_),
      'DISC_TYPE': '',
      'DISC_PER': '0',
      'DISC_AMT': '0',
      'TAXABLE_AMT': Final_AMT,
      'TAX_YESNO': '',
      'TAX_REVYES': '',
      'TAX_TYPE': '',
      'Bag': pack,
      'Pcs': qty,
      'GST_PER': data.GST_Per,
      'CGST_PER': CGST_Per,
      'SGST_PER': SGST_Per,
      'IGST_PER': IGST_Per,
      'CGST_TAX_AMT': CGST_AMT,
      'SGST_TAX_AMT': SGST_AMT,
      'IGST_TAX_AMT': IGST_AMT,
      'TOTAL_TAX': Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT),
      'NET_AMT': Number(Final_AMT) + Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT)
    }

    if (qty_ > 0) {
      this.cart_item.push(Temp_data)
    }

    this.cart_item.sort((a, b) => a["ITEM_NAME"].localeCompare(b["ITEM_NAME"]))

    this.C_Sub_Total = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Taxable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TAXABLE_AMT), 0)).toFixed(2);
    this.C_IGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.IGST_TAX_AMT), 0)).toFixed(2);
    this.C_SGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.SGST_TAX_AMT), 0)).toFixed(2);
    this.C_CGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.CGST_TAX_AMT), 0)).toFixed(2);
    this.C_Tax = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TOTAL_TAX), 0)).toFixed(2);

    this.C_Total_Payable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Savings = (this.cart_item.reduce((sum, current) => sum + ((parseFloat(current.MRP) - parseFloat(current.SALE_RATE)) * parseFloat(current.Qty)), 0)).toFixed(2);

  }

  public Sales_Profit: boolean = false;

  public DF_Bill_Type = "All";
  public check_Unicode: boolean = false;
  public lable_ = "";
  public Stock_Check: boolean = true;

  Search_Item_Category(data) {

    console.log(data);
    this.Selected_item_Category = data;
    if (data == "All") {
      this.Item_Master_Rows = this.Perment_Item_Master_Row;

    }
    else {
      this.Item_Master_Rows = this.Perment_Item_Master_Row.filter(e => e.Category.toLowerCase().includes(data.toLowerCase()));
    }

  }



  public Room_Check_OUT_Rows = [];
  get_Room_Check_out() {
    this.getc("Api/Transaction/get_Room_Check_OUT").subscribe((res: any) => {
      this.Room_Check_OUT_Rows = JSON.parse(res).record;
    });
  }




  public Advance_Collection_Rows = [];
  get_Advance_Collection() {
    this.get("Api/Transaction/get_Advance_Collection?From=" + this.From_Date.formatted + "&To=" + this.To_Date.formatted).subscribe((res: any) => {
      this.Advance_Collection_Rows = JSON.parse(res).record;
    });
  }

  public Room_Check_IN_Rows = [];
  get_Room_Check_IN() {
    this.getc("Api/Transaction/get_Room_Check_IN").subscribe((res: any) => {
      this.Room_Check_IN_Rows = JSON.parse(res).record;
    });
  }
  public Schedule_count = 0;

  public ledger_count = 0;
  public Ledger_Order_by = "created_date desc";
  public Ledger_Master_Rows = [];
  public PLedger_Master_Rows = [];
  public SLedger_Rows = [];
  public LedgerLoad: boolean = false;

  get_Ledger_Master() {
    this.LedgerLoad = true;

    this.isload = true;
    this.Ledger_Master_Rows = [];
    this.get("Api/Master/get_Ledger_Master1?Lfrom=" + this.Lfrom + "&Area=" + this.S_Area + "&Order=" + this.Ledger_Order_by).subscribe((res: any) => {
      this.isload = false;
      this.LedgerLoad = false;

      this.Ledger_Master_Rows = JSON.parse(res).record;
      this.PLedger_Master_Rows = JSON.parse(res).record;

      this.ledger_Filter();
      try {
        this.ledger_count = this.Ledger_Master_Rows.length;
      } catch { }
    });
  }
  public Customer_Master_Rows = [];
  public Patient_Name: boolean = false;
  get_Customer_Master() {
    this.LedgerLoad = true;

    this.isload = true;
    this.Customer_Master_Rows = [];
    this.get("Api/Master/get_Customer_Master?Lfrom=" + this.Lfrom + "&Area=" + this.S_Area ).subscribe((res: any) => {
      this.isload = false;
      this.LedgerLoad = false;

      this.Customer_Master_Rows = JSON.parse(res).record;

 
    });
  }
  change_Age(data)
  {
    this.get_Age(data);
    this.get_Month_(data);
    this.get_Days(data);


  }
  get_Days(data)
  {
    this.get("Api/invoice/GetDaysBetween?Date1="+data).subscribe((res: any) => {
    this.header_Row.Days_ = res;

    });

  }
  get_Age(data)
  {
    this.get("Api/invoice/get_Age?Date="+data).subscribe((res: any) => {
    this.header_Row.Age = res;

    });

  }
  get_Month_(data)
  {
    this.get("Api/invoice/GetMonthsBetween?Date1="+data+"&Date2="+this.S_From).subscribe((res: any) => {
    this.header_Row.Months_ = res;

    });

  }
   public visitor_count=0;
  public Visior_Master_Rows = [];
  public VisiorLoad: boolean = false;
  public Pvisitor_Master_Rows=[];
  get_Visitor_Master() {
    this.VisiorLoad = true;

    this.isload = true;
    this.Visior_Master_Rows = [];
    this.get("Api/Master/get_Visitor_Master?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Approval_Status+"&User=" +this.Search_User ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Visior_Master_Rows = JSON.parse(res).record;
      this.Pvisitor_Master_Rows = JSON.parse(res).record;

      this.ledger_Filter();
      try {
        this.visitor_count = this.Visior_Master_Rows.length;
      } catch { }
     
    });
  }
  public Visior_Master_Rows1 = [];
  public VisiorLoad1: boolean = false;
  public Pvisitor_Master_Rows1=[];
  public Visior_approval_count=0;
  get_Visitor_Master1() {
    this.VisiorLoad = true;
this.Visior_approval_count=0;
    this.isload = true;
    this.visitor_count=0;
    this.Visior_Master_Rows1 = [];
    this.get("Api/Master/get_Visitor_For_Approval?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Approval_Status+"&Emp_ID=" + this.Emp_ID ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Visior_Master_Rows1 = JSON.parse(res).record;
     
      
      try {
        this.visitor_count = this.Visior_Master_Rows.length;
        this.Visior_approval_count= this.Visior_Master_Rows.length;
      } catch { }
     
    });
  }get_Laptop_Mobile_Approved


  public filter_data="";

  public Visitor_Approved_count=0;
  public Visitor_Approved_Row=[];
  get_Visitor_Approved() {
    this.VisiorLoad = true;

    this.isload = true;
    this.Visior_Master_Rows1 = [];
    this.get("Api/Master/get_Visitor_Approved?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Approval_Status+"&Emp_ID=" + this.Emp_ID ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Visitor_Approved_Row = JSON.parse(res).record;
      try
      {
      this.Visitor_Approved_count = this.Visitor_Approved_Row.length;
      }catch{}
     
     
    });
  }

  public Laptop_Approved_count=0;
  public Laptop_Approved_Row=[];
  get_Laptop_Approved() {
    this.VisiorLoad = true;

    this.isload = true;
    this.Laptop_Approved_Row = [];
    this.get("Api/Master/get_Laptop_Mobile_Approved?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Laptop_Approval+this.Mobile_Approval+"&Emp_ID=" + this.Emp_ID ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Laptop_Approved_Row = JSON.parse(res).record;
      try
      {
      this.Laptop_Approved_count = this.Laptop_Approved_Row.length;
      }catch{}
     
     
    });
  }

  public Visitor_at_Gate_count1=0;
  public Visitor_at_Gate_Row=[];
  get_Visitor_at_Gate() {
    this.VisiorLoad = true;

    this.isload = true;
    this.Visior_Master_Rows1 = [];
    this.get("Api/Master/get_Visitor_at_gate?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Approval_Status+"&Emp_ID=" + this.Emp_ID ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;
     this.Visitor_at_Gate_count1=0;
      this.Visitor_at_Gate_Row = JSON.parse(res).record;
     
     
      try
      {
        this.Visitor_at_Gate_count1=this.Visitor_at_Gate_Row.length;
      }catch{}
     
    });
  }
  public Visitor_at_Gate_count=0;
  public Visitor_at_Gate_Row1=[];
  get_Visitor_at_Gate1() {
    this.VisiorLoad = true;

    this.isload = true;
    this.Visitor_at_Gate_count=0;
    this.Visitor_at_Gate_Row1 = [];
    this.get("Api/Master/get_Visitor_at_gate1?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Approval_Status+"&Emp_ID=" + this.Emp_ID ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Visitor_at_Gate_Row1 = JSON.parse(res).record;
      if(this.Visitor_at_Gate_Row1)
      {
      this.Visitor_at_Gate_count = this.Visitor_at_Gate_Row1.length;
      }

     
     
    });
  }



  public allowed_visitor:number=0;
  public Visitor_allowed_Row=[];
  get_Visitor_allowed() {
    this.VisiorLoad = true;
    this.allowed_visitor=0;
    this.isload = true;
    this.Visitor_at_Gate_Row1 = [];

    this.get("Api/Master/get_Visitor_allowed?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Approval_Status+"&Emp_ID=" + this.Emp_ID ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Visitor_allowed_Row = JSON.parse(res).record;

      if(this.Visitor_allowed_Row)
      {
       
      this.allowed_visitor=this.Visitor_allowed_Row.length;
     
      }

     
     
    });
  }
  public Checkin_count=0;
  public Checkin_Visitor_Row=[];
  public PCheckin_Visitor_Row=[];
  get_Checkin_Visitor() {
    this.VisiorLoad = true;

   
    //this.Checkin_Visitor_Row = [];
    this.getc("Api/Master/get_Checkin_Visitor").subscribe((res: any) => {

      this.isload = false;
      this.VisiorLoad = false;
      this.Checkin_count=0;
      this.Checkin_Visitor_Row = JSON.parse(res).record;
      this.PCheckin_Visitor_Row=JSON.parse(res).record;
      try
      {
      this.Checkin_count = this.Checkin_Visitor_Row.length;
      }catch{}

     
     
    });
  }
  public Checkin_admin_count=0;
  public Checkin_admin_Row=[];
  get_Checkin_Admin() {
    this.VisiorLoad = true;

    this.isload = true;
    this.Checkin_admin_Row = [];
    this.get("Api/Master/get_Checkin_Visitor_admin?Emp_ID=" + this.Emp_ID).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Checkin_admin_Row = JSON.parse(res).record;
      this.Checkin_admin_count = this.Checkin_admin_Row.length;

     
     
    });
  }
  public Checkout_admin_count=0;
  public Checkout_admin_Row=[];
  get_Checkout_Admin() {
    this.VisiorLoad = true;

    this.isload = true;
    this.Checkout_admin_Row = [];
    this.get("Api/Master/get_Checkout_Visitor_admin?Emp_ID=" + this.Emp_ID).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Checkout_admin_Row = JSON.parse(res).record;
      if( this.Checkout_admin_Row)
      {
      this.Checkout_admin_count = this.Checkout_admin_Row.length;
      }
      else
      {
        this.Checkout_admin_count=0;
      }

     
     
    });
  }
  public Checkout_count=0;
  public Checkout_Visitor_Row=[];
  get_Checkout_Visitor() {
    this.VisiorLoad = true;

  
    this.Checkout_Visitor_Row = [];
    this.get("Api/Master/get_Checkout_Visitor?From=" + this.S_From + "&To=" + this.S_To + "&Visitor="+this.S_Visitor_Type+"&Department=" + this.S_Department ).subscribe((res: any) => {
      this.isload = false;
      this.VisiorLoad = false;

      this.Checkout_Visitor_Row = JSON.parse(res).record;
      if(this.Checkout_Visitor_Row )
      {
      this.Checkout_count = this.Checkout_Visitor_Row.length;
      }
      else
      {
        this.Checkout_count=0;
      }
     
     
    });
  }
  

  







  public Approved_Master_Rows = [];
  public PApproved_Master_Rows=[];
  get_Visitor_Approved_Master() {
    this.VisiorLoad = true;


    this.Approved_Master_Rows = [];
    this.get("Api/Master/get_Approved_Master?From=" + this.S_From + "&To=" + this.S_To + "&Status="+this.Approval_Status+"&User=" + this.CREATED_BY ).subscribe((res: any) => {
      
      this.isload = false;
      this.VisiorLoad = false;
      this.visitor_count=0;
      this.Approved_Master_Rows = JSON.parse(res).record;
      this.PApproved_Master_Rows = JSON.parse(res).record;

//      this.ledger_Filter();
      try {
        this.visitor_count = this.Approved_Master_Rows.length;
      } catch { }
     
    });
  }



  ledger_Filter() {

    this.SLedger_Rows = this.PLedger_Master_Rows.filter(e => e.Group_ID == this.Ledger_Type || e.Group_ID == "3");

  }

  public Member_Order_by = "Created_Date desc";
  public Member_Details_Rows = [];
  get_Member_Details() {
    this.isload = true;
    this.Member_Details_Rows = [];
    this.get("Api/Master/get_Member_Details?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.CREATED_BY + "&Order=" + this.Member_Order_by).subscribe((res: any) => {
      this.isload = false;
      this.Member_Details_Rows = JSON.parse(res).record;
    });
  }


  public Room_Type_Rows = [];
  get_Room_Type() {
    this.getc("Api/Master/get_Room_Type").subscribe((res: any) => {
      this.Room_Type_Rows = JSON.parse(res).record;
    });
  }



  Generate_Bill() {
    this.isload = true;
    this.get("Api/Transaction/Generate_Bill?Month=" + this.S_From + "&User=" + this.CREATED_BY).subscribe((res: any) => {
      this.isload = false;
      this.toastr.success("Generated Success");
    });
  }

  Customer_Rows = [];
  get_Customer() {
    this.getc("Api/Master/get_ledger").subscribe((res: any) => {
      this.Customer_Rows = JSON.parse(res).record;
    });
  }

  Reference_Rows = [];
  get_Reference() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
      this.Area_Row = this.Reference_Rows.filter(e => e.Ref_ID == "Area");
      this.Exp_Category = this.Reference_Rows.filter(e => e.Ref_ID == "Exp_Category");

      this.get_pay_mode_ID();
    });
  }

  public Room_Master_Rows = [];
  get_rooms() {
    this.getc("Api/Master/get_rooms").subscribe((res: any) => {
      this.Room_Master_Rows = JSON.parse(res).record;


    });
  }





  public sales_compare = "0.00";
  public sales_compare_bar = 7.00;
  public Sales_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 1, Qty: 1 }];
  get_Sales_DB() {
    this.get("Api/Invoice/get_Sales_DB?Length=7").subscribe((res: any) => {

      if (res != "[]") {
        this.Sales_DB = JSON.parse(res).record;


        if (this.Sales_DB.length == 1) {
          this.Sales_DB.push({ Date: this.Y_Date, Amount: this.Sales_DB[0]["Amount"], Qty: this.Sales_DB[0]["Qty"] });
        }

        this.sales_compare = ((((this.Sales_DB[0]["Amount"]) - this.Sales_DB[1]["Amount"]) / this.Sales_DB[1]["Amount"]) * 100).toFixed(2);
        this.sales_compare_bar = Math.abs(Number(this.sales_compare));



      }
    });

  }
  public purchase_compare = "0.00";
  public purchase_compare_bar = 7.00;
  public purchase_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 1, Qty: 1 }];
  get_purchase_DB() {
    this.get("Api/Invoice/get_Purchase_DB?Length=7").subscribe((res: any) => {

      if (res != "[]") {
        this.purchase_DB = JSON.parse(res).record;


        if (this.purchase_DB.length == 1) {
          this.purchase_DB.push({ Date: this.Y_Date, Amount: this.purchase_DB[0]["Amount"], Qty: this.purchase_DB[0]["Qty"] });
        }

        this.purchase_compare = ((((this.purchase_DB[0]["Amount"]) - this.purchase_DB[1]["Amount"]) / this.purchase_DB[1]["Amount"]) * 100).toFixed(2);
        this.purchase_compare_bar = Math.abs(Number(this.purchase_compare));



      }
    });

  }
  public Expence_compare = "0.00";
  public Expence_compare_bar = 7.00;
  public Expence_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 1, Qty: 1 }];
  get_Expence_DB() {
    this.get("Api/Invoice/get_Expence_DB?Length=7").subscribe((res: any) => {

      if (res != "[]") {
        this.Expence_DB = JSON.parse(res).record;


        if (this.Expence_DB.length == 1) {
          this.Expence_DB.push({ Date: this.Y_Date, Amount: this.Expence_DB[0]["Amount"], Qty: this.Expence_DB[0]["Qty"] });
        }

        this.Expence_compare = ((((this.Expence_DB[0]["Amount"]) - this.Expence_DB[1]["Amount"]) / this.Expence_DB[1]["Amount"]) * 100).toFixed(2);
        this.Expence_compare_bar = Math.abs(Number(this.Expence_compare));



      }
    });

  }
  public Order_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 0, Qty: 0 }];
  get_Order_DB() {

    this.get("Api/Invoice/get_Order_DB?Length=7").subscribe((res: any) => {
      if (res != "[]") {
        this.Order_DB = JSON.parse(res).record;
      }
    });
  }


  public Pending_Order_DB = [{ Pending: 0, Delivery: 0, T_Pending: 0, T_Orders: 0, Y_Orders: 0, Y_Pending: 0, Y_Delivery: 0 }];
  get_Pending_Order_DB() {

    this.getc("Api/Invoice/get_Order_DB").subscribe((res: any) => {

      if (res != "[]") {
        this.Pending_Order_DB = JSON.parse(res).record;




      }

    });
  }

  public Collection_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 0, Qty: 0 }];;
  get_Collection_DB() {
    this.get("Api/Invoice/get_Collection_DB?Length=7").subscribe((res: any) => {
      if (res != "[]") {
        this.Collection_DB = JSON.parse(res).record;

        if (this.Collection_DB.length == 1) {
          this.Collection_DB.push({ Date: this.Y_Date, Amount: 0, Qty: 1 });
        }
      }
    });
  }

  public DB_Data = [{ Sales: 0, Collection: 0, Expense: 0, Out_standing: 0 }];;
  get_DB_Data() {
    this.getc("Api/Invoice/get_DB_Data").subscribe((res: any) => {
      if (res != "[]") {
        this.DB_Data = JSON.parse(res).record;


      }
    });
  }






  Make_Delivery(Order_No) {
    this.get("Api/Invoice/Make_Delivery?Order_No=" + Order_No).subscribe((res: any) => {

      this.toastr.success("Delivered  Success..", 'Msg');
      this.back();
      this.get_orders();
      this.get_Order_DB();
      this.get_Pending_Order_DB();
      this.Clear_Cart();

    });
  }


  
  load_Menu(Menu,Link)
  {
    this.Module_Name=Menu;
    this.get_child_N(Menu);
    this.router.navigate([Link]);
  }

  public Module_Name="";
  
  public M_Row_Data=[];

  get_child_N(module) {

    
    console.log(module);
    
    console.log(this.Menu_Rows)
    
    try
    {
      this.M_Row_Data= [];   
    var parent = this.Menu_Rows.filter(e => (e.Display_Name == module && e.Type=='Label'))[0]["ID"];
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

this.M_Row_Data= data;
    }catch{

    }
  }




  public Room_CIN_Details = [];

  get_rooms_CIN_Details() {

    this.getc("Api/Master/get_room_CIN_ID").subscribe((res: any) => {
      this.Room_CIN_Details = JSON.parse(res).record;
    });

  }


  view_Data(data) {
    this.router.navigate(['view-order-details']);
  }


  public Bank_Master_Rows = [];
  get_Bank_Master() {
    this.getc("Api/Master/get_Bank_Master").subscribe((res: any) => {
      this.Bank_Master_Rows = JSON.parse(res).record;
    });
  }





  get_dmk_Data() {

    this.get_Thoguthi_Master();
    this.get_Ondriyam_Master();
    this.get_Ondriyam_Peruratchi_Master();
    this.get_Ondriyam_Peruratchi_Ward_Master();
    this.get_Ondriyam_Ooratchi_Master();
    this.get_Ondriyam_Ooratchi_Kilai_Master();
    this.get_Nagaram_Master();
    this.get_Nagaram_Ward_Master();
    this.get_Total_Members();

  }


  public Thoguthi_Master_Rows = [];
  get_Thoguthi_Master() {

    this.getc("Api/Master/get_Thoguthi_Details").subscribe((res: any) => {
      this.Thoguthi_Master_Rows = JSON.parse(res).record;
    });

  }
  public Area_Street_Rows = [];
  get_Area_Street() {
    this.isload = true;

    this.getc("Api/Master/get_Area_Street_Details").subscribe((res: any) => {
      this.Area_Street_Rows = JSON.parse(res).record;
      this.isload = false;

    });

  }




  public Copy_Type="ORIGINAL";

public Printer_width="58";

  Mobile_Print(data)
  {
    window.open(this.Server_URL+"report/Mobile_Print?User=Bill_Print&Company="+this.Company+"&Bill_No="+data.Bill_No+"&Format="+this.Printer_width+"&Blue_Address=", "_blank");
  }


  open_pdf(data) {
    
    if (this.Bill_Format == "Format1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&Lab_Bill_Format=" + this.Lab_Bill_Format + "&DOB=" + data.DOB +"&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Klisters_Sales_Bill?" + parm, 'popup', 'location=0,width=750,height=650,left=500,top=55');

    }
    else if (this.Bill_Format == "Receipt_2inch") {
      this.get("Api/invoice/Receipt_2inch?Bill_No=" + data.Bill_No).subscribe((res: any) => { });
    }
    else if (this.Bill_Format == "Re-Print") {
      this.get("Api/Hotel/Reprint_takeaway?Bill_No=" + data.Bill_No).subscribe((res: any) => { });
    }
    else if (this.Bill_Format == "Mobile_Shop") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Mobile_Shop_Foramt?" + parm, "_blank");

    }
    else if (this.Bill_Format == "Format2") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_Invoice_1?" + parm, "_blank");

    } else if (this.Bill_Format == "Format3") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_Invoice_2?" + parm, "_blank");

    }
    else if (this.Bill_Format == "Format4") {
      window.open(this.Server_URL + "report/Print_Sales_Bills?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Bill_Format == "Format5") {
      window.open(this.Server_URL + "report/Print_Sales_Bills1?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");
    }
    else if (this.Bill_Format == "PRS_A4") {
      window.open(this.Server_URL + "report/PRS_Bill?User=" + this.CREATED_BY + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No + "&Company=" + this.Company + "&Copy_Type=" + this.Copy_Type, "_blank");

    }
    else if (this.Bill_Format == "PRS_A3") {
      window.open(this.Server_URL + "report/PRS_Bill2?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Bill_Format == "PRS_NT_A3") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Sales_Bills_Non_Tax?" + parm, "_blank");

    }

    else if (this.Bill_Format == "Osai") {
      window.open(this.Server_URL + "report/Osai_Bill?User=" + this.CREATED_BY + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No + "&Company=" + this.Company + "&Copy_Type=" + this.Copy_Type, "_blank");

    }
    else if (this.Bill_Format == "Klisters") {

      window.open(this.Server_URL + "report/Klisters_Sales_Bill?User=" + this.CREATED_BY + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No + "&Company=" + this.Company + "&Copy_Type=" + this.Copy_Type, "_blank");


    }
    else if (this.Bill_Format == "Osai_Mobile") {

      window.open(this.Server_URL + "report/Mobile_Print?User=Bill_Print&Company=" + this.Company + "&Bill_No=" + data.Bill_No + "&Format=" + this.Printer_width + "&Blue_Address=", "_blank");
    }


  }

  public DC_Format="Klisters";
  open_delivery(data) {

    
    if(this.DC_Format=="Klisters1")
    {
      var parm="User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name="+data.DC_No.replace(/[^a-zA-Z0-9 ]/g, "_")+"&File_Type=pdf&Bill_No="+data.Bill_No;
      window.open(this.Server_URL+"report/Klisters_Delivery_Bill?"+parm,"_blank");
      
    }
    else  if(this.DC_Format=="Klisters"){
      window.open(this.Server_URL+"report/Klisters_Delivery_Bill?Bill_No="+data.DC_No+"&Company="+this.Company, "_blank");
 
     }
    
    
  }
  open_pdf_Non_Tax(data) {

    
    if(this.NT_Bill_Format=="NT_Format1")
   {
     var parm="User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name="+data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_")+"&File_Type=pdf&Bill_No="+data.Bill_No;
     window.open(this.Server_URL+"report/Print_Sales_Bills_Non_Tax?"+parm,"_blank");
     
   }
   else  if(this.NT_Bill_Format=="Klisters"){
    var parm="User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name="+data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_")+"&File_Type=pdf&Bill_No="+data.Bill_No;
    window.open(this.Server_URL+"report/Klisters_Non_Tax_Bill?"+parm,"_blank");
    

   }
}
public Test_Type_="All";
public Old_Reference_Value:any;
public Report_Msg="Welcome";
get_Test_Report(data) 
{
 this.Edit_Row=data;
 
 window.open(this.Server_URL+"report/Lab_Report?Type=Open&Contact="+this.Edit_Row.Contact_No+"&Bill_No="+this.Edit_Row.Bill_No+"&File_Name="+this.Edit_Row.Bill_No+"&Msg="+this.Report_Msg+"&Format="+this.Lab_Report_With_Header+"&Test_Type="+this.Test_Type_+"&Company="+this.Company, "_blank");


}
get_Without_Test_Report(data) 
{
 this.Edit_Row=data;
 window.open(this.Server_URL+"report/Lab_Report?Type=Open&Contact="+this.Edit_Row.Contact_No+"&Bill_No="+this.Edit_Row.Bill_No+"&File_Name="+this.Edit_Row.Bill_No+"&Msg="+this.Report_Msg+"&Format="+this.Lab_Report_Without_Header+"&Test_Type="+this.Test_Type_+"&Company="+this.Company, "_blank");



}
get_Crystal_Test_Report() 
{
 window.open(this.Server_URL+"report/Overall_Crystal_Report?Company="+this.Company, "_blank");



}
public P_Invoice_Format="Format1"
proforma_pdf(data) {
  if(this.P_Invoice_Format=="Format1")
{ ``
  var parm="User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name="+data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_")+"&File_Type=pdf&Bill_No="+data.Bill_No;
  window.open(this.Server_URL+"PDF/Export_P_Invoice_1?"+parm, "_blank");
}
else  if(this.P_Invoice_Format=="Klisters"){
  window.open(this.Server_URL+"report/Klisters_Proforma_Bill?Bill_No="+data.Bill_No+"&Company="+this.Company, "_blank");

  

 }
}
public Delivery_Format="Format1"
Delivery_pdf(data) {
  if(this.Delivery_Format=="Format2")
{ 
  var parm="User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name="+data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_")+"&File_Type=pdf&Bill_No="+data.Bill_No;
  window.open(this.Server_URL+"PDF/Export_P_Invoice_1?"+parm, "_blank");
}
else  if(this.Delivery_Format=="Format1"){
  window.open(this.Server_URL+"report/Print_Customerwise?Bill_No="+data.Bill_No+"&Company="+this.Company, "_blank");

  

 }
}
public PO_Format="Format1";
open_purchase_pdf(data) {
  if(this.PO_Format=="Format1")
  {
    var parm="User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name="+data.PO_No.replace(/[^a-zA-Z0-9 ]/g, "_")+"&File_Type=pdf&PO_No="+data.PO_No;
  window.open(this.Server_URL+"PDF/Export_PO_1?"+parm, "_blank");

  }
 
  else  if(this.PO_Format=="Klisters"){
    window.open(this.Server_URL+"report/Klisters_PO_Bill?PO_No="+data.PO_No+"&Company="+this.Company, "_blank");

   }
  
 
}
  get_street(Area) {

    return this.Area_Street_Rows.filter(e => String(e.Area).toLowerCase() == String(Area).toLowerCase());
  }
  public Ondriyam_Master_Rows = [];
  get_Ondriyam_Master() {

    this.getc("Api/Master/get_Ondriyam_Details").subscribe((res: any) => {
      this.Ondriyam_Master_Rows = JSON.parse(res).record;

    });

  }

  get_Nagaram(data) {

    var r = [];
    if (this.Nagaram_Master_Rows) {
      r = this.Nagaram_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(data).toLowerCase());
    }

    return r;
  }


  get_Nagaram_Ward(Thoguthi, data) {

    var r = [];
    if (this.Nagaram_Ward_Master_Rows) {
      r = this.Nagaram_Ward_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Nagaram).toLowerCase() == String(data).toLowerCase());

    }

    return r;
  }


  get_Ondriyam(data) {

    var r = [];
    if (this.Ondriyam_Master_Rows) {
      r = this.Ondriyam_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(data).toLowerCase())
    }

    return r;

  }



  get_Peruratchi(Thoguthi, data) {
    var r = [];
    if (this.Ondriyam_Peruratchi_Master_Rows) {
      r = this.Ondriyam_Peruratchi_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(data).toLowerCase());
    }


    return r;
  }


  get_Peruratchi_Ward(Thoguthi, Ondriyam, data) {
    var r = [];
    if (this.Ondriyam_Peruratchi_Ward_Master_Rows) {
      r = this.Ondriyam_Peruratchi_Ward_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(Ondriyam).toLowerCase());
      r = r.filter(e => String(e.Peruratchi).toLowerCase() == String(data).toLowerCase());
    }
    return r;
  }


  get_Ondriyam_Ooratchi(Thoguthi, data) {

    console.log(Thoguthi, data)

    console.log(this.Ondriyam_Ooratchi_Master_Rows)
    var r = [];
    if (this.Ondriyam_Ooratchi_Master_Rows) {
      r = this.Ondriyam_Ooratchi_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(data).toLowerCase());
    }
    console.log(r);
    return r;
  }



  get_Ondriyam_Ooratchi_Kilai(Thoguthi, Ondriyam, data) {


    var r = [];
    if (this.Ondriyam_Ooratchi_Kilai_Master_Rows) {
      r = this.Ondriyam_Ooratchi_Kilai_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(Ondriyam).toLowerCase());
      r = r.filter(e => String(e.Ooratchi).toLowerCase() == String(data).toLowerCase());
    }

    return r;
  }



  public Ondriyam_Peruratchi_Master_Rows = [];
  get_Ondriyam_Peruratchi_Master() {

    this.getc("Api/Master/get_Ondriyam_Peruratchi_Details").subscribe((res: any) => {
      this.Ondriyam_Peruratchi_Master_Rows = JSON.parse(res).record;
    });

  }
  public Ondriyam_Peruratchi_Ward_Master_Rows = [];
  get_Ondriyam_Peruratchi_Ward_Master() {

    this.getc("Api/Master/get_Ondriyam_Peruratchi_Ward_Details").subscribe((res: any) => {
      this.Ondriyam_Peruratchi_Ward_Master_Rows = JSON.parse(res).record;
    });

  }
  public Ondriyam_Ooratchi_Master_Rows = [];
  get_Ondriyam_Ooratchi_Master() {

    this.getc("Api/Master/get_Ondriyam_Ooratchi_Details").subscribe((res: any) => {
      this.Ondriyam_Ooratchi_Master_Rows = JSON.parse(res).record;
    });

  }
  public Ondriyam_Ooratchi_Kilai_Master_Rows = [];
  get_Ondriyam_Ooratchi_Kilai_Master() {

    this.getc("Api/Master/get_Ondriyam_Ooratchi_Kilai_Details").subscribe((res: any) => {
      this.Ondriyam_Ooratchi_Kilai_Master_Rows = JSON.parse(res).record;
    });

  }
  public Nagaram_Master_Rows = [];
  get_Nagaram_Master() {

    this.getc("Api/Master/get_Nagaram_Details").subscribe((res: any) => {
      this.Nagaram_Master_Rows = JSON.parse(res).record;
    });

  }
  public Nagaram_Ward_Master_Rows = [];
  get_Nagaram_Ward_Master() {

    this.getc("Api/Master/get_Nagaram_Ward_Details").subscribe((res: any) => {
      this.Nagaram_Ward_Master_Rows = JSON.parse(res).record;
    });

  }
  public Dashboard_Row = [];
  get_Dashboard() {
    this.Dashboard_Row = [];
    this.loading = true;

    this.get("Api/Master/get_dashboard?").subscribe((res: any) => {
      this.Dashboard_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }
  public Employee_Details_Rows = [];
  get_Employee_Details() {

    this.get("Api/Master/get_Employee_Details?Department="+this.S_Department).subscribe((res: any) => {
      this.Employee_Details_Rows = JSON.parse(res).record;


      try{
      this.Emp_Dept = this.Employee_Details_Rows.filter(e => e.ID ==  this.Emp_ID)[0]["Department"];
      this.Emp_Approver = this.Employee_Details_Rows.filter(e => e.ID ==  this.Emp_ID)[0]["Approver_ID"];
      if(this.Emp_Approver=="" || this.Emp_Approver=="0" || this.Emp_Approver==this.Emp_ID )
      {
        this.Emp_Approver_Name="";
        this.Emp_Approver =this.Emp_ID;
      }
      else
      {
      this.Emp_Approver_Name=   this.Employee_Details_Rows.filter(e => e.ID ==  this.Emp_Approver)[0]["Name"];
      }
      }catch{}


    });

  }

  public Due_text="Dues";
  public Current_Statsu_text="Currently Use";

  public isedit:boolean=true;

  public Due_type=[];
  public Curent_Status_type=[];
  
  public Instrument_Details_Rows = [];
  get_Instrument_Details() {

    this.get("Api/Master/get_Instrument_Details?INS_Type=" + this.Instrument_Type + "&Current_Status=" + this.Current_Status ).subscribe((res: any) => {
    this.Instrument_Details_Rows = JSON.parse(res).record;

    this.Due_type = [...new Set(this.Instrument_Details_Rows.map(item => item.Dues))];
    this.Curent_Status_type = [...new Set(this.Instrument_Details_Rows.map(item => item.Current_Status))];

    this.db_get_status(this.Current_Statsu_text)

    this.db_get_dues(this.Due_text);
    });

  }


  public Ins_Standard_Row = [];

  
  get_Doc_Standard() {
  
    this.Ins_Standard_Row = [];
    this.getc("Api/Master/get_Doc_Standard_Procedure").subscribe((res: any) => {
    this.Ins_Standard_Row = JSON.parse(res).record;
     
    });
  }


get_docs(data)
  {
    return this.Ins_Standard_Row.filter(e=>e.Doc_Type==data);
    
  }
  
  db_Status_Rows=[];
  db_get_status(data)
  {
    this.Current_Statsu_text=data;
    this.db_Status_Rows=this.Instrument_Details_Rows.filter(e=>String(e.Current_Status).toLowerCase()==String(data).toLowerCase());
  }

  db_Due_Rows=[];
  db_get_dues(data)
  {
    
    this.Due_text=data;
    this.db_Due_Rows=this.Instrument_Details_Rows.filter(e=>String(e.Dues).toLowerCase()==String(data).toLowerCase()).sort((a, b) => Number(a["Days"]) - Number((b["Days"])));
    window.scroll(0,this.page_y);  
  }

public move:boolean=false;  page_y=0.0;
  Click_data="";


  get_Ins_by_Status(data)
  {
    return this.Instrument_Details_Rows.filter(e=>e.Current_Status==data)

  }

  public Instrument_Request_count=0;
  public Instrument_Request_Approved=0;
  public Instrument_Request_Rejected=0;

  public Instrument_Request_Rows = [];
  
  get_Instrument_Request() {

    this.get("Api/Master/get_Instrument_Request?From=" + this.S_From + "&To=" + this.S_To +"&Status="+this.Apprval_Status+"&User=all").subscribe((res: any) => {
    this.Instrument_Request_Rows = JSON.parse(res).record;
    try {
      this.Instrument_Request_count = this.Instrument_Request_Rows.length;
    } catch { }
    });

  }
 

  public App_Instrument_Request_count=0;
  
  public App_Instrument_Request_Rows = [];
  get_App_Instrument_Request() {
     
    this.App_Instrument_Request_count=0;
    this.getc("Api/Master/get_App_Instrument_Request").subscribe((res: any) => {
    this.App_Instrument_Request_Rows = JSON.parse(res).record;
    try {
      this.App_Instrument_Request_count = this.App_Instrument_Request_Rows.length;
    } catch { }
    });



  }

  public load_from_App_Req:boolean=false;
  public load_from_App_Req_data:any={};


  public Instrument_Diagram_Row = [];
  public Instrument_Diagram_Row_img = [];
  public Instrument_Diagram_Row_pdf = [];
  
  get_Diagram_Master(ID) {

    this.Instrument_Diagram_Row = [];
    this.Instrument_Diagram_Row_img = [];
   this.Instrument_Diagram_Row_pdf = [];
    this.get("Api/Master/get_Diagram_Master?ID="+ID).subscribe((res: any) => {
    this.Instrument_Diagram_Row = JSON.parse(res).record;
    this.Instrument_Diagram_Row_img=this.Instrument_Diagram_Row.filter(e=>e.file_type==".png")
    this.Instrument_Diagram_Row_pdf=this.Instrument_Diagram_Row;
    this.get_INS_Submited_Document(ID)
    });

  }
  public Request_Drawing_Row = [];
  public Request_Drawing_Row_img = [];
  public Request_Drawing_Row_pdf = [];
  
  get_Request_Drawing_Master(ID) {

    this.Request_Drawing_Row = [];
    this.Request_Drawing_Row_img = [];
   this.Request_Drawing_Row_pdf = [];
    this.get("Api/Master/get_Request_Drawing?ID="+ID).subscribe((res: any) => {
    this.Request_Drawing_Row = JSON.parse(res).record;
    this.Request_Drawing_Row_img=this.Request_Drawing_Row.filter(e=>e.File_Type==".png")
    this.Request_Drawing_Row_pdf=this.Request_Drawing_Row;
    this.get_Submited_Document(ID);
    });

  }

  public Document_List_count=0;
  public Document_List=[];
  get_Submited_Document(ID) {

    this.Document_List = [];
    this.Document_List_count=0;
    this.get("Api/Master/get_Request_Drawing_List?ID="+ID).subscribe((res: any) => {
    this.Document_List = JSON.parse(res).record;

    try
    {
      this.Document_List_count=this.Document_List.length;

    }catch{}
   
    });

  }

  public INS_Document_List_count=0;
  public INS_Document_List=[];
  get_INS_Submited_Document(ID) {

    this.INS_Document_List = [];
    this.INS_Document_List_count=0;
    this.get("Api/Master/get_Drawing_List?ID="+ID).subscribe((res: any) => {
    this.INS_Document_List = JSON.parse(res).record;

    try
    {
      this.INS_Document_List_count=this.INS_Document_List.length;

    }catch{}
   
    });

  }
  get_Request_Drawing_List


  public Inspection_Standard_Row = [];
  get_Inspection_Standard(ID) {

    this.Inspection_Standard_Row = [];
    this.get("Api/Master/get_Inspection_Standard?ID="+ID).subscribe((res: any) => {
    this.Inspection_Standard_Row = JSON.parse(res).record;
  
    });

  }
  public Test_Inspection_Standard_Row = [];
  get_Test_Inspection_Standard(ID) {

    this.Test_Inspection_Standard_Row = [];
    this.get("Api/Master/get_Test_Inspection_Standard?ID="+ID).subscribe((res: any) => {
    this.Test_Inspection_Standard_Row = JSON.parse(res).record;
  
    });

  }
  public Used_Instrument_Rows_ = [];
  get_Used_Instrument(Calibration_No) {

    this.get("Api/Master/get_Used_Instrument?Calibration_No="+Calibration_No).subscribe((res: any) => {
      this.Used_Instrument_Rows = JSON.parse(res).record;
    });

  }
  public Used_Instrument_Rows = [];
  get_Used_Instrument_() {

    this.getc("Api/Master/get_Used_Instrument_").subscribe((res: any) => {
      this.Used_Instrument_Rows = JSON.parse(res).record;
    });

  }
  public Master_Instrument_Row = [];
  get_Master_Instrument(ID) {

    this.Master_Instrument_Row = [];
    this.get("Api/Master/get_Master_Instrument?ID="+ID).subscribe((res: any) => {
    this.Master_Instrument_Row = JSON.parse(res).record;
    this.master_exp=false;
    for(var i=0;i<=this.Master_Instrument_Row.length;i++)
    {
      if(parseFloat(this.Master_Instrument_Row[0].Days)<=0)
      {
        this.master_exp=true;
      }
    }

    });

  }

  master_exp:boolean=false;

  public Department_Email_Row = [];
  get_Department_Email() {

    this.Department_Email_Row = [];
    this.get("Api/Master/get_Department_Email?Email_Department="+this.Email_Department).subscribe((res: any) => {
    this.Department_Email_Row = JSON.parse(res).record;
  
    });

  }
  

  get_email_id(data)
  {
    return  this.Department_Email_Row.filter(e=>e.Department==data)[0]["Email_ID"];
  }
  
  public Instrument_Status_Row = [];
  get_Instrument_Type() {

    this.getc("Api/Master/Instrument_Dashboard").subscribe((res: any) => {
    this.Instrument_Status_Row = JSON.parse(res).record;
    });

  }
  public Instrument_Report_Row = [];
  
  public Instrument_Report_Row_H = [];


  public Type="Created_Date";


  
  Calibration_Approval_Row_C=0;
  public Calibration_Approval_Row = [];
  get_Calibration_Approval() {
    this.Calibration_Approval_Row_C=0;
    this.getc("Api/Master/get_Calibration_Approval").subscribe((res: any) => {
    this.Calibration_Approval_Row = JSON.parse(res).record;
    try
    {

      this.Calibration_Approval_Row_C= this.Calibration_Approval_Row.length;
    }catch{}

    });

  }
  Test_Feasibility_Row_C=0;
  public Test_Feasibility_Row = [];
  get_Test_Feasibility() {
    this.Test_Feasibility_Row_C=0;
    this.getc("Api/Master/get_Test_Fesibility").subscribe((res: any) => {
    this.Test_Feasibility_Row = JSON.parse(res).record;
    try
    {

      this.Test_Feasibility_Row_C= this.Test_Feasibility_Row.length;
    }catch{}

    });

  }
  Test_Feasibility_Approval_Row_C=0;
  public Test_Feasibility_Approval_Row = [];
  get_Test_Feasibility_Approval() {
    this.Test_Feasibility_Approval_Row_C=0;
    this.getc("Api/Transaction/get_Test_Fesibility_Approval").subscribe((res: any) => {
    this.Test_Feasibility_Approval_Row = JSON.parse(res).record;
    try
    {

      this.Test_Feasibility_Approval_Row_C= this.Test_Feasibility_Approval_Row.length;
    }catch{}

    });

  }
 Test_TimePlan_Row_C = 0;
  public Feasibility_Status="Approved";
  public Test_Type="Regular";

  public Test_TimePlan_Row = [];
  get_Test_Time_Plan() {
   this.Test_TimePlan_Row_C = 0;

    this.get("Api/Transaction/get_Test_Time_Plan?Test_Feasibility_Status="+this.Feasibility_Status+"&Test_Type="+this.Test_Type).subscribe((res: any) => {
    this.Test_TimePlan_Row = JSON.parse(res).record;
    try
    {

      this.Test_TimePlan_Row_C= this.Test_TimePlan_Row.length;
    }catch{}
    });

  }
  Tad_SheetApproval_Row_C = 0;

  public TAD_Approval_Row = [];
  get_TAD_Sheet_Approval() {
    this.  Tad_SheetApproval_Row_C = 0;

    this.getc("Api/Transaction/get_TAD_Sheet_Approval").subscribe((res: any) => {
    this.TAD_Approval_Row = JSON.parse(res).record;
  
    try
    {

      this.Tad_SheetApproval_Row_C= this.TAD_Approval_Row.length;
    }catch{}
    });

  }
  public Test_Result_Approval_Row = [];
  get_Test_Result_Approval() {

    this.getc("Api/Transaction/get_Test_Result_Approval").subscribe((res: any) => {
    this.Test_Result_Approval_Row = JSON.parse(res).record;
  

    });

  }
  public Test_Report_Generate_Row = [];
  get_Test_Report_generate() {

    this.getc("Api/Transaction/get_Test_Report_Generate").subscribe((res: any) => {
    this.Test_Report_Generate_Row = JSON.parse(res).record;
  

    });

  }
  public Test_Result_Approval_Entry_Row = [];
  get_Test_Result_Approval_Entry() {

    this.getc("Api/Transaction/get_Test_Result_Approval_Entry").subscribe((res: any) => {
    this.Test_Result_Approval_Entry_Row = JSON.parse(res).record;
  

    });

  }
  public Outof_Cali_Approval_Row = [];
  public Outof_Cali_Approval_Row_C = 0;
  get_Outof_Calibration_Approval() {

    this.getc("Api/Master/get_Out_Of_Calibration_Approval").subscribe((res: any) => {
    this.Outof_Cali_Approval_Row = JSON.parse(res).record;

    try{
     this.Outof_Cali_Approval_Row_C=this.Outof_Cali_Approval_Row.length;
    }catch{}

    });

  }
  public Action_Pending_Row_count=0;
  public Action_Pending_Row = [];
  get_Action_Pending() {

    this.getc("Api/Master/get_Action_Pending").subscribe((res: any) => {
    this.Action_Pending_Row = JSON.parse(res).record;
    try{
      this.Action_Pending_Row_count=this.Action_Pending_Row.length;
     }catch{}
    });

  }


  public Ins_Standard__Type_Row:any = {};
  get_Ins_Std_Procedure(ID) {

    this.Ins_Standard__Type_Row = {};
    this.get("Api/Master/get_INS_Standard_Procedure_Details?Instrument_Type="+ID).subscribe((res: any) => {
    this.Ins_Standard__Type_Row = JSON.parse(res).record[0];
   
    });

  }


 
  public Calibration_Entry_Row = [];

  get_Calibration_Entry(ID) {

    this.get("Api/Master/get_Calibration_Entry?ID="+ID).subscribe((res: any) => {
    this.Calibration_Entry_Row = JSON.parse(res).record;
  
    });

  }

 
  public Blocked_Visitor_Rows = [];
  get_Blocked_Visitor() {

    this.getc("Api/Master/get_Blocked_Visitor").subscribe((res: any) => {
      this.Blocked_Visitor_Rows = JSON.parse(res).record;
    });

  }
  public Area_Master_Rows = [];
  get_Area_Master() {

    this.isload=true;
    this.Area_Master_Rows=[];
    this.getc("Api/Master/get_Area_Master").subscribe((res: any) => {
      this.isload=false;
      this.Area_Master_Rows = JSON.parse(res).record;
      
    });

  }


  get_Area_Color(data) {

    
    try{
    return this.Area_Master_Rows.filter(e => e.Araa == data)[0]["Color_Code"];
    }catch{

      return [];
    }
  }


   public Holiday_Details_Rows = [];
  get_Holiday_Details() {

    this.getc("Api/Master/get_Holiday_Details").subscribe((res: any) => {
      this.Holiday_Details_Rows = JSON.parse(res).record;
    });

  }

  get_Thoguthi(Data) {

    var data = this.Thoguthi_Master_Rows.filter(e => String(e.District).toLowerCase() == String(Data).toLowerCase()).sort((a, b) => (a["Thoguthi"]) - ((b["Thoguthi"])));
    return data;
  }



  pdfType = "Open";


  get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public load_page(data) {
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }







  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }



  public sum_of(dt, field) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0);
      } catch { }
    }

    return len;
  }


  Open_Modal1(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }
  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };
  headers;

  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  File_Name = "Data";

  export_pdf() {


    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  Ledger_ID = "0";
  Ledger_cr = 0;
  Ledger_db = 0;
  Ledger_Total = 0;
  Ledger_File_Name = "Data";
  public BILLNO;
  export_pdf1() {


    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.Excel_Data.Ledger_ID = this.Ledger_ID
    this.Excel_Data.From = this.S_From;
    this.Excel_Data.To = this.S_To;
    this.Excel_Data.cr = this.Ledger_cr;

    this.Excel_Data.db = this.Ledger_db;
    this.Excel_Data.Total = this.Ledger_Total;


    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdfx?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

}
